import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import {withRouter} from "react-router";

import ViewRestaurantList from "../Views/ViewRestaurantList";
import EditRestaurant from "../Editors/EditRestaurant";

import APIService from "../API.js";


class PromotionalRestaurantController extends Component {

  state = {
    restuarants: [],
    isFetchingRestaurants: false,
  };

  componentDidMount() {
    this.getRestaurantList();
  };

  getRestaurantList = () => {
    this.setState( {isFetchingRestaurants: true})
    fetch(APIService.serverUrl + "/api/private/promotional-restaurants", {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if (json) {
          this.setState( {restaurants: json})
        }
        this.setState({isFetchingRestaurants: false})
      })
  };

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <div>
        <Switch>
          <Route
            path={relativePath}
            exact
            render={() =>
              <ViewRestaurantList
                {...this.state}
                refreshList={this.getRestaurantList}
                restaurants={this.state.restaurants}
              />}
          />
          <Route
            path={relativePath + "/add"}
            render={() =>
              <EditRestaurant
                refreshList={this.getRestaurantList}
              />
            }
          />
          <Route
            path={relativePath + "/edit/:restaurantId"}
            render={() =>
              <EditRestaurant
                refreshList={this.getRestaurantList}
              />}
          />
        </Switch>
      </div>
    )
  };
};

export default withRouter(PromotionalRestaurantController);
