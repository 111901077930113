import React, { Component } from 'react';
import {
  Header,
  Icon,
  Image,
  Label,
  Form,
  Input,
  Grid,
  Checkbox,
  Button,
} from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import APIService from '../API.js';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { withRouter } from "react-router";
import Helpers from '../Helpers.js';


class EditContest extends Component {

  doesContestExist = () => this.props.match.params.contestId !== undefined;

  static defaultState = {

    /* Default Contest State */
    // id: 0,
    isApproved: true,
    name: '',
    externalUrl: '',
    iFrame: '',
    content: EditorState.createEmpty(),
    image: '',
    displayTypeId: 0,
    startDate: new moment().startOf('day'),
    endDate: new moment().endOf('day').add(30, 'd'),
    /* Default Contest State */

    unmodifiedContest: {},

    contestDisplayTypes: [],
    newImage: undefined,
  }

  state = EditContest.defaultState;

  componentDidMount() {
    this.getContest();
    this.getContestDisplayTypes();
  }

  getContest = () => {
    if (this.doesContestExist()) {
      fetch(APIService.serverUrl + '/api/private/contest/'+this.props.match.params.contestId, {
        method: "GET",
        headers: APIService.authHeaders(),
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("getContest Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("getContest Request Succeeded!");
          // console.log(json);
          this.startEditContest(json);
        } else {
          console.log("getContests Fetch Failed returned null")
        }
      })
    }
  }

  getContestDisplayTypes = () => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl + '/api/private/contest-display-type', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("getContestLocations Fetch Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("getContestDisplayTypes Request Succeeded!");
        // console.log(json);
        this.setState({
          "contestDisplayTypes": json,
          "componentIsLoading": false,
        })
      } else {
        console.log("getContestLocations Fetch Failed")
      }
    })
  };

  patchContest = () => {
    this.setState({ 'componentIsLoading': true, });
    let apiPath = this.doesContestExist() ? '/api/private/contest/'+this.state.id : '/api/private/contest';
    fetch(APIService.serverUrl + apiPath, {
      method: this.doesContestExist() ? 'PATCH' : 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        id: this.state.id,
        isApproved: this.state.isApproved,
        name: this.state.name,
        externalUrl: this.state.externalUrl,
        iFrame: this.state.iFrame,
        content: draftToHtml(convertToRaw(this.state.content.getCurrentContent())),
        image: this.state.image,
        displayTypeId: this.state.displayTypeId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("patchContest PATCH Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("patchContest PATCH Succeeded!");
        this.props.history.replace('/contests/edit/'+json.id);
        this.props.refreshList();
        this.getContest();
      } else {
        console.log("patchContest PATCH Failed")
      }
    })
  }

  startEditContest = (contest) => {
    const bodyBlock = htmlToDraft(contest.content);
    let bodyEditorState = null;
    if (bodyBlock) {
      const bodyContentState = ContentState.createFromBlockArray(bodyBlock.contentBlocks);
      bodyEditorState = EditorState.createWithContent(bodyContentState);
    }

    let apiContestData = {
      id: contest.id,
      isApproved: contest.isApproved,
      name: contest.name,
      externalUrl: contest.externalUrl,
      iFrame: contest.iframe,
      content: bodyEditorState,
      image: contest.image,
      displayTypeId: contest.displayType.id,
      startDate: moment(contest.startDate),
      endDate: moment(contest.endDate),
    }

    this.setState({
      ...apiContestData,
      unmodifiedContest: apiContestData,
    })
  }

  contestLocationChange = (e, { name, value, text }) => {
    this.setState({ [name]: value });
    // console.log(text);
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: moment(date) })
  }

  onEndDateChange = (date) => {
    this.setState({ endDate: moment(date) })
  }

  onEditorStateChange = (content) => {
    this.setState({
      content,
    });
  };

  deleteImage = (id) => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl+'/api/private/image/'+id, {
      method: 'DELETE',
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        // console.log("deleteImage Succeeded!");
        this.props.refreshList();
        this.getContest();
        this.setState({ 'componentIsLoading': false, });
      } else {
        console.log("deleteImage Failed: ", response.status, response.statusText)
      }
    })
  }

  selectTest = () => {
    let i = this.state.contestDisplayTypes.length;
    while (i--) {
      if (this.state.displayTypeId === this.state.contestDisplayTypes[i].id) {
        return this.state.contestDisplayTypes[i].name;
      }
    }
  }

  renderLocationInput = () => {

    switch (this.selectTest()) {
      case 'URL': // URL
        return (
          <Form.Field>
            <label>URL: </label>
            <Input
              id="externalUrl"
              name='externalUrl'
              placeholder='Enter the Contest URL...'
              value={this.state.externalUrl}
              onChange={this.onInputChange} />
          </Form.Field>
        );
      case 'iFrame': //iFrame
        return (
          <Form.Field>
            <label>iFrame: </label>
            <Input
              id="iFrame"
              name='iFrame'
              placeholder='Enter the Contest iFrame...'
              value={this.state.iFrame}
              onChange={this.onInputChange} />
          </Form.Field>
        );
      case 'Content': //Content
        return (
          <Form.Field>{/* Story */}
            <label>Local Content: </label>
            <Editor
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'history'],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                blockType: {
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote', 'Code'],
                },
                fontSize: {
                  options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
                },
                textAlign: { inDropdown: true, },
                list: { inDropdown: true, },
              }}
              editorState={this.state.content}
              onEditorStateChange={this.onEditorStateChange} />
          </Form.Field>
        );
      default:
        return (
          <Form.Field>{/* Default */}
            <label></label>
            <Form.Input
              id="selectLocation"
              name='selectLocation'
              placeholder='Select a Location'
              value='Select a Location'
              readOnly
              error />
          </Form.Field>
        );
    }
  }

  isFormModified = () => {
    if (!this.doesContestExist()) { return true }
    for (var key in this.state.unmodifiedContest) {
      if (this.state.unmodifiedContest[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  }


  render() {

    let requiredFieldsComplete = (
      this.state.name.length !== 0 &&
      this.state.displayTypeId !== 0
    );

    // console.log('requiredFieldsComplete: '+requiredFieldsComplete);
    // console.log('isFormModified: '+this.isFormModified());

    return (

      <Form >
        <Header
          dividing
          color='blue'
          icon={ this.doesContestExist() ? 'edit outline' : 'add' }
          content={ this.doesContestExist() ? 'Edit Contest' : 'Add Contest' } />
        <Form.Group inline>
          <Form.Field>
            <label>Approved:</label>
            <Checkbox
              toggle
              name='isApproved'
              checked={this.state.isApproved}
              onChange={() => {
                  this.setState({isApproved: !this.state.isApproved })
              }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>
            Name:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Input
            id="name"
            name='name'
            placeholder='Enter the Contest Name...'
            value={this.state.name}
            onChange={this.onInputChange} />
        </Form.Field>
        <Form.Field>
          <label>
            Contest Location:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Form.Select
            id="displayTypeId"
            name='displayTypeId'
            options={this.state.contestDisplayTypes.map(
              location => {
                return {
                  key: location.id,
                  text: location.name === 'Content' ? 'Local Content' : location.name,
                  value: location.id
                }
            })}
            placeholder='Choose the Contest Location...'
            value={this.state.displayTypeId}
            onChange={this.contestLocationChange} />
        </Form.Field>
        { this.renderLocationInput() }
        <Form.Field>
          <label>Start Date:</label>
          <DatePicker
            name='startDate'
            dateFormat='MM/dd/yyyy'
            minDate={new Date()}
            selected={this.state.startDate.toDate()}
            onChange={this.onStartDateChange} />
        </Form.Field>
        <Form.Field>
          <label>End Date:</label>
          <DatePicker
            name='endDate'
            dateFormat='MM/dd/yyyy'
            minDate={new Date()}
            selected={this.state.endDate.toDate()}
            onChange={this.onEndDateChange} />
        </Form.Field>
        { this.doesContestExist() ?
            <Form.Field>
              <label>Image: </label>
              <Grid>
                <Grid.Row>
                  {this.state.image ?
                    <Grid.Column key={this.state.image.id} >
                      <Image width='263' height='148'
                        src={Helpers.getOptimizedImage(this.state.image, 263)} />
                      <div style={{position: 'absolute', top: 0, width: 'auto', height: 'auto' }}>
                        <Button
                          size='mini'
                          circular
                          color='red'
                          icon='cancel'
                          onClick={ () => this.deleteImage(this.state.image.id) }/>
                      </div>
                    </Grid.Column>
                  :
                    <Grid.Column >
                      <Button
                        size='mini'
                        color='green'
                        onClick={ (e)=> {this.props.history.push('/add-image/contest/'+this.state.id)}} >
                        <Icon name='image outline' /> Add Image
                      </Button>
                    </Grid.Column>
                  }
                </Grid.Row>
              </Grid>
            </Form.Field>
          : ''
        }
        <Form.Field>
          <Label size='tiny' >
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
            Denotes required field
          </Label>
        </Form.Field>
        <Form.Field>
          <Button
            size='mini'
            color={ this.isFormModified() ? 'red' : undefined }
            onClick={ () => this.props.history.push('/contests') } >
            <Icon name='cancel' /> { this.isFormModified() ? 'Cancel' : 'Close' }
          </Button>
          <Button
            size='mini'
            color='green'
            disabled={!requiredFieldsComplete || !this.isFormModified()}
            onClick={this.patchContest} >
            <Icon name='save outline' /> Save
          </Button>
        </Form.Field>
      </Form>

    );
  }
}

export default withRouter(EditContest);

/*



*/
