import React, { Component } from 'react';
import { Menu, Sidebar, Divider, Icon } from "semantic-ui-react";
import { withRouter } from 'react-router-dom';
import APIService from "./API";

class SideBarNavigation extends Component {

    onMenuItemClick = (e, { name }) => {
        switch (name) {
            case "articleList": this.props.history.push('/articles'); break
            case "articleReports": this.props.history.push('/article-reports'); break
            case "articleTypes": this.props.history.push('/article-types'); break
            case "priorityTypes": this.props.history.push('/priority-types'); break
            case "videoTypes": this.props.history.push('/video-types'); break
            case "closings": this.props.history.push('/closings'); break
            case "closingsRadio": this.props.history.push('/closings/radio'); break
            case "closingsHistory": this.props.history.push('/closings/history'); break
            case "agencyList": this.props.history.push('/agencies'); break
            case "snowEmergency": this.props.history.push('/snow-emergency'); break
            case "resourceList": this.props.history.push('/resources'); break
            case "contests": this.props.history.push('/contests'); break
            case "userList": this.props.history.push('/users'); break
            case "gamesList": this.props.history.push('/games'); break
            case "gameStatusList": this.props.history.push('/game-statuses'); break
            case "schoolList": this.props.history.push('/schools'); break
            case "sportList": this.props.history.push('/sports'); break
            case "seasonList": this.props.history.push('/seasons'); break
            case "electionList": this.props.history.push('/elections'); break
            case "partyList": this.props.history.push('/parties'); break
            case "electionCountyList": this.props.history.push('/election-counties'); break
            case "promotionalRestaurantList": this.props.history.push('/promotional-restaurants'); break

            default: this.props.history.push('/');
        };
        this.props.closeNavMenu();

    }


    render() {
        let userRoles = this.props.user.roles

        let activeMenuItem = '';
        let pageName = '';
        let iconName = '';
        let iLoading = false;

        switch(this.props.location.pathname) {
            case "/articles":
                activeMenuItem = 'articleList'; pageName = 'Articles'; iconName = 'newspaper outline'; break
            case "/article-reports":
                activeMenuItem = 'articleReports'; pageName = 'Article Reports'; iconName = 'line graph'; break
            case "/article-types":
                activeMenuItem = 'articleTypes'; pageName = 'Article Types'; iconName = 'tasks'; break
            case "/priority-types":
                activeMenuItem = 'priorityTypes'; pageName = 'Priority Types'; iconName = 'tasks'; break
            case "/video-types":
                activeMenuItem = 'videoTypes'; pageName = 'Video Types'; iconName = 'tasks'; break
            case "/closings":
              activeMenuItem = 'closings'; pageName = 'Closings'; iconName = 'calendar times outline'; break
            case "/closings/radio":
              activeMenuItem = 'closingsRadio'; pageName = 'Closings Radio'; iconName = 'calendar times outline'; break
            case "/closings/history":
              activeMenuItem = 'closingsHistory'; pageName = 'Closings History'; iconName = 'calendar times outline'; break
            case "/agencies":
                activeMenuItem = 'agencyList'; pageName = 'Agencies'; iconName = 'building outline'; break
            case "/snow-emergency":
                activeMenuItem = 'snowEmergency'; pageName = 'Snow Emergencies'; iconName = 'warning sign'; break
            case "/resources":
                activeMenuItem = 'resourceList'; pageName = 'Layout Resources'; iconName = 'globe'; break
            case "/contests":
                activeMenuItem = 'contests'; pageName = 'Contests'; iconName = 'trophy'; break
            case "/users":
                activeMenuItem = 'userList'; pageName = 'Users'; iconName = 'users'; break
            case "/games":
                activeMenuItem = 'gamesList'; pageName = 'Games'; iconName = 'ticket alternate'; break
            case "/schools":
                activeMenuItem = 'schoolList'; pageName = 'Schools'; iconName = 'university'; break
            case "/game-statuses":
                activeMenuItem = 'gameStatusList'; pageName = 'Game Statuses'; iconName= 'clock outline'; break
            case "/sports":
                activeMenuItem = 'sportList'; pageName = 'Sports'; iconName = 'football ball'; break
            case "/seasons":
                activeMenuItem = 'seasonList'; pageName = 'Seasons'; iconName = 'calendar alternate outline'; break
            case "/elections":
                activeMenuItem = 'electionList'; pageName = 'Elections'; iconName = 'book'; break
            case "/parties":
                activeMenuItem = 'partyList'; pageName = 'Parties'; iconName = 'address card outline'; break
            case "/election-counties":
                activeMenuItem = 'electionCountyList'; pageName = 'Election Counties'; iconName = 'map marker alternate'; break
            case "/promotional-restaurants":
              activeMenuItem = 'promotionalRestaurantList'; pageName = 'Promotional Restaurants'; iconName = 'food'; break
            default:
                activeMenuItem = 'articleList'; pageName = 'Editing...'; iconName = 'setting'; iLoading = true; break
        };

        return (
            <Sidebar.Pushable className="sidebarPushable">
                <Sidebar
                    borderless
                    vertical
                    as={Menu}
                    key="blue"
                    id="wmfdMenu"
                    icon='labeled'
                    direction="left"
                    animation='overlay'
                    className="menuSidebar"
                    onHide={this.props.closeNavMenu}
                    visible={this.props.isNavMenuVisible}
                    color={this.props.stormMode ? 'red' : 'blue'}
                >
                    <Menu.Item
                        disabled
                        className='sideBarItem'
                    >
                        <Icon
                            name='user'
                            className='sideBarIcon'
                        />
                        <h5>{this.props.user.name}</h5>
                    </Menu.Item>
                    <Divider/>
                    {!userRoles.filter(role => role.name === "admin" || role.name === "publisher").length ? null :
                        [
                          <Menu.Item
                            content='News'
                            className='sideBarItem sideBarHeader'
                          />,
                          <Menu.Item
                              name='articleList'
                              active={activeMenuItem === 'articleList'}
                              onClick={this.onMenuItemClick}
                              // content='Articles'
                              // icon='newspaper outline'
                              className='sideBarItem'
                          >
                              <Icon name='newspaper outline'/>
                              <h5>Articles</h5>
                          </Menu.Item>,
                        ]
                    }
                    {!userRoles.filter(role => role.name === "admin").length ? null :
                        [
                            <Menu.Item
                                key='articleReports'
                                name='articleReports'
                                // content='Article Reports'
                                // icon='line graph'
                                active={activeMenuItem === 'articleReports'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='line graph'/>
                                <h5>Article Reports</h5>
                            </Menu.Item>,
                            <Menu.Item
                                key='articleTypes'
                                name='articleTypes'
                                // content='Article Types'
                                // icon='tasks'
                                active={activeMenuItem === 'articleTypes'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='tasks'/>
                                <h5>Article Types</h5>
                            </Menu.Item>,
                            <Menu.Item
                                key='priorityTypes'
                                name='priorityTypes'
                                // content='Priority Types'
                                // icon='tasks'
                                active={activeMenuItem === 'priorityTypes'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='tasks'/>
                                <h5>Priority Types</h5>
                            </Menu.Item>,
                            <Menu.Item
                                key='videoTypes'
                                name='videoTypes'
                                // content='Video Types'
                                // icon='tasks'
                                active={activeMenuItem === 'videoTypes'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='tasks'/>
                                <h5>Video Types</h5>
                            </Menu.Item>,
                        ]
                    }
                    <Divider/>
                    <Menu.Item
                      content='COVID-19'
                      className='sideBarItem sideBarHeader'
                    />
                    <Menu.Item
                        key='promotionalRestaurantList'
                        name='promotionalRestaurantList'
                        // content='Closings'
                        // icon='calendar times outline'
                        active={activeMenuItem === 'promotionalRestaurantList'}
                        onClick={this.onMenuItemClick}
                        className='sideBarItem'
                    >
                        <Icon name='food'/>
                        <h5>Promo Restaurants</h5>
                    </Menu.Item>
                    {!userRoles.filter(role => role.name === "admin" || role.name === "closer" || role.name === "closing-admin").length ? null :
                        [
                            <Divider/>,
                            <Menu.Item
                              content='Closings and Delays'
                              className='sideBarItem sideBarHeader'
                            />,
                            <Menu.Item
                                key='closings'
                                name='closings'
                                // content='Closings'
                                // icon='calendar times outline'
                                active={activeMenuItem === 'closings'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='calendar times outline'/>
                                <h5>Closings</h5>
                            </Menu.Item>,
                            <Menu.Item
                              key='closingsRadio'
                              name='closingsRadio'
                              // content='Closings'
                              // icon='calendar times outline'
                              active={activeMenuItem === 'closingsRadio'}
                              onClick={this.onMenuItemClick}
                              className='sideBarItem'
                            >
                              <Icon name='calendar times outline'/>
                              <h5>Closings Radio</h5>
                            </Menu.Item>,
                            <Menu.Item
                              key='closingsHistory'
                              name='closingsHistory'
                              // content='Closings'
                              // icon='calendar times outline'
                              active={activeMenuItem === 'closingsHistory'}
                              onClick={this.onMenuItemClick}
                              className='sideBarItem'
                            >
                              <Icon name='calendar times outline'/>
                              <h5>Closings History</h5>
                            </Menu.Item>,
                            !userRoles.filter(role => role.name === "admin" || role.name === "closing-admin").length ? null :
                                <Menu.Item
                                  key='agencyList'
                                  name='agencyList'
                                  // content='Agencies'
                                  // icon='building outline'
                                  active={activeMenuItem === 'agencyList'}
                                  onClick={this.onMenuItemClick}
                                  className='sideBarItem'
                                >
                                  <Icon name='building outline'/>
                                  <h5>Agencies</h5>
                                </Menu.Item>
                            ,
                        ]
                    }
                    {!userRoles.filter(role => role.name === "admin" || role.name === "snow-reporter" || role.name === "closing-admin").length ? null :
                        [
                            <Menu.Item
                                key='snowEmergency'
                                name='snowEmergency'
                                // content='Snow Emergencies'
                                // icon='warning sign'
                                active={activeMenuItem === 'snowEmergency'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='warning sign'/>
                                <h5>Snow Emergencies</h5>
                            </Menu.Item>,
                        ]
                    }
                    {!userRoles.filter(role => role.name === "admin" || role.name === "score-reporter").length ? null :
                        [
                            <Divider/>,
                            <Menu.Item
                              content='Scoreboard'
                              className='sideBarItem sideBarHeader'
                            />,
                            <Menu.Item
                                name='seasonList'
                                // content='Seasons'
                                // icon='calendar alternate outline'
                                active={activeMenuItem === 'seasonList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='calendar alternate outline'/>
                                <h5>Seasons</h5>
                            </Menu.Item>,
                            <Menu.Item
                                name='gamesList'
                                // content='Games'
                                // icon='ticket alternate'
                                active={activeMenuItem === 'gamesList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='ticket alternate'/>
                                <h5>Games</h5>
                            </Menu.Item>,
                            <Menu.Item
                                name='schoolList'
                                // content='Schools'
                                // icon='university'
                                active={activeMenuItem === 'schoolList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='university'/>
                                <h5>Schools</h5>
                            </Menu.Item>,
                            <Menu.Item
                                name='sportList'
                                // content='Sports'
                                // icon='football ball'
                                active={activeMenuItem === 'sportList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='football ball'/>
                                <h5>Sports</h5>
                            </Menu.Item>,
                            <Menu.Item
                                name='gameStatusList'
                                // content='Game Statuses'
                                // icon='clock outline'
                                active={activeMenuItem === 'gameStatusList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='clock outline'/>
                                <h5>Game Statuses</h5>
                            </Menu.Item>,
                        ]
                    }
                    {!userRoles.filter(role => role.name === "admin" || role.name === "election-reporter").length ? null :
                        [
                            <Divider/>,
                            <Menu.Item
                              content='Elections'
                              className='sideBarItem sideBarHeader'
                            />,
                            <Menu.Item
                              name='electionCountyList'
                              active={activeMenuItem === 'electionCountyList'}
                              onClick={this.onMenuItemClick}
                              className='sideBarItem'
                            >
                              <Icon name='map marker alternate'/>
                              <h5>Election Counties</h5>
                            </Menu.Item>,
                            <Menu.Item
                                name='electionList'
                                // content='Elections'
                                // icon='book'
                                active={activeMenuItem === 'electionList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='book'/>
                                <h5>Elections</h5>
                            </Menu.Item>,
                            <Menu.Item
                                name='partyList'
                                // content='Parties'
                                // icon='address card outline'
                                active={activeMenuItem === 'partyList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='address card outline'/>
                                <h5>Parties</h5>
                            </Menu.Item>,
                        ]
                    }
                    {!userRoles.filter(role => role.name === "admin").length ? null :
                        [
                            <Divider/>,
                            <Menu.Item
                              content='Other Resources'
                              className='sideBarItem sideBarHeader'
                            />,
                            <Menu.Item
                                key='resourceList'
                                name='resourceList'
                                // content='Layout Resources'
                                // icon='globe'
                                active={activeMenuItem === 'resourceList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='globe'/>
                                <h5>Layout Resources</h5>
                            </Menu.Item>,
                            <Menu.Item
                                key='contests'
                                name='contests'
                                // content='Contests'
                                // icon='trophy'
                                active={activeMenuItem === 'contests'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='trophy'/>
                                <h5>Contests</h5>
                            </Menu.Item>,
                            <Menu.Item
                                key='userList'
                                name='userList'
                                // content='Users'
                                // icon='users'
                                active={activeMenuItem === 'userList'}
                                onClick={this.onMenuItemClick}
                                className='sideBarItem'
                            >
                                <Icon name='users'/>
                                <h5>Users</h5>
                            </Menu.Item>,
                        ]
                    }
                    <Divider/>
                    <Menu.Item
                        name='closeMenu'
                        // icon='close'
                        // text='Close Menu'
                        onClick={this.props.closeNavMenu}
                        className='sideBarItem'
                    >
                        <Icon name='close' color='red'/>
                        <h5>Close Menu</h5>
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={this.props.isNavMenuVisible}>
                    {this.props.children}
                </Sidebar.Pusher>


                {/*<div style={{paddingBottom: '80px'}} >*/}
                {/*  <Dimmer active={this.state.componentIsLoading} >*/}
                {/*    <Loader size='massive'>Loading</Loader>*/}
                {/*  </Dimmer>*/}
                {/*</div>*/}
            </Sidebar.Pushable>
        )
    }
};

export default withRouter(SideBarNavigation);
