import React, { Component } from 'react';
import {
  Table,
  Icon,
  Button,
  Dimmer,
  Loader
} from 'semantic-ui-react';
import { withRouter } from "react-router";


class VideoTypes extends Component {

  startNewType = () => {
    this.props.history.push('/video-types/add');
  }

  editType = (type) => {
    this.props.history.push('/video-types/edit/'+type.id);
  };


  render() {

    return (

      <div>

        <Dimmer active={this.props.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Table celled striped color="blue" selectable compact='very' size='small' className='small-table' >
          <Table.Header fullWidth >
            <Table.Row >
              <Table.HeaderCell textAlign="center" colSpan='2'>
                <span style={{ fontSize: "18px" }}>Click The Rows Below To Edit Active State Or Name.</span>
                <Button color="blue" onClick={this.startNewType} floated='right' size='mini' >Add Type</Button>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign="center" collapsing>
                <strong>Active</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Video Type Name</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.videoTypes.map( (type) => {
              return(
                <Table.Row
                  key={type.id.toString()}
                  id={type.id.toString()}
                  name={type.name}
                  activestate={type.isActive.toString()}
                  style={{cursor:'pointer'}}
                  onClick={ (e)=> {this.editType(type)} }
                >
                  <Table.Cell textAlign="center" collapsing>
                    <Icon name={ type.isActive ? 'check circle' : 'circle outline' } color="blue" />
                  </Table.Cell>
                  <Table.Cell> {type.name} </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

      </div>

    );
  }
}

export default withRouter(VideoTypes)

/*



*/
