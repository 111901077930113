import React, {Component} from "react";
import {withRouter} from "react-router";
import {Table, Dimmer, Loader, Button} from "semantic-ui-react";



class ViewTeamList extends Component {


    startAddTeam = () => {
        this.props.history.push('/teams/add');
    }

    startEditTeam = (team) => {
        this.props.history.push('/teams/edit/' + team.id);
    }

    render = () => {
        return (
            <div>

                <Dimmer
                    // active={this.props.componentIsLoading}
                    page
                >
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <Table
                    celled
                    striped
                    color="blue"
                    selectable
                    compact='very'
                    size='small'
                    className='small-table'
                >
                    <Table.Header fullWidth >
                        <Table.Row >
                            <Table.HeaderCell textAlign="center" colSpan='2'>
                                <span style={{ fontSize: "18px" }}>
                                    Click The Rows Below To Edit Active State Or Name.
                                </span>
                                <Button
                                    color="blue"
                                    onClick={this.startAddTeam}
                                    floated='right'
                                    size='mini'
                                >
                                    Add Team
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center" collapsing>
                                <strong>Id</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan='2'>
                                <strong>Team</strong>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.teams.map( (team) => {
                            return(
                                <Table.Row
                                    key={team.id.toString()}
                                    id={team.id.toString()}
                                    name={team.name}
                                    style={{cursor:'pointer'}}
                                    onClick={ (e) => {this.startEditTeam(team)} }
                                >
                                    <Table.Cell>{team.id}</Table.Cell>
                                    <Table.Cell>{team.name} </Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>

            </div>
        )
    };
};

export default withRouter(ViewTeamList);