import React, {Component} from 'react'
import APIService from "../API";
import {Button, Form, Icon, Modal} from "semantic-ui-react";
import EditVoteCount from "./EditVoteCount";

const defaultState = {
  name: "",
  voteCount: "",
  partyId: 0,
  isDeleteModalOpen: false,
}

class EditCandidate extends Component {

  state = defaultState

  componentDidMount = () => {
    if (this.isEdit()) {
      this.setAPIDerivedState()
    }
  }

  isEdit = () => !!this.props.candidate

  resetState = () => this.setState(defaultState);

  setAPIDerivedState = () => {
    const candidate = this.props.candidate
    this.setState({
      name: candidate.name,
      voteCount: candidate.voteCount,
      partyId: candidate.party.id,
    })
  }

  patchCandidate = (event) => {
    event.preventDefault()
    const candidate = this.props.candidate
    fetch(APIService.serverUrl + "/api/private/candidate" + (this.isEdit() ? "/" + candidate.id : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        name: this.state.name,
        voteCount: Number(this.state.voteCount),
        partyId: this.state.partyId,
        raceId: this.isEdit() ? undefined : Number(this.props.raceId),
      })
    })
      .then(response => response.ok ? response.json() : null)
      .then(json => {
        if (json) {
          this.props.refresh()
          if (!this.isEdit()) {
            this.resetState()
          }
        } else {
          alert('Saving candidate ' + this.state.name + ' failed')
        }
      })
  }

  deleteCandidate = (event) => {
    const candidate = this.props.candidate;
    event.preventDefault();
    fetch(APIService.serverUrl + "/api/private/candidate/" + candidate.id,  {
      method: "DELETE",
      headers: APIService.authHeaders(),
      body: JSON.stringify({}),
    })
      .then((response) => {
        if(response.ok) {
          alert("This Candidate has been deleted.")
          this.props.refresh();
        }
      })
  }

  render = () => {
    return (
      <div>
        <Form className="candidateForm" >
          <Form.Group>
            <Form.Select
              value={this.state.partyId}
              onChange={(event, data) => this.setState({partyId: data.value})}
              loading={this.props.isFetchingParties}
              disabled={this.isEdit() ? true : false}
              options={this.props.parties.map(party => ({value: party.id, text: party.name}))}
              label='Party'
            />
            <Form.Input
              value={this.state.name}
              onChange={event => this.setState({name: event.target.value})}
              label='Name'
            />
            {!this.props.totalVoteCount ? null :
              <Form.Input
                value={this.state.voteCount}
                onChange={event => this.setState({voteCount: event.target.value})}
                label='Count'
                type='number'
              />
            }

            <Form.Group style={{margin: 'auto auto 5px 20px'}}>
              <Button.Group>
                <Button
                  size='small'
                  onClick={this.patchCandidate}
                  content={this.isEdit() ? "Save" : "Add"}
                  color={this.isEdit() ? "green" : "blue"}
                />
                {!this.isEdit() ? null :
                  <Button
                    size='small'
                    color='red'
                    content='Delete'
                    onClick={() => {
                      this.setState({isDeleteModalOpen: true})
                    }}
                  />
                }
              </Button.Group>
            </Form.Group>

          </Form.Group>
        </Form>

        {!this.isEdit() || this.props.totalVoteCount ? null :
          <div>
            {this.props.candidate.voteCounts.map((count) => {
              return (
                <EditVoteCount
                  voteCount={count}
                  electionCountyOptions={this.props.electionCounties}
                  candidateId={this.props.candidate.id}
                  readEntity={this.readIssue}
                />
              )
            })}
            <EditVoteCount
              electionCountyOptions={this.props.electionCounties}
              candidateId={this.props.candidate.id}
              readEntity={this.props.refresh}
            />
          </div>
        }

        {/*Delete Modal*/}
        <Modal open={this.state.isDeleteModalOpen}>
          <Modal.Header>
            Are you sure you want to delete Candidate &nbsp;
            <font color='blue'>
              {this.state.name}
            </font>?
          </Modal.Header>
          <Modal.Content>
            <Form.Field>
              <Button
                size='mini'
                color='grey'
                style={{marginRight: '10px'}}
                onClick={() => {
                  this.setState({isDeleteModalOpen: false})
                }}
              >
                <Icon name='left arrow'/>
                No
              </Button>
              <Button
                size='mini'
                color='red'
                onClick={this.deleteCandidate}
              >
                <Icon name='delete'/>
                Delete
              </Button>
            </Form.Field>
          </Modal.Content>
        </Modal>
      </div>
    )
  }
}

export default EditCandidate
