import React, {Component} from 'react';
import APIService from "../API";
import {Button, Form} from "semantic-ui-react";

const defaultState = {
  forCount: 0,
  againstCount: 0,
  electionCountyId: 0,

  isUpdatingVoteCount: false,
}

class EditVoteCount extends Component {

  state = defaultState

  componentDidMount() {
    if(this.isEdit()) {
      this.setExistingState()
    }
  }

  resetState = () => this.setState(defaultState);

  isEdit = () => this.props.voteCount !== undefined;
  isCandidateVote = () => this.props.candidateId !== undefined;

  voteCountId = () => this.props.voteCount.id

  updateVoteCount = (event) => {
    event.preventDefault();
    this.setState({isUpdatingVoteCount: true});
    fetch(APIService.serverUrl + "/api/private/vote-count" + (this.isEdit() ? "/" + this.voteCountId() : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        forCount: Number(this.state.forCount),
        againstCount: Number(this.state.againstCount),
        electionCountyId: Number(this.state.electionCountyId),
        issueId: this.props.issueId,
        candidateId: this.props.candidateId,
      })
    })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return null;
          }
        })
        .then((json) => {
          this.setState({isUpdatingVoteCount: false});
          if (json) {
            this.props.readEntity();
            this.resetState()
          }
        })
  }

  setExistingState = () => {
    this.setState({
      forCount: this.props.voteCount.forCount,
      againstCount: this.props.voteCount.againstCount,
      electionCountyId: this.props.voteCount.electionCounty.id
    })
  }

  render = () => {

    let voteCount = this.props.voteCount

    return (
      <Form className="voteForm" onSubmit={this.updateVoteCount} >
        <Form.Group>
          <Form.Input
            label={this.isCandidateVote() ? "Votes" : "For"}
            value={this.state.forCount}
            onChange={(event) => {
              this.setState({forCount: event.target.value})
            }}
          />
          {this.isCandidateVote() ? null :
            <Form.Input
              label="Against"
              value={this.state.againstCount}
              onChange={(event) => {
                this.setState({againstCount: event.target.value})
              }}
            />
          }
          <Form.Select
            label="County"
            value={this.state.electionCountyId}
            options={this.props.electionCountyOptions.map((county) => ({
              text: county.name,
              value: county.id
            }))}
            onChange={(event, data) => {
              this.setState({electionCountyId: data.value})
            }}
          />

          <Form.Group style={{margin: 'auto auto 3px 20px'}}>
            <Button
              size="small"
              type="submit"
              content={this.isEdit() ? "Save" : "Add"}
              color={this.isEdit() ? "green" : "blue"}
            />
          </Form.Group>
        </Form.Group>
      </Form>
    )
  }
}

export default EditVoteCount;
