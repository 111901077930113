import React, { Component } from 'react';
import {Grid, Icon, Modal, Header, Image, Dimmer, Loader, Container, Label, Form, Button, Checkbox, Dropdown, Input, } from 'semantic-ui-react'
import APIService from '../API.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactJWPlayer from 'react-jw-player';
import { withRouter } from "react-router";
import Helpers from '../Helpers.js';
import wordCount from "html-word-count";


const initialArticleVideoState = {
  id: 1,
  title: 'TEST',
  location: 'LOCATION TEXT',
  articleVideoType: {
    id: 0,
    name: '',
  },
}

class EditArticle extends Component {

  articleId = this.props.match.params.articleId;

  state = {
    shouldAutoPostToSocial: false,
    articlePriorityId: 0,
    isApproved: false,
    isFeatured: false,
    approvedByUser: '',
    headline: '',
    endDate: new moment().endOf('day'),
    tag: '',
    waitingFor: '',
    shouldShowGenericAuthor: false,
    subHeadline: '',
    startDate: new moment().startOf('day'),
    mainImageId: 0,
    articleTypeIds: [],
    viewCount: 0,
    createdAt: new moment().startOf('day'),
    updatedAt: new moment().startOf('day'),
    approvedDisabled: false,

    unModifiedArticle: {},

    articlePriority: [],
    articleType: [],
    videoType: [],
    articleAuthors: [],
    articleImages: [],

    newArticleVideo: {
      title: '',
      description: '',
      location: '',
      videoTypeId: 0,
    },

    articleVideo: initialArticleVideoState,

    editorState: EditorState.createEmpty(),
    editorStateTest: EditorState.createEmpty(),
    storyDraft: '',
    openVideoModal: false,
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getArticle();
    this.getType('videoType');
    this.getType('articleType');
    this.getType('articlePriority');
  }

  getArticle = () => {
    fetch(APIService.serverUrl + '/api/private/article/'+this.articleId, {
      method: "GET",
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("ArticleList Fetch Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("getArticle Request Succeeded!");
        // console.log(json);
        const storyBlock = htmlToDraft(json.story);
        let storyEditorState = null;
        if (storyBlock) {
          const storyContentState = ContentState.createFromBlockArray(storyBlock.contentBlocks);
          storyEditorState = EditorState.createWithContent(storyContentState);
        }
        let tempArticle = {
          "shouldAutoPostToSocial": json.shouldAutoPostToSocial,
          "articlePriorityId": json.articlePriority.id,
          'isApproved': json.isApproved,
          'approvedDisabled': json.mainImageId ? false : true,
          "isFeatured": json.isFeatured,
          "approvedByUser": json.approvedByUser,
          "headline": json.headline,
          "endDate": moment(json.endDate),
          "tag": json.tag,
          "waitingFor": json.waitingFor,
          "shouldShowGenericAuthor": json.shouldShowGenericAuthor,
          "subHeadline": json.subHeadline,
          "startDate": moment(json.startDate),
          "mainImageId": json.mainImageId,
          "articleTypeIds": json.articleTypes.map( articleTypes => articleTypes.id ),
          "articleTypes": json.articleTypes,
          "viewCount": json.viewCount,
          "createdAt": moment(json.createdAt),
          "updatedAt": moment(json.updatedAt),
          'editorState': storyEditorState,
        };
        this.setState({
          ...tempArticle,
          'unModifiedArticle':{
            ...tempArticle,
          },
          'articleAuthors': json.authors,
          'articleImages': json.images,
          'articleVideo': json.articleVideo,
          'componentIsLoading': false,
        });
      } else {
        console.log("ArticleList Fetch Failed")
      }
    })
  };

  getType = (type) => {
    fetch(APIService.serverUrl + '/api/private/'+type, {
      method: "GET",
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log(type+" Fetch Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log(type+" Request Succeeded!");
        this.setState({
          [type]: json,
        })
      } else {
        console.log(type+" Fetch Failed")
      }
    })
  };

  patchArticle = () => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl + '/api/private/article/'+this.articleId, {
      method: 'PATCH',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        "shouldAutoPostToSocial": this.state.shouldAutoPostToSocial,
        "articlePriorityId": this.state.articlePriorityId,
        "isApproved": this.state.isApproved,
        "isFeatured": this.state.isFeatured,
        "approvedByUser": this.state.approvedByUser,
        "headline": this.state.headline,
        "endDate": moment(this.state.endDate),
        "tag": this.state.tag,
        "waitingFor": this.state.waitingFor,
        "shouldShowGenericAuthor": this.state.shouldShowGenericAuthor,
        "subHeadline": this.state.subHeadline,
        "startDate": moment(this.state.startDate),
        "mainImageId": this.state.mainImageId,
        "articleTypeIds": this.state.articleTypeIds,
        "createdAt": moment(this.state.createdAt),
        "updatedAt": moment(this.state.updatedAt),
        "story": draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        "wordCount": wordCount(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))),
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("patchArticle PATCH Failed: ", response.status, response.statusText)
        alert(response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        console.log("patchArticle PATCH Succeeded!");
        this.setState({
          'componentIsLoading': false,
        });
        this.props.history.replace('/articles/edit/'+json.id);
        this.props.refreshList();
        this.getArticle();
        // this.props.history.push('/articles');
      } else {
        console.log("patchArticle PATCH Failed")
      }
    })
  };

  addArticleVideo = (e) => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl + '/api/private/articleVideo', {
      headers: APIService.authHeaders(),
      method: 'POST',
      body: JSON.stringify({
        "articleVideoTypeId": Number(this.state.newArticleVideo.videoTypeId),
        "title": this.state.newArticleVideo.title,
        "description": this.state.newArticleVideo.description,
        "location": this.state.newArticleVideo.location,
        "articleId": Number(this.articleId),
      }),
    }).then(
      response => {
        response.json()
        // console.log('Response section')
      }
    ).then(
      success => {
        // console.log('addVideo Succeeded!');
        this.onVideoModalClose();
        this.setState({ 'componentIsLoading': false, });
        this.getArticle();
      }
    ).catch(
      error => console.log(error)
    );
  }

  deleteArticleImage = (id) => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl+'/api/private/image/'+id, { //image only in path
      method: 'DELETE',
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        // console.log("deleteImage Succeeded!");
        this.setState({ 'componentIsLoading': false, });
        this.getArticle();
      } else {
        console.log("deleteImage Failed: ", response.status, response.statusText)
      }
    })
  }

  deleteArticelVideo = (id) => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl+'/api/private/articleVideo/'+id, {
      method: 'DELETE',
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        // console.log("deleteImage Succeeded!");
        this.setState({
          'componentIsLoading': true,
          "articleVideo": initialArticleVideoState
        })
        this.getArticle();
      } else {
        console.log("deleteImage Failed: ", response.status, response.statusText)
      }
    })
  }

  setNewVideoState = (object) => {
    let modifyVideo = {
      ...this.state.newArticleVideo,
      ...object
    }
    this.setState({ newArticleVideo: modifyVideo });
  }

  onVideoInputChange = (e) => {
    this.setNewVideoState({ [e.target.name]: e.target.value, });
  }

  onMainImageToggleChange = (id) => {
    fetch(APIService.serverUrl + '/api/private/article/'+this.articleId, {
      method: 'PATCH',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        "shouldAutoPostToSocial": this.state.shouldAutoPostToSocial,
        "articlePriorityId": this.state.articlePriorityId,
        "isApproved": this.state.isApproved,
        "isFeatured": this.state.isFeatured,
        "approvedByUser": this.state.approvedByUser,
        "headline": this.state.headline,
        "endDate": moment(this.state.endDate),
        "tag": this.state.tag,
        "waitingFor": this.state.waitingFor,
        "shouldShowGenericAuthor": this.state.shouldShowGenericAuthor,
        "subHeadline": this.state.subHeadline,
        "startDate": moment(this.state.startDate),
        "articleTypeIds": this.state.articleTypeIds,
        "createdAt": moment(this.state.createdAt),
        "updatedAt": moment(this.state.updatedAt),
        "story": draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        "wordCount": wordCount(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))),
        "mainImageId": this.state.mainImageId === id ? null : id,
      })
    })
    .then((response) => {
      if (response.ok) {
        // console.log('main image toggle worked');
        this.setState({
          mainImageId: this.state.mainImageId === id ? null : id,
        })
        // console.log('mainImageId: ', this.state.mainImageId);
        if (this.state.mainImageId === 0 || this.state.mainImageId === null) {
          this.setState({ "isApproved": false, "approvedDisabled": true, })
        } else if (this.state.mainImageId !== 0 || this.state.mainImageId !== null) {
          this.setState({ "approvedDisabled": false, })
        }
      } else {
        console.log("patchArticle PATCH Failed: ", response.status, response.statusText)
      }
    })
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  onSelectChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  onVideoSelectChange = (e, { name, value }) => {
    this.setNewVideoState({ [name]: value })
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: moment(date) })
  }

  onEndDateChange = (date) => {
    this.setState({ endDate: moment(date) })
  }

  onToggleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  openVideoModal = () => this.setState({ openVideoModal: true, })

  onVideoModalClose = () => {
    this.setState({
      openVideoModal: false,
      newArticleVideo: {
        title: '',
        description: '',
        location: '',
      },
    });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  videoLayout = () => {
    if (this.state.articleVideo !== undefined) {
      return (
        <Grid.Column width="16" >
          <div width='auto' height='auto' >
            {this.renderArticleVideo()}
            <div style={{position: 'absolute', top: 0, width: 'auto', height: 'auto' }} >
              <Button
                size='mini'
                circular
                color='red'
                icon='cancel'
                onClick={ () => this.deleteArticelVideo(this.state.articleVideo.id) }/>
            </div>
          </div>
        </Grid.Column>
      )
    } else {
      return (
        <Grid.Column width="16" >
          <Button size='mini' color='green' onClick={this.openVideoModal} >
            <Icon name='film' /> Add Video
          </Button>
        </Grid.Column>
      )
    }
  };

  renderArticleVideo = () => {
    switch (this.state.articleVideo.articleVideoType.name) {
      case "JWPlatform":
        return (
          <ReactJWPlayer
            licenseKey='gNewmJcQkvWHH9BwSK37cRg2jvz7OPoiY+Uohg=='
            playlist={'//content.jwplatform.com/v2/media/' + this.state.articleVideo.location}
            playerId='lTCrLok1'
            playerScript={'https://content.jwplatform.com/players/' + this.state.articleVideo.location + '-lTCrLokv.js'}
            aspectRatio='16:9'
            customProps={{
              width: '100%',
              advertising: {
                "admessage": "This video will resume in xx seconds",
                "adscheduleid": "s1OFE6R4",
                "client": "googima",
                "schedule": [
                  {
                    "offset": "pre",
                    "tag": "https://pubads.g.doubleclick.net/gampad/ads?sz=720x400&iu=/20137842/VideoNewsPreRoll&ciu_szs&impl=s&gdfp_req=1&env=vp&output=xml_vast2&unviewed_position_start=1&url=[referrer_url]&correlator=[timestamp]",
                    "type": "linear"
                  }
                ]
              }
            }}
          />
        );
      default:
        return (
          <div className="videoContainer">
            {this.state.articleVideo &&
              <iframe src={this.state.articleVideo.location} frameBorder="0" allowFullScreen/>
            }
          </div>
        );
    }
  };

  isFormModified = () => {
    for (var key in this.state.unModifiedArticle) {
      if (this.state.unModifiedArticle[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  }

  render() {

    let requiredFieldsComplete =
      this.state.headline.length !== 0 &&
      this.state.subHeadline.length !== 0 &&
        draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) !==
        draftToHtml(convertToRaw(this.state.editorStateTest.getCurrentContent())) &&
      this.state.articlePriorityId !== 0 &&
      this.state.articleTypeIds !== 0;

    let { user } = this.props;

    let isRestrictedPublisher =
      !user.roles.filter(role => role.name === "admin").length
      && user.roles.filter(role => role.name === "publisher").length
      && user.articleTypes.length;

    return(

      <Container fluid >

        <Dimmer active={this.state.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

            <Form>
              <Header
                dividing
                icon='edit outline'
                color='blue'
                content={'Edit Article '+this.articleId+' - '+this.state.headline} />
              {/* View Count */}
              <Form.Group inline>
                <label>View Count:</label>
                <strong>{this.state.viewCount}</strong>
              </Form.Group>
              {/* Headline */}
              <Form.Field>
                <label>
                  Headline:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue' />
                </label>
                <Input
                  id='headline'
                  name='headline'
                  placeholder='Enter the Headline...'
                  value={this.state.headline}
                  onChange={this.onInputChange} />
              </Form.Field>
              {/* Sub Headline */}
              <Form.Field>
                <label>
                  Sub Headline:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue' />
                </label>
                <Input
                  id='subHeadline'
                  name='subHeadline'
                  placeholder='Enter the Sub Headline...'
                  value={this.state.subHeadline}
                  onChange={this.onInputChange} />
              </Form.Field>
              {/* TAG */}
              <Form.Field>
                <label>Tag: </label>
                <Input
                  id='tag'
                  name='tag'
                  placeholder='Enter the Tag...'
                  value={this.state.tag}
                  onChange={this.onInputChange} />
              </Form.Field>
              {/* Waiting For */}
              <Form.Field>
                <label>Waiting For: </label>
                <Input
                  id='waitingFor'
                  name='waitingFor'
                  placeholder='Waiting For...'
                  value={this.state.waitingFor}
                  onChange={this.onInputChange} />
              </Form.Field>
            </Form>
              {/* Story */}
            <div style={{maxWidth: "768px", margin: "20px auto"}}>
              <label>
                Story:&nbsp;
                <Icon
                  name='asterisk'
                  size='small'
                  color='blue' />
              </label>
              <Editor
                toolbar={{
                  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'history', 'embedded'],
                  inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                  },
                  blockType: {
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote', 'Code'],
                  },
                  fontSize: {
                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
                  },
                  textAlign: { inDropdown: true, },
                  list: { inDropdown: true, },
                }}
                editorState={this.state.editorState}
                onEditorStateChange={this.onEditorStateChange} />
              <textarea
                disabled
                hidden
                value={this.state.editorState} />
              <p>Word Count: { wordCount(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))}</p>
            </div>
            <Form>
              {/* Author(s) */}
              <Form.Group inline>
                <label>Authors:</label>
                <strong>{this.state.articleAuthors.map( (author, i) => { return ( i ? ', ' : '' ) + author.name })}</strong>
              </Form.Group>
              {/* Video */}
              {isRestrictedPublisher ? null :
                <Form.Field>
                  <label>Video: </label>
                  <Grid>
                    <Grid.Row>
                      { this.videoLayout() }
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              }
              {/* Images */}
              <Form.Field >
                <label>Images: </label>
                <Grid>
                  <Grid.Row>
                    { this.state.articleImages.map( image => {
                        return (
                          <Grid.Column width="4" style={{minHeight: '80px'}} key={image.id} >
                            {/** Will add onClick function here to edit original Image **/}
                            <Image width='auto' height='auto'
                              src={Helpers.getOptimizedImage(image, 263)} />

                            <div style={{position: 'absolute', top: 0, width: 'auto', height: 'auto' }}>
                              <Button
                                size='mini'
                                circular
                                color='red'
                                icon='cancel'
                                disabled={this.state.mainImageId === image.id}
                                onClick={ () => this.deleteArticleImage(image.id) }/>
                            </div>
                            <div
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                height: 'auto',
                                backgroundColor: 'rgba(255,255,255,0.9)',
                                padding: 4,
                                verticalAlign: 'middle',
                              }}>
                              <Checkbox
                                checked={this.state.mainImageId === image.id}
                                onChange={ () => this.onMainImageToggleChange(image.id) }
                                toggle />&nbsp; &nbsp; Main Image
                            </div>
                          </Grid.Column>
                        )
                      })
                    }
                    <Grid.Column width="4" verticalAlign='middle'  >
                      <Button
                        size='mini'
                        color='green'
                        onClick={ (e)=> {this.props.history.push(
                          '/add-image/article/'
                          + this.articleId
                          + (this.state.articleTypes.some(v => v.name === "Obituaries") ? "/no-aspect" : "")
                        )}} >
                        <Icon name='image outline' /> Add Image
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <Label size='tiny' pointing color='red' basic >
                  <Icon
                    name='asterisk'
                    size='small'
                    color='red' />
                  "Main Image" is required for Article Approval.
                </Label>
              </Form.Field>
              <Form.Group  widths='equal'>
                {/* Start Date */}
                <Form.Field>
                  <label>Start Date: </label>
                  <DatePicker
                    name='startDate'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    showTimeSelect
                    timeIntervals={15}
                    timeCaption="Time"
                    selected={this.state.startDate.toDate()}
                    onChange={this.onStartDateChange} />
                </Form.Field>
                {/* End Date */}
                <Form.Field>
                  <label>End Date: </label>
                  <DatePicker
                    name='endDate'
                    dateFormat='MM/dd/yyyy h:mm aa'
                    showTimeSelect
                    timeIntervals={15}
                    timeCaption="Time"
                    selected={this.state.endDate.toDate()}
                    onChange={this.onEndDateChange} />
                </Form.Field>
              </Form.Group>
              {/* Article Type */}
              <Form.Field>
                <label>
                  Article Type:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue' />
                </label>
                <Dropdown
                  id="articleTypeIds"
                  name='articleTypeIds'
                  placeholder='Choose the Article Type...'
                  fluid
                  multiple
                  selection
                  options={this.state.articleType.map(
                    aType => { return { key: aType.id, text: aType.name, value: aType.id }
                  })}
                  value={this.state.articleTypeIds}
                  onChange={this.onSelectChange} />
              </Form.Field>
              {/* Article Priority */}
              <Form.Field>
                <label>
                  Article Priority:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue' />
                </label>
                <Form.Select
                  id="articlePriorityId"
                  name='articlePriorityId'
                  options={this.state.articlePriority.map(
                    pChoice => { return { key: pChoice.id, text: pChoice.name, value: pChoice.id }
                  })}
                  placeholder='Choose the Article Priority...'
                  value={this.state.articlePriorityId}
                  onChange={this.onSelectChange} />
              </Form.Field>
              <Form.Group widths='equal'>
                {/* Approved */}
                <Form.Field>
                  <label>Approved: </label>
                  <Form.Checkbox
                    id="isApproved"
                    name='isApproved'
                    disabled={this.state.approvedDisabled}
                    checked={this.state.isApproved}
                    onChange={this.onToggleChange}
                    toggle />
                </Form.Field>

                {/* Featured */}
                {isRestrictedPublisher ? null :
                  <Form.Field>
                    <label>Featured: </label>
                    <Form.Checkbox
                      id="isFeatured"
                      name='isFeatured'
                      checked={this.state.isFeatured}
                      onChange={this.onToggleChange}
                      toggle />
                  </Form.Field>
                }
                {/* Post To Social */}
                {isRestrictedPublisher ? null :
                  <Form.Field>
                    <label>Post To Social: </label>
                    <Form.Checkbox
                      id="shouldAutoPostToSocial"
                      name='shouldAutoPostToSocial'
                      checked={this.state.shouldAutoPostToSocial}
                      onChange={this.onToggleChange}
                      toggle />
                  </Form.Field>
                }
                {/* Show Generic Author */}
                {isRestrictedPublisher ? null :
                  <Form.Field>
                    <label>Generic Author: </label>
                    <Form.Checkbox
                      id="shouldShowGenericAuthor"
                      name='shouldShowGenericAuthor'
                      checked={this.state.shouldShowGenericAuthor}
                      onChange={this.onToggleChange}
                      toggle />
                  </Form.Field>
                }
              </Form.Group>
              <Form.Field>
                <Label size='tiny' >
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue' />
                  Denotes required field
                </Label>
              </Form.Field>

              {/* Cancel/Save Button */}
              <Form.Field>
                <Button
                  size='mini'
                  color={ this.isFormModified() ? 'red' : undefined }
                  onClick={ () => this.props.history.push('/articles') } >
                  <Icon name='cancel' /> { this.isFormModified() ? 'Cancel' : 'Close' }
                </Button>
                <Button
                  size='mini'
                  color='green'
                  disabled={ !requiredFieldsComplete || !this.isFormModified()}
                  onClick={this.patchArticle} >
                  <Icon name='save outline' /> Save
                </Button>
              </Form.Field>
            </Form>
        
        {/* Add Video Modal */}
          <Modal id='vidModal' open={this.state.openVideoModal} centered={false} size='tiny'>
            <Modal.Content>
              <Form >
                <Header dividing icon='film' content='Add Video' color='blue' />
                <Form.Field>
                  <label>Title: </label>
                  <Form.Input
                    name="title"
                    value={this.state.newArticleVideo.title}
                    onChange={this.onVideoInputChange} />
                </Form.Field>
                <Form.Field>
                  <label>Description: </label>
                  <Form.Input
                    name="description"
                    value={this.state.newArticleVideo.description}
                    onChange={this.onVideoInputChange} />
                </Form.Field>
                <Form.Field>
                  <label>Type: </label>
                  <Form.Select
                    id="videoTypeId"
                    name='videoTypeId'
                    options={this.state.videoType.map(
                      tChoice => { return { key: tChoice.id, text: tChoice.name, value: tChoice.id }
                    })}
                    placeholder='Choose the Video Type...'
                    value={this.state.newArticleVideo.videoTypeId}
                    onChange={this.onVideoSelectChange} />
                </Form.Field>
                <Form.Field>
                  <label>
                    Location: (if type iframe then only add the src value)
                  </label>
                  <Form.TextArea
                    name="location"
                    value={this.state.newArticleVideo.location}
                    onChange={this.onVideoInputChange} />
                </Form.Field>
                <Form.Field>
                  <Button size='tiny' color='red' onClick={this.onVideoModalClose} >
                    <Icon name='cancel' /> Cancel
                  </Button>
                  <Button size='tiny' color='green' onClick={this.addArticleVideo} >
                    <Icon name='save outline' /> Save
                  </Button>
                </Form.Field>
              </Form>
            </Modal.Content>
          </Modal>
      </Container>
    );
  }
}

export default withRouter(EditArticle);

/*



*/
