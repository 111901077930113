import React, {Component} from "react";
import {withRouter} from "react-router";
import {Table, Icon, Button, Dimmer, Loader} from 'semantic-ui-react';


class ViewSchoolList extends Component {

    startAddSchool = () => {
        this.props.history.push('/schools/add');
    }

    startEditSchool = (school) => {
        this.props.history.push('/schools/edit/'+school.id);
    }

    render = () => {
        return (
            <div>

                <Dimmer
                    // active={this.props.componentIsLoading}
                    page
                >
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <Table
                    celled
                    striped
                    color="blue"
                    selectable
                    compact='very'
                    size='small'
                    className='small-table'
                >
                    <Table.Header fullWidth >
                        <Table.Row >
                            <Table.HeaderCell textAlign="center" colSpan='2'>
                                <span style={{ fontSize: "18px" }}>
                                    Click The Rows Below To Edit Active State Or Name.
                                </span>
                                <Button
                                    color="blue"
                                    onClick={this.startAddSchool}
                                    floated='right'
                                    size='mini'
                                >
                                    Add School
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center" collapsing>
                                <strong>Id</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell >
                                <strong>Name</strong>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.schools.map( (school) => {
                            return(
                                <Table.Row
                                    key={school.id.toString()}
                                    id={school.id.toString()}
                                    name={school.name}
                                    style={{cursor:'pointer'}}
                                    onClick={ (e) => {this.startEditSchool(school)} }
                                >
                                    <Table.Cell>{school.id}</Table.Cell>
                                    <Table.Cell>{school.name}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>

            </div>
        )
    };
};

export default withRouter(ViewSchoolList);
