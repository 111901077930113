import APIService from "./API";

export default class Helpers {

  static authorsCommaSeparated(article) {
    let safeAuthors = article.authors;
    if (safeAuthors === undefined || safeAuthors === null) safeAuthors = [];
    return article.shouldShowGenericAuthor ? 'WMFD Staff' : safeAuthors.map((a,i) => (i ? ', ':'') + a.name);
  };

  static getOptimizedImage = (mainImage, size) => { // 900, 750, 360, 263
    let optimizedImages = mainImage.optimizedImages ? mainImage.optimizedImages : [];
    for (let optimizedImage of optimizedImages) {
      if (optimizedImage.width === size) {
        return APIService.serverUrl + optimizedImage.location
      }
    }
  }

  static generateGameName = (primarySchoolName, secondarySchoolName, otherPrimarySchoolName, otherSecondarySchoolName) => {
    let finalPrimarySchoolName = "N/A";
    let finalSecondarySchoolName = "N/A";
    if (otherPrimarySchoolName) {
      finalPrimarySchoolName = otherPrimarySchoolName
    } else if (primarySchoolName) {
      finalPrimarySchoolName = primarySchoolName
    }
    if (otherSecondarySchoolName) {
      finalSecondarySchoolName = otherSecondarySchoolName
    } else if (secondarySchoolName) {
      finalSecondarySchoolName = secondarySchoolName
    }
    return finalSecondarySchoolName + " v " + finalPrimarySchoolName;
  };

  static generateGameNameFromGame = (game) => {
    return Helpers.generateGameName(
      (game.primarySchool ? game.primarySchool.name : null),
      (game.secondarySchool ? game.secondarySchool.name : null),
      game.otherPrimarySchoolName,
      game.otherSecondarySchoolName,
    );
  };

}


/* TEST Comment */
