import React, { Component } from "react"
import { Route } from "react-router-dom";
import ViewSnowEmergencies from "../Views/ListSnowEmergencies.js";
import EditSnowEmergency from "../Editors/EditSnowEmergency.js";
import Constants from '../Constants.js';
import APIService from "../API.js";

class SnowEmergencyController extends Component {

state = {
  snowEmergencyCounties: [],
  snowEmergencyModes: [],
  componentIsLoading: true,
}

componentDidMount() {
  this.fetchSnowEmergencyCounties();
  this.fetchSnowEmergencyModes();
}

fetchSnowEmergencyCounties = () => {
  fetch(APIService.serverUrl + '/api/private/snowEmergencyCounty', {
    method: "GET",
    headers: APIService.authHeaders(),
  })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("getAgencyList Fetch Failed: ", response.status, response.statusText)
        if (response.status === 401) { this.props.history.push('/') };
        return null
      }}).then((json) => {
    if (json) {
      this.setState({
        snowEmergencyCounties: json,
        componentIsLoading: false,
      })
    } else {
      console.log("Fetch Snow Emergency Counties Failed")
    }
  })
};

fetchSnowEmergencyModes = () => {
  fetch(APIService.serverUrl + '/api/private/snowEmergencyMode', {
    method: "GET",
    headers: APIService.authHeaders(),
  }).then(response => response.ok ? response.json() : null).then((json) => {
    if (json) {
      this.setState({snowEmergencyModes: json})
    } else {
      console.log("fetchSnowEmergencyModes Failed")
    }
  })
};

render = () => {

  let isMobile = Constants.isMobile();
  let relativePath = this.props.match.path;

  return (
    <div>
      <Route
        path={relativePath}
        exact={isMobile}
        render={ () =>
          <ViewSnowEmergencies
            {...this.state}
            {...this.props}
            onStormModeChange={this.props.onStormModeChange} />
        }
      />
      <Route
        path={relativePath + "/:addEdit(add|edit)/:countyId?"}
        render={() => <EditSnowEmergency refreshList={this.fetchSnowEmergencyCounties} />}
      />
    </div>
  )
}
}

export default SnowEmergencyController
