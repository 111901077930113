import React, { Component } from 'react';
import { Dimmer, Loader, Container } from 'semantic-ui-react'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Constants from './Constants.js';
import APIService from './API.js';
import Login from './Login.js';
import SideBarNavigation from './SideBarNavigation';
import 'semantic-ui-css/semantic.min.css';
import './App.css';
import HeaderMenu from "./HeaderMenu";
import ArticleListController from "./Controllers/ArticleListController";
import Cropper from "./Cropper";
import ViewArticlesReport from "./Views/ViewArticlesReport";
import ArticleTypeController from "./Controllers/ArticleTypeController";
import VideoTypeController from "./Controllers/VideoTypeController";
import PriorityTypeController from "./Controllers/PriorityTypeController";
import ClosingController from "./Controllers/ClosingController";
import AgencyListController from "./Controllers/AgencyListController";
import ResourceListController from "./Controllers/ResourceListController";
import UserListController from "./Controllers/UserListController";
import ContestListController from "./Controllers/ContestListController";
import GamesListController from "./Controllers/GamesController";
import GameStatusController from "./Controllers/GameStatusController";
import SchoolsListController from "./Controllers/SchoolController";
import TeamsListController from "./Controllers/TeamController";
import SportsListController from "./Controllers/SportController";
import SeasonController from "./Controllers/SeasonController.js";
import PartyController from "./Controllers/PartyController.js";
import ElectionController from "./Controllers/ElectionController.js";
import SnowEmergencyController from "./Controllers/SnowEmergencyController";
import IssuesController from "./Controllers/IssuesController.js";
import RaceController from "./Controllers/RaceController.js";
import ElectionCountyController from "./Controllers/ElectionCountyController";
import PromotionalRestaurantController from "./Controllers/PromotionalRestaurantController";


export default class App extends Component {

  state = {
    componentIsLoading: true,
    isAuthenticated: false,
    hasCheckedAuthentication: false,
    user: {
      agencies: [],
      articleTypes: [],
      email: '',
      id: 0,
      isActive: false,
      name:'',
      roles: [],
    },
    publicSitePreferences: {
      stormMode: false,
    },
    isMobile: Constants.isMobile(),
    isNavMenuVisible: false,
  }

  componentDidMount () {
    this.getMe();
    this.fetchPublicSitePreferences();
    APIService.unAuthenticate = this.unAuthenticate;
    // forces sub components to render on resize
    window.addEventListener('resize', () => {
      if ( this.state.isMobile !== Constants.isMobile() ) {
        this.setState({ isMobile: Constants.isMobile() });
      }
    });
  }

  onSuccessfullLogin = () => {
    this.fetchPublicSitePreferences();
    this.getMe();
  }

  getMe = () => {
    fetch(APIService.serverUrl + '/api/private/me', {
      headers: APIService.authHeaders(),
      method: 'GET',
    }).then( (response) => {
      if (response.ok) {
        return response.json();
      } else {
        this.setState({
          isAuthenticated: false,
          hasCheckedAuthentication: true,
        })
        console.log("getMe Failed: ", response.status, response.statusText);
        // window.location.replace('/');
      }
    }).then( (json) => {
      if (json) {
        this.setState({
          user: {...json},
          isAuthenticated: true,
          hasCheckedAuthentication: true,
          componentIsLoading: false,
        })
      } else {
        console.log('Could not get JSON')
        // this.props.history.push('/');
      }
    })
  }

  unAuthenticate = () => {
    this.setState({ isAuthenticated: false });
  }

  fetchPublicSitePreferences = () => {
    fetch(APIService.serverUrl + "/api/private/publicSitePreferences", {
      method: 'GET',
      headers: APIService.authHeaders(),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({publicSitePreferences: json});
        // console.log('HeaderMenu fetchPublicSitePreferences Succedded');
      } else {
        console.log("fetchPublicSitePreferences Failed");
      }
    })
  };

  onStormModeChange = () => {
    fetch(APIService.serverUrl + "/api/private/publicSitePreferences", {
      method: 'PATCH',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        ...this.state.publicSitePreferences,
        stormMode: !this.state.publicSitePreferences.stormMode,
      }),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({publicSitePreferences: json});
        // console.log("onStormModeChange Executed");
      } else {
        console.log("patchPublicSitePrefences Failed")
      }
    })
  };

  openNavMenu = () => {
    document.getElementsByTagName("body")[0].style = "overflow: hidden;"
    this.setState({isNavMenuVisible: true})
  }

  closeNavMenu = () => {
    document.getElementsByTagName("body")[0].style = ""
    this.setState({isNavMenuVisible: false})
  }

  selectLayout = () => {
    let userRoles = this.state.user.roles

    if (!this.state.hasCheckedAuthentication) {
      return (
        <Dimmer active={this.state.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
      )
    } else if (this.state.isAuthenticated) {
      return (
        <div  className='app'>
          <Dimmer active={this.state.componentIsLoading} >
            <Loader size='massive'>Loading</Loader>
          </Dimmer>
          <SideBarNavigation
              isNavMenuVisible={this.state.isNavMenuVisible}
              openNavMenu={this.openNavMenu}
              closeNavMenu={this.closeNavMenu}
              user={this.state.user}
          >

            <HeaderMenu
                openNavMenu={this.openNavMenu}
                user={this.state.user}
                stormMode={this.state.publicSitePreferences.stormMode}
            />
            <div className="appBody">
              <Switch>
                /*
              change URLs to 'articles' not 'article-list'
              add doesEntityExist() to all add/edit componets that need it
              disable send text on edit closing id no textCasterId set
              Set Button State on Add and Edit Article Components Required Fields
              UN-NEST state Items

              XX Image Changes from New API

              XX Contest API additions

              Contest Date change.

          */
                <Route
                    path="/"
                    exact
                    render={ () => {
                      if (userRoles.filter(role => role.name === "admin" || role.name === "publisher" || role.name === "approver").length) {
                        return <Redirect to='/articles' />
                      } else if (userRoles.filter(role => role.name === "closer" || role.name === "closing-admin").length) {
                        return <Redirect to='/closings'/>
                      } else if (userRoles.filter(role => role.name === "score-reporter").length) {
                        return <Redirect to='/games'/>
                      } else if (userRoles.filter(role => role.name === "snow-reporter").length) {
                        return <Redirect to='/snow-emergency'/>
                      } else if (userRoles.filter(role => role.name === "election-reporter").length) {
                        return <Redirect to='/elections'/>
                      }
                      return <Redirect to='/articles' />
                    }}
                />
                <Route
                    path="/articles"
                    render={props =>
                        <ArticleListController
                            {...props}
                            user={this.state.user}
                        />
                    }
                />
                <Route
                    path="/add-image/:entityPath(article|contest|school)/:entityId/:noAspect?"
                    exact
                    component={Cropper}
                />
                <Route path="/edit-image/:articleImageId" exact component={Cropper} />
                <Route path="/article-reports" exact component={ViewArticlesReport} />
                <Route path="/article-types" component={ArticleTypeController} />
                <Route path="/video-types" component={VideoTypeController} />
                <Route path="/priority-types" component={PriorityTypeController} />
                <Route path="/closings" component={ClosingController} />
                <Route path="/agencies" component={AgencyListController} />
                <Route path="/resources" component={ResourceListController} />
                <Route path="/users" component={UserListController} />
                <Route path="/contests" component={ContestListController} />
                <Route path="/games" component={GamesListController}/>
                <Route path="/game-statuses" component={GameStatusController}/>
                <Route path="/schools" component={SchoolsListController}/>
                <Route path="/teams" component={TeamsListController} />
                <Route path="/sports" component={SportsListController}/>
                <Route path="/seasons" component={SeasonController}/>
                <Route path='/parties' component={PartyController}/>
                <Route path='/election-counties' component={ElectionCountyController}/>
                <Route path='/elections' component={ElectionController}/>
                <Route path='/issues' component={IssuesController}/>
                <Route path='/races' component={RaceController}/>
                <Route path='/promotional-restaurants' component={PromotionalRestaurantController}/>

                <Route
                    path="/snow-emergency"
                    render={ props =>
                        <SnowEmergencyController
                            {...props}
                            stormMode={this.state.publicSitePreferences.stormMode}
                            onStormModeChange={this.onStormModeChange}
                        />
                    }
                />
              </Switch>
            </div>
          </SideBarNavigation>
        </div>
      )
    } else {
      return (
        <Container className='login-page'>
          <Login onSuccessfullLogin={this.onSuccessfullLogin} />
        </Container>
      )
    }
  }


  render() {

    return (
      <Router>
        { this.selectLayout() }
      </Router>
    );
  }

}

/*



*/
