import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import ViewSportList from "../Views/ViewSportList";
import EditSport from "../Editors/EditSport";
import APIService from "../API";


class SportController extends Component {

    state = {
        sports: [],
        isFetchingSports: false,
    };

    componentDidMount() {
        this.getSportList();
    };

    getSportList = () => {
        this.setState( {isFetchingSports: true});
        fetch( APIService.serverUrl + "/api/private/sports", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState({sports: json})
                }
                this.setState({isFetchingSports: false})
            })
    };


    render = () => {

        let relativePath = this.props.match.path;

        return (
            <div>
                <Switch>
                    <Route
                        path={relativePath}
                        exact
                        render={() =>
                            <ViewSportList
                                {...this.state}
                                refreshList={this.getSportList}
                                sports={this.state.sports}
                            />}
                    />
                    <Route
                        path={relativePath + "/add"}
                        render={() =>
                            <EditSport
                                refreshList={this.getSportList}
                            />}
                    />
                    <Route
                        path={relativePath + "/edit/:sportId"}
                        render={() =>
                            <EditSport
                                refreshList={this.getSportList}
                            />}
                    />
                </Switch>

            </div>
        )
    };
};

export default withRouter(SportController);