import React, { Component } from 'react';
import {
  Table,
  Button,
  Dimmer,
  Loader,
  Label,
  Icon,
} from 'semantic-ui-react';
import { withRouter } from "react-router";


class UserList extends Component {

  startNewUser = () => {
    this.props.history.push('/users/add');
  }

  startEditUser = (user) => {
    this.props.history.push('/users/edit/'+user.id);
  };

  render() {

    return (

      <div>
        <Dimmer active={this.props.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <Table celled striped color="blue" selectable compact='very' size='small' >
          <Table.Header fullWidth >
            <Table.Row >
              <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='12' >
                <span style={{ fontSize: "18px" }}>Click The Rows Below To Edit Users.</span>
                <Button color="blue" onClick={this.startNewUser} floated='right' size='mini' >New User</Button>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign='center' collapsing>
                <strong>Active</strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center' collapsing>
                <strong>ID</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Name</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Email</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Roles</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.userList.map( (user) => {
              return(
                <Table.Row
                  key={user.id.toString()}
                  id={user.id.toString()}
                  style={{cursor:'pointer'}}
                  onClick={ (e) => {this.startEditUser(user)} }
                >
                  <Table.Cell name='isActive' textAlign="center" collapsing>
                    <Icon name={user.isActive ? 'check circle' : 'circle outline'} color="blue" />
                  </Table.Cell>
                  <Table.Cell name='id' textAlign="center" collapsing>
                    <Label circular size='tiny' content={user.id} />
                  </Table.Cell>
                  <Table.Cell name='name' >
                    <Icon name='user' color='grey' />{user.name}
                  </Table.Cell>
                  <Table.Cell name='email' >
                    <Icon name='mail' color='grey' />{user.email}
                  </Table.Cell>
                  <Table.Cell>
                    {user.roles.map((role, index) => (index ? ', ':'') + role.name)}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>

    );
  }
}

export default withRouter(UserList)

/*



*/
