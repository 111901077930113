import React, { Component } from 'react';
import {
  Icon,
  Button,
  Form,
  Input,
  Header,
  Select,
  Label,
} from 'semantic-ui-react'
import { withRouter } from "react-router";
import TextAlertList from "../Views/TextAlertList";
import APIService from '../API.js';
import ResponsiveModal from '../ResponsiveModal.js';

class EditSnowEmergency extends Component {
  static initialEditCountyState = {
    id: 0,
    name: '',
    textCasterId: undefined,
    snowEmergencyModeId: undefined,
  }

  state = {
    snowEmergencyCounties: [],
    snowEmergencyModes: [],

    editCounty: EditSnowEmergency.initialEditCountyState,
    unmodifiedCounty: {},

    snowEmergencyTexts: [],
    snowEmergencyCounty: undefined,

    componentIsLoading: true,
  };

  doesCountyExist = () => this.props.match.params.countyId !== undefined;

  componentDidMount() {
    this.fetchSnowEmergencyCounty();
    this.fetchSnowEmergencyModes();
  }

  fetchSnowEmergencyCounty = () => {
    if (this.props.match.params.countyId !== undefined) {
      fetch(APIService.serverUrl + '/api/private/snowEmergencyCounty/'+this.props.match.params.countyId, {
        method: "GET",
        headers: APIService.authHeaders(),
      }).then(response => response.ok ? response.json() : null).then((json) => {
        if (json) {
          // console.log("Fetch Snow Emergency Counties Succeeded")
          // console.log(json)
          this.setState({
            editCounty: {
              id: json.id,
              name: json.name,
              textCasterId: json.textCasterId,
              snowEmergencyModeId: json.snowEmergencyMode.id,
            },
            unmodifiedCounty:  {
              id: json.id,
              name: json.name,
              textCasterId: json.textCasterId,
              snowEmergencyModeId: json.snowEmergencyMode.id,
            },
            snowEmergencyTexts: json.snowEmergencyTexts,
            snowEmergencyCounty: json,
            componentIsLoading: false,
          })
        } else {
          console.log("Fetch Snow Emergency Counties Failed")
        }
      })
    }
  };

  fetchSnowEmergencyModes = () => {
    fetch(APIService.serverUrl + '/api/private/snowEmergencyMode', {
      method: "GET",
      headers: APIService.authHeaders(),
    }).then(response => response.ok ? response.json() : null).then((json) => {
      if (json) {
        // console.log("Fetch Snow Emergency Modes Succedded")
        // console.log(json)
        this.setState({snowEmergencyModes: json})
      } else {
        console.log("fetchSnowEmergencyModes Failed")
      }
    })
  };

  patchSnowEmergencyCounty = () => {
    this.setState({ 'componentIsLoading': true});
    let fetchPath = this.state.editCounty.id ?
      '/api/private/snowEmergencyCounty/'+this.state.editCounty.id :
      '/api/private/snowEmergencyCounty'
    fetch(APIService.serverUrl + fetchPath, {
      method: this.state.editCounty.id ? 'PATCH' : 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify(this.state.editCounty)
    }).then(response => response.ok ? response.json() : null).then((json) => {
      if (json) {
        this.props.refreshList();
        if (this.doesCountyExist()) {
          this.fetchSnowEmergencyCounty();
        } else {
          this.setState({
            editCounty: EditSnowEmergency.initialEditCountyState,
          });
          this.props.history.push('/snow-emergency/edit/'+json.id);
          this.fetchSnowEmergencyCounty();
        }
      } else {
        console.log("patchSnowEmergencyCounty Failed");
      }
    })
  };

  setEditCountyState = (object) => {
    let modifyCounty = {
      ...this.state.editCounty,
      ...object
    };
    this.setState({ editCounty: modifyCounty });
  };

  onEditInputChange = (e) => {
    this.setEditCountyState({ [e.target.name]: e.target.value, });
  };

  onEditSelectChange = (e, { name, value }) => {
    this.setEditCountyState({ [name]: value });
  };

  isFormNotModified = () => {
    for (var key in this.state.editCounty) {
      if (this.state.unmodifiedCounty[key] !== this.state.editCounty[key]) {
        return false;
      }
    }
    return true;
  }


  render() {

    let requiredFieldsComplete = (
      this.state.editCounty.name.length !== 0 //&&
      // this.state.editCounty.textCasterId.length !== 0
    );

    let hasCountyInitiallyLoaded = this.state.editCounty.id !== 0;
    let isFormModified = !this.isFormNotModified();
    let hasTextcasterId = this.state.editCounty.textCasterId !== '' && this.state.editCounty.textCasterId !== undefined;


    return (

      <ResponsiveModal modalSize='tiny' >
        <Form>
          <Header
            dividing
            color='blue'
            icon={ this.state.editCounty.id ? 'edit outline' : 'add' }
            content={ this.state.editCounty.id ? 'Edit County' : 'Add County' } />
          <Form.Field>
            <label>
              Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
            </label>
            <Input
              id='name'
              name='name'
              placeholder='County Name...'
              value={this.state.editCounty.name}
              onChange={this.onEditInputChange} />
          </Form.Field>
          <Form.Field>
            <label>
              Text Caster Id:&nbsp;
            </label>
            <Input
              id='textCasterId'
              name='textCasterId'
              placeholder='Text Caster ID...'
              value={
                this.state.editCounty.textCasterId !== undefined ?
                this.state.editCounty.textCasterId : '' }
              onChange={this.onEditInputChange} />
          </Form.Field>
          { (!this.doesCountyExist()) ? null :
            <Form.Field>
              <label>Mode: </label>
              <Select
                id="snowEmergencyModeId"
                name='snowEmergencyModeId'
                options={this.state.snowEmergencyModes.map(
                  mode => ({key: mode.id, text: mode.name, value: mode.id})
                )}
                placeholder='Choose the Snow Emergency Mode...'
                value={this.state.editCounty.snowEmergencyModeId}
                onChange={this.onEditSelectChange} />
            </Form.Field>
          }
          { (!this.doesCountyExist() || !hasCountyInitiallyLoaded) ? null :
            <TextAlertList
              snowEmergencyCounty={this.state.snowEmergencyCounty}
              textAlerts={this.state.snowEmergencyTexts}
              disableSend={isFormModified || !hasTextcasterId}
              onSuccess={this.fetchSnowEmergencyCounty}
            />
          }

          <div>&nbsp;</div>
          <Form.Field>
            <Label size='tiny' >
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
              Denotes required field
            </Label>
          </Form.Field>
          <Form.Field>
            <Button
              size='mini'
              color={ isFormModified ? 'red' : undefined }
              onClick={ () => this.props.history.push('/snow-emergency') } >
              <Icon name='cancel' /> { isFormModified ? 'Cancel' : 'Close' }
            </Button>
            <Button
              size='mini'
              color='green'
              disabled={!requiredFieldsComplete || !isFormModified}
              onClick={this.patchSnowEmergencyCounty} >
              <Icon name='save outline' /> Save
            </Button>
          </Form.Field>
        </Form>
      </ResponsiveModal>

    );
  }

}

export default withRouter(EditSnowEmergency);

/*



*/
