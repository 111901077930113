import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import {withRouter} from "react-router";
import ViewElectionList from "../Views/ViewElectionList";
import EditElection from "../Editors/EditElection";
import APIService from "../API.js";
import ViewElection from "../Views/ViewElection";


class ElectionController extends Component {

  state = {
    elections: [],
    isFetchingElections: false,
  };

  componentDidMount() {
    this.getElectionList();
  };

  getElectionList = () => {
    this.setState( {isFetchingElections: true})
    fetch(APIService.serverUrl + "/api/private/elections", {
        method: "POST",
        headers: APIService.authHeaders(),
        body: JSON.stringify({}),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return null;
            }
        })
        .then((json) => {
            if (json) {
                this.setState( {elections: json})
            }
            this.setState({isFetchingElections: false})
        })
  };


  render = () => {

      let relativePath = this.props.match.path;

      return (
          <div>
              <Switch>
                  <Route
                      path={relativePath}
                      exact
                      render={() =>
                          <ViewElectionList
                              {...this.state}
                              refreshList={this.getElectionList}
                              elections={this.state.elections}
                          />}
                  />
                  <Route
                      path={relativePath + "/add"}
                      render={() =>
                          <EditElection
                              refreshList={this.getElectionList}
                          />
                      }
                  />
                  <Route
                    path={relativePath + "/view/:electionId"}
                    render={() =>
                      <ViewElection/>
                    }
                  />
                  <Route
                      path={relativePath + "/edit/:electionId"}
                      render={() =>
                          <EditElection
                              refreshList={this.getElectionList}
                          />}
                  />
              </Switch>

          </div>
      )
  };
};

export default withRouter(ElectionController);
