import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import {withRouter} from "react-router";
import ViewPartyList from "../Views/ViewPartyList";
import EditParty from "../Editors/EditParty";
import APIService from "../API.js";


class PartyController extends Component {

  state = {
    parties: [],
    isFetchingParties: false,
  };

  componentDidMount() {
    this.getPartyList();
  };

  getPartyList = () => {
    this.setState( {isFetchingParties: true})
    fetch(APIService.serverUrl + "/api/private/parties", {
        method: "POST",
        headers: APIService.authHeaders(),
        body: JSON.stringify({}),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return null;
            }
        })
        .then((json) => {
            if (json) {
                this.setState( {parties: json})
            }
            this.setState({isFetchingParties: false})
        })
  };


  render = () => {

      let relativePath = this.props.match.path;

      return (
          <div>
              <Switch>
                  <Route
                      path={relativePath}
                      exact
                      render={() =>
                          <ViewPartyList
                              {...this.state}
                              refreshList={this.getPartyList}
                              parties={this.state.parties}
                          />}
                  />
                  <Route
                      path={relativePath + "/add"}
                      render={() =>
                          <EditParty
                              refreshList={this.getPartyList}

                          />}
                  />
                  <Route
                      path={relativePath + "/edit/:partyId"}
                      render={() =>
                          <EditParty
                              refreshList={this.getPartyList}
                          />}
                  />
              </Switch>

          </div>
      )
  };
};

export default withRouter(PartyController);
