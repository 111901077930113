import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import EditGame from '../Editors/EditGame.js';
import APIService from "../API";
import ViewGameList from "../Views/ViewGameList";
import moment from 'moment';




class GamesController extends Component {

    state = {
        games: [],
        isFetchingGames: false,
        isFetchingTVDate: false,
        sportsTVDate: new moment,
        isTVDateModalOpen: false,
    };

    componentDidMount() {
        this.getGameList();
        this.getSportsTVDate();
    };

    getGameList = () => {
        this.setState({isFetchingGames: true});
        fetch( APIService.serverUrl + "/api/private/games", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify( {}),
        })
            .then( (response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then( (json) => {
                if(json) {
                    this.setState( {games: json})
                }
                this.setState( {isFetchingGames: false})
            })
    };

    getSportsTVDate = () => {
      this.setState({isFetchingTVDate: true});
      fetch( APIService.serverUrl + "/api/private/publicSitePreferences", {
          method: "GET",
          headers: APIService.authHeaders(),
      })
          .then( (response) => {
              if(response.ok) {
                  return response.json();
              } else {
                  return null;
              }
          })
          .then( (json) => {
              if(json) {
                  this.setState({
                    sportsTVDate: moment(json.sportsTVDate)
                  })
              }
              this.setState( {isFetchingTVDate: false})
          })
    };

    updateSportsTVDate = () => {
      fetch(APIService.serverUrl + "/api/private/publicSitePreferences", {
          method: "PATCH",
          headers: APIService.authHeaders(),
          body: JSON.stringify({
              sportsTVDate: moment(this.state.sportsTVDate),
          })
      })
      .then((response) => {
          if (response.ok) {
              return response.json();
          } else {
              return null;
          }
      })
      .then((json) => {
          if (json) {
              this.closeTVDateModal();
          }
      })

    }

    closeTVDateModal = () => {
      this.setState({ isTVDateModalOpen: false})
    };

    openTVDateModal = () => {
      this.setState({ isTVDateModalOpen: true});
    };

    onSelectTVDate = (date) => {
        this.setState( {sportsTVDate: moment(date)})
    }

    render = () => {

        let relativePath = this.props.match.path;

        return (
              <Switch>
                  <Route
                      path={relativePath}
                      exact
                      render={() =>
                          <ViewGameList
                              {...this.state}
                              refreshList={this.getGameList}
                              games={this.state.games}
                              isModalOpen={this.state.isTVDateModalOpen}
                              closeTVDateModal={this.closeTVDateModal}
                              openTVDateModal={this.openTVDateModal}
                              sportsTVDate={this.state.sportsTVDate}
                              onSelectTVDate={this.onSelectTVDate}
                              updateSportsTVDate={this.updateSportsTVDate}
                              isFetchingTVDate={this.state.isFetchingTVDate}
                          />}
                  />
                  <Route
                      path={relativePath + "/add"}
                      render={() =>
                          <EditGame
                              refreshList={this.getGameList}
                          />}
                  />
                  <Route
                      path={relativePath + "/edit/:gameId"}
                      render={() =>
                          <EditGame
                              refreshList={this.getGameList}
                          />}
                  />
              </Switch>
        )
    };
};

export default withRouter(GamesController);
