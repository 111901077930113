import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input, Grid, Image, Checkbox, Dropdown, Label, Modal} from "semantic-ui-react";
import APIService from "../API";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';
import TextAlertList from "../Views/TextAlertList";
import Helpers from "../Helpers";


class EditGame extends Component {
    state = {
        game: null,
        hasCustomTitle: false,
        eventStartAt: new moment,

        gameStatusId: null,
        gameStatusList: [],

        seasonList:[],
        seasonId: null,

        schoolList: [],

        primarySchoolName: null,
        primarySchoolId: null,
        primarySchoolScore: null,
        hasOtherPrimarySchool: false,
        otherPrimarySchoolName: null,

        secondarySchoolName: null,
        secondarySchoolId: null,
        secondarySchoolScore: null,
        hasOtherSecondarySchool: false,
        otherSecondarySchoolName: null,

        textAlerts: [],

        isReadingGame: false,
        isUpdatingGame: false,
        isFetchingSchools: false,
        isFetchingSports: false,
        isFetchingGameStatuses: false,
        isFetchingSeasons: false,
        isDeleteModalOpen: false,
    };


    componentDidMount() {
        if(this.isEdit()) {
            this.readGame();
        }
        this.getSchoolList();
        // this.getSportList();
        this.getSeasonList();
    };

    isEdit = () => this.props.match.params.gameId !== undefined;

    gameId = () => this.props.match.params.gameId;

    updateGame = () => {
        this.setState({isUpdatingGame: true});
        fetch(APIService.serverUrl + "/api/private/game" + (this.isEdit() ? "/" + this.gameId() : ""), {
            method: this.isEdit() ? "PATCH" : "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({
                name: this.state.gameName,
                hasCustomName: this.state.hasCustomTitle,
                primarySchoolScore: this.state.primarySchoolScore,
                secondarySchoolScore: this.state.secondarySchoolScore,
                hasOtherPrimarySchool: this.state.hasOtherPrimarySchool,
                hasOtherSecondarySchool: this.state.hasOtherSecondarySchool,
                primarySchoolId: this.state.primarySchoolId,
                secondarySchoolId: this.state.secondarySchoolId,
                otherPrimarySchoolName: this.state.otherPrimarySchoolName,
                otherSecondarySchoolName: this.state.otherSecondarySchoolName,
                eventStartAt: moment(this.state.eventStartAt),
                sportId: this.state.sportId,
                gameStatusId: this.state.gameStatusId,
                seasonId: this.state.seasonId,
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
              if (json) {
                this.props.refreshList();
                if (this.isEdit()) {
                  this.readGame();
                } else {
                  this.props.history.push('/games/edit/' + json.id);
                  this.readGame();
                }
              } else {
                console.log('patchGame PATCH Failed')
              }
              this.setState({isUpdatingGame: false});
            })
    };

    readGame = () => {
        this.setState({isReadingGame: true});
        fetch( APIService.serverUrl + "/api/private/game/" + this.gameId(), {
            method: "GET",
            headers: APIService.authHeaders(),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState( { isReadingGame: false});
                if (json) {
                    this.setState( {
                        game: json,
                        gameName: json.name,
                        hasCustomTitle: json.hasCustomName,
                        gameStatusId: json.gameStatus.id,
                        primarySchoolId: (json.primarySchool ? json.primarySchool.id : null),
                        primarySchoolName: (json.primarySchool ? json.primarySchool.name : null),
                        hasOtherPrimarySchool: json.hasOtherPrimarySchool,
                        otherPrimarySchoolName: (json.hasOtherPrimarySchool ? json.otherPrimarySchoolName : null),
                        primarySchoolScore: json.primarySchoolScore,
                        secondarySchoolId: (json.secondarySchool ? json.secondarySchool.id : null),
                        secondarySchoolName: (json.secondarySchool ? json.secondarySchool.name : null),
                        hasOtherSecondarySchool: json.hasOtherSecondarySchool,
                        otherSecondarySchoolName: (json.hasOtherSecondarySchool ? json.otherSecondarySchoolName : null),
                        secondarySchoolScore: json.secondarySchoolScore,
                        eventStartAt: moment(json.eventStartAt),
                        seasonId: json.season.id,
                        textAlerts: json.textAlerts,
                    })
                    this.getGameStatusList(json.season.sportId);
                }
            })
    };

    deleteGame = () => {
      fetch(APIService.serverUrl + "/api/private/game/" + this.gameId(), {
        method: "DELETE",
        headers: APIService.authHeaders(),
        body: JSON.stringify({}),
      })
        .then((response) => {
          if(response.ok) {
            this.closeDeleteModal();
            alert("This Game has been deleted.")
            this.props.refreshList();
            this.props.history.push("/games");
          }
        })
    };

    getSchoolList = () => {
        this.setState( {isFetchingSchools: true});
        fetch( APIService.serverUrl + "/api/private/schools", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState({schoolList: json})
                }
                this.setState({isFetchingSchools: false})
            })
    };

    getGameStatusList = (sportId) => {
        this.setState( {isFetchingGameStatuses: true});
        fetch( APIService.serverUrl + "/api/private/game-statuses", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({
                sportId: sportId,
            }),
        })
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState({gameStatusList: json})
                }
                this.setState({isFetchingGameStatuses: false})
            })
    }

    getSeasonList = () => {
        this.setState({isFetchingSeasons: true});
        fetch(APIService.serverUrl + "/api/private/seasons", {
          method: "POST",
          headers: APIService.authHeaders(),
          body: JSON.stringify({}),
        })
          .then( (response) => {
            if(response.ok) {
              return response.json();
            } else {
              return null;
            }
          })
          .then( (json) => {
            if(json) {
              this.setState( {seasonList: json})
            }
            this.setState( {isFetchingSeasons: false})
          })
    };

    onSeasonChange = (event, data) => {
      this.setState({
          seasonId: data.value,
      });
      let sportId = null;
      this.state.seasonList.forEach((season) => {
        if(season.id === data.value) {
          sportId = season.sport.id
        }
      });
      this.getGameStatusList(sportId);
    };

    onEventStartAt = (date) => {
        this.setState( {eventStartAt: moment(date)})
    }

    openDeleteModal = () => {
      this.setState({isDeleteModalOpen: true})
    };

    closeDeleteModal = () => {
      this.setState({isDeleteModalOpen: false})
    };

    render = () => {

        return (
            <Form loading={this.state.isReadingGame}>
                <Header
                    dividing
                    icon='add'
                    color='blue'
                    content={this.isEdit() ? 'Edit Game' : 'Add Game'}
                />

                {/*Custom Selector and Title Field*/}
                <Form.Group>
                    <Form.Field width='4'>
                        <label>Custom Game Title: </label>
                        <Checkbox
                            toggle
                            id='hasCustomTitle'
                            name='hasCustomTitle'
                            checked={this.state.hasCustomTitle}
                            onChange={() => {
                                this.setState({ "hasCustomTitle": !this.state.hasCustomTitle })
                            }}
                        />
                    </Form.Field>
                    <Form.Field  width='12'>
                        <label>Game Title:</label>
                        <Input
                            id='gameTitle'
                            name='gameTitle'
                            disabled={this.state.isUpdatingGame || !this.state.hasCustomTitle}
                            value={
                              (this.state.hasCustomTitle ?  this.state.gameName :
                                Helpers.generateGameName(
                                  this.state.primarySchoolName,
                                  this.state.secondarySchoolName,
                                  this.state.otherPrimarySchoolName,
                                  this.state.otherSecondarySchoolName
                                )
                              )
                            }
                            onChange={(event) => {
                                this.setState( {gameName: event.target.value})
                            }}
                        />
                    </Form.Field>
                </Form.Group>

                {/*Season and Date Picker*/}
                <Form.Group>
                  <Form.Field width='10'>
                    <label>Season:&nbsp;
                        <Icon
                          name='asterisk'
                          size='small'
                          color='blue'
                        />
                    </label>
                    <Dropdown
                        fluid
                        search
                        selection
                        placeholder='Select Season'
                        value={this.state.seasonId}
                        options={this.state.seasonList.map( (season) => ({
                            key: season.id,
                            text: season.name,
                            value: season.id,
                        }))}
                        onChange={this.onSeasonChange}
                    />
                  </Form.Field>
                  <Form.Field width='6'>
                    <label>Date Played:&nbsp;
                        <Icon
                          name='asterisk'
                          size='small'
                          color='blue'
                        />
                    </label>
                    <DatePicker
                        name='eventStartAt'
                        dateFormat='MM/dd/yyyy'
                        selected={this.state.eventStartAt.toDate()}
                        onChange={this.onEventStartAt}
                    />
                  </Form.Field>
                </Form.Group>

                {/*Away Team*/}
                <Form.Group >
                    <Form.Field width='3'>
                        <label>Other School: </label>
                        <Checkbox
                            toggle
                            id='hasOtherSecondarySchool'
                            name='hasOtherSecondarySchool'
                            checked={this.state.hasOtherSecondarySchool}
                            onChange={() => {
                                this.setState({ "hasOtherSecondarySchool": !this.state.hasOtherSecondarySchool })
                            }}
                        />
                    </Form.Field>

                    <Form.Field
                      width='10'
                    >
                      <label>Away Team:&nbsp;
                          <Icon
                            name='asterisk'
                            size='small'
                            color='blue'
                          />
                      </label>
                      {this.state.hasOtherSecondarySchool === false ?
                          <Dropdown
                              fluid
                              search
                              selection
                              placeholder='Select Team'
                              value={this.state.secondarySchoolId}
                              options={this.state.schoolList.map( (team) => ({
                                  key: team.id,
                                  text: team.name,
                                  value: team.id,
                              }))}
                              onChange={(event, data) => {
                                  this.setState({
                                      secondarySchoolId: data.value,
                                      secondarySchoolName: event.target.innerText,
                                  });
                              }}
                          />
                        :
                          <Input
                              id='hasOtherSecondarySchool'
                              name='hasOtherSecondarySchool'
                              value={
                                (this.state.hasOtherSecondarySchool ?  this.state.otherSecondarySchoolName : null)
                              }
                              onChange={(event) => {
                                  this.setState( {otherSecondarySchoolName: event.target.value})
                                  console.log(event.target.value)
                              }}
                          />
                      }
                    </Form.Field>
                    <Form.Field
                      width='3'
                    >
                        <label>Away Team Score:</label>
                        <Input
                            id='secondaryTeamScore'
                            name='secondaryTeamScore'
                            disabled={this.state.isUpdatingGame}
                            type='number'
                            placeholder='-'
                            value={this.state.secondarySchoolScore}
                            onChange={(event) => {
                                this.setState( {secondarySchoolScore: Number(event.target.value)})
                            }}
                        />
                    </Form.Field>
                </Form.Group>

                {/*Home Team*/}
                <Form.Group >
                    <Form.Field width='3'>
                        <label>Other School: </label>
                        <Checkbox
                            toggle
                            id='hasOtherPrimarySchool'
                            name='hasOtherPrimarySchool'
                            checked={this.state.hasOtherPrimarySchool}
                            onChange={() => {
                                this.setState({ "hasOtherPrimarySchool": !this.state.hasOtherPrimarySchool })
                            }}
                        />
                    </Form.Field>

                    <Form.Field
                      width='10'
                    >
                        <label>Home Team:&nbsp;
                            <Icon
                              name='asterisk'
                              size='small'
                              color='blue'
                            />
                        </label>
                      {this.state.hasOtherPrimarySchool === false ?
                          <Dropdown
                              fluid
                              search
                              selection
                              placeholder='Select Team'
                              value={this.state.primarySchoolId}
                              options={this.state.schoolList.map( (team) => ({
                                  key: team.id,
                                  text: team.name,
                                  value: team.id,
                              }))}
                              onChange={(event, data) => {
                                  this.setState({
                                      primarySchoolId: data.value,
                                      primarySchoolName: event.target.innerText,
                                  });
                              }}
                          />
                        :
                          <Input
                              id='otherPrimarySchoolName'
                              name='otherPrimarySchoolName'
                              value={(this.state.hasOtherPrimarySchool ?  this.state.otherPrimarySchoolName : null)}
                              onChange={(event) => {
                                  this.setState( {otherPrimarySchoolName: event.target.value})
                              }}
                          />
                      }
                    </Form.Field>
                    <Form.Field
                      width='3'
                    >
                        <label>Home Team Score:</label>
                        <Input
                            id='primarySchoolScore'
                            name='primarySchoolScore'
                            disabled={this.state.isUpdatingGame}
                            type='number'
                            placeholder='-'
                            value={this.state.primarySchoolScore}
                            onChange={(event) => {
                                this.setState( {primarySchoolScore: Number(event.target.value)})
                            }}
                        />
                    </Form.Field>
                </Form.Group>

                {/*Game Status*/}
                <Form.Field width='8'>
                    <label>Game Status:&nbsp;
                        <Icon
                          name='asterisk'
                          size='small'
                          color='blue'
                        />
                    </label>
                    <Dropdown
                        fluid
                        selection
                        disabled={this.state.seasonId === null}
                        value={this.state.gameStatusId}
                        options={this.state.gameStatusList.map( (status) => ({
                            key: status.id,
                            text: status.name,
                            value: status.id,
                        }))}
                        onChange={(event, data) => {
                            this.setState({
                                gameStatusId: data.value,
                            });
                        }}
                    />
                </Form.Field>

                {/*Required Field*/}
                <Form.Field style={{marginBottom: '20px'}}>
                  <Label size='tiny' >
                    <Icon
                      name='asterisk'
                      size='small'
                      color='blue' />
                    Denotes required field
                  </Label>
                </Form.Field>

                <Form.Field>
                  <Button
                    size='mini'
                    style={{marginRight: '10px'}}
                    onClick={() => {this.props.history.push("/games")}}
                    loading={this.state.isUpdatingGame}
                    disabled={this.state.isUpdatingGame || this.state.isReadingGame}
                  >
                    <Icon name='left arrow'/>
                    Cancel
                  </Button>
                  {!this.isEdit() ? null :
                    <Button
                      size='mini'
                      color='red'
                      style={{marginRight: '10px'}}
                      onClick={this.openDeleteModal}
                      loading={this.state.isUpdatingGame}
                      disabled={this.state.isUpdatingGame || this.state.isReadingGame}
                    >
                      <Icon name='delete'/>
                      Delete
                    </Button>
                  }
                  <Button
                      loading={this.state.isUpdatingGame}
                      disabled={this.state.isUpdatingGame || this.state.isReadingGame}
                      size='mini'
                      color='green'
                      onClick={this.updateGame}
                  >
                      <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                      {this.isEdit() ? 'Save Game' : 'Add Game'}
                  </Button>
                </Form.Field>

                {!this.isEdit() || this.state.isReadingGame ? null :
                  <TextAlertList
                    textAlerts={this.state.textAlerts}
                    onSuccess={this.readGame}
                    game={this.state.game}
                  />
                }
                <Modal size='small' open={this.state.isDeleteModalOpen}>
                  <Modal.Header>
                    Are you sure you want to delete&nbsp;
                    <font color='blue'>
                      {this.state.hasCustomTitle ?  this.state.gameName :
                        Helpers.generateGameName(
                          this.state.primarySchoolName,
                          this.state.secondarySchoolName,
                          this.state.otherPrimarySchoolName,
                          this.state.otherSecondarySchoolName
                        )
                      }
                    </font>?
                  </Modal.Header>
                  <Modal.Content>
                    <Form.Field>
                      <Button
                        style={{marginRight: '10px'}}
                        onClick={this.closeDeleteModal}
                      >
                        <Icon name='left arrow'/>
                        No
                      </Button>
                      <Button
                        color='red'
                        onClick={this.deleteGame}
                      >
                        <Icon name='delete'/>
                        Delete
                      </Button>
                    </Form.Field>

                  </Modal.Content>
                </Modal>
            </Form>
        )
    };

};

export default withRouter(EditGame);
