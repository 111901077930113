import React, {Component} from "react";
import {withRouter} from "react-router";
import {Table, Icon, Button, Dimmer, Loader} from 'semantic-ui-react';


class ViewGameStatusList extends Component {

    startAddStatus = () => {
        this.props.history.push('/game-statuses/add');
    }

    startEditStatus = (status) => {
        this.props.history.push('/game-statuses/edit/' + status.id);
    }

    render = () => {
        return (
            <div>

                <Dimmer
                    // active={this.props.componentIsLoading}
                    page
                >
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <Table
                    celled
                    striped
                    color="blue"
                    selectable
                    compact='very'
                    size='small'
                    className='small-table'
                >
                    <Table.Header fullWidth >
                        <Table.Row >
                            <Table.HeaderCell textAlign="center" colSpan='3'>
                                <span style={{ fontSize: "18px" }}>
                                    Click The Rows Below To Edit Active State Or Name.
                                </span>
                                <Button
                                    color="blue"
                                    onClick={this.startAddStatus}
                                    floated='right'
                                    size='mini'
                                >
                                    Add Game Status
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center" collapsing>
                                <strong>Id</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                <strong>Status</strong>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.props.statuses.map( (status) => {
                            return(
                                <Table.Row
                                    key={status.id.toString()}
                                    id={status.id.toString()}
                                    name={status.name}
                                    style={{cursor:'pointer'}}
                                    onClick={ (e) => {this.startEditStatus(status)} }
                                >
                                    <Table.Cell>{status.id}</Table.Cell>
                                    <Table.Cell>{status.name}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>

            </div>
        )
    };
};

export default withRouter(ViewGameStatusList);
