import React, { Component } from "react"
import { Route } from "react-router-dom";
import ArticleTypes from "../Views/ViewArticleTypes.js";
import EditArticleType from "../Editors/EditArticleType.js";
import Constants from '../Constants.js';
import APIService from "../API";

class ArticleTypeController extends Component {
  state = {
    articleTypes: [],
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getArticleTypes();
  }

  getArticleTypes = () => {
    fetch(APIService.serverUrl + '/api/private/articleType?showInactive=true', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("getArticleTypes Fetch Failed: ", response.status, response.statusText)
        if (response.status === 401) { this.props.history.push('/') };
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("getArticleTypes Request Succeeded!");
        // console.log(json);
        this.setState({
          "articleTypes": json,
          "componentIsLoading": false,
        })
      } else {
        console.log("getArticleTypes Fetch Failed")
      }
    })
  }

  render = () => {

    let isMobile = Constants.isMobile();
    let relativePath = this.props.match.path;

    return (
      <div>
        <Route
          path={relativePath}
          exact={isMobile}
          render={() => <ArticleTypes {...this.state} />}
        />
        <Route
          path={relativePath + "/:addEdit(add|edit)/:articleTypeId?"}
          render={() => <EditArticleType refreshList={this.getArticleTypes} />}
        />
      </div>
    )
  }
}

export default ArticleTypeController
