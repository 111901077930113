import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input, Grid, Image, Checkbox} from "semantic-ui-react";
import Helpers from "../Helpers";
import APIService from "../API";


class EditSchool extends Component {

    state = {
        schoolName: null,
        schoolLogo: null,
        schoolTextCasterId: null,
        isReadingSchool: false,
        isUpdatingSchool: false,
    };

    componentDidMount() {
        if(this.isEdit()) {
            this.readSchool();
        };
    }

    isEdit = () => this.props.match.params.schoolId !== undefined;

    schoolId = () => this.props.match.params.schoolId;


    updateSchool = () => {
        this.setState({isUpdatingSchool: true});
        fetch(APIService.serverUrl + "/api/private/school" + (this.isEdit() ? "/" + this.schoolId() : ""), {
            method: this.isEdit() ? "PATCH" : "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({
                name: this.state.schoolName,
                textCasterId: this.state.schoolTextCasterId,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState({isUpdatingSchool: false});
                if (json) {
                    this.props.refreshList();
                    this.props.history.push("/schools");

                }
            })
    };

    readSchool = () => {
        this.setState({isReadingSchool: true});
        fetch( APIService.serverUrl + '/api/private/school/' + this.schoolId(), {
            method: "GET",
            headers: APIService.authHeaders(),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState( { isReadingSchool: false});
                if (json) {
                    this.setState( {
                      schoolName: json.name,
                      schoolLogo: json.logoImage,
                      schoolTextCasterId: json.textCasterId,
                    })
                }
            })
    };

    deleteImage = (id) => {
      this.setState({ isReadingSchool: true, });
      fetch(APIService.serverUrl + '/api/private/image/' + id, {
        method: 'DELETE',
        headers: APIService.authHeaders(),
      })
      .then((response) => {
        if (response.ok) {
          // console.log("deleteImage Succeeded!");
          this.props.refreshList();
          this.readSchool();
        } else {
          console.log("deleteImage Failed: ", response.status, response.statusText)
        }
      })
    }

    render = () => {
        return (
            <div>
                <Form loading={this.state.isReadingSchool}>
                    <Header dividing icon='add' content={this.isEdit() ? 'Edit School' : 'Add School'} color='blue'/>
                    <Form.Field>
                        <label>
                            School Name:
                        </label>
                        <Input
                            id='schoolName'
                            name='schoolName'
                            disabled={this.state.isUpdatingSchool}
                            value={this.state.schoolName}
                            onChange={(event) => {
                                this.setState( {schoolName: event.target.value})
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>
                            Text Caster Id:
                        </label>
                        <Input
                            id='schoolTextCasterId'
                            name='schoolTextCasterId'
                            disabled={this.state.isUpdatingSchool}
                            value={this.state.schoolTextCasterId}
                            onChange={(event) => {
                                this.setState( {schoolTextCasterId: event.target.value})
                            }}
                        />
                    </Form.Field>
                    { this.isEdit() ?
                        <Form.Field>
                          <label>Image: </label>
                          <Grid>
                            <Grid.Row>
                              {this.state.schoolLogo ?
                                <Grid.Column key={this.state.schoolLogo.id} >
                                  <Image width='100' height='100'
                                    src={Helpers.getOptimizedImage(this.state.schoolLogo, 150)} />
                                  <div style={{position: 'absolute', top: 0, width: 'auto', height: 'auto' }}>
                                    <Button
                                      size='mini'
                                      circular
                                      color='red'
                                      icon='cancel'
                                      onClick={ () => this.deleteImage(this.state.schoolLogo.id) }/>
                                  </div>
                                </Grid.Column>
                              :
                                <Grid.Column >
                                  <Button
                                    size='mini'
                                    color='green'
                                    onClick={ ()=> {this.props.history.push('/add-image/school/' + this.schoolId())}} >
                                    <Icon name='image outline' /> Add Image
                                  </Button>
                                </Grid.Column>
                              }
                            </Grid.Row>
                          </Grid>
                        </Form.Field>
                      : ''
                    }
                    <Button
                        loading={this.state.isUpdatingSchool}
                        disabled={this.state.isUpdatingSchool}
                        size='mini'
                        color='green'
                        onClick={this.updateSchool}
                    >
                        <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                        {this.isEdit() ? 'Edit School' : 'Add School'}
                    </Button>
                </Form>
            </div>
        )
    };
};

export default withRouter(EditSchool);
