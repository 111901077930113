import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Button, Divider, Form, Header, Modal} from "semantic-ui-react";
import APIService from "../API";


class EditRestaurant extends Component {
  state = {
    restaurant: null,
    name: "",
    address: "",
    phoneNumber: "",
    website: "",
    description: "",
    isCarryOutOption: false,
    isDeliveryOption: false,
    isCurbsideOption: false,
    contactName: "",
    contactPhone: "",
    isApproved: false,
    hasOnlineOrdering: false,
    onlineOrderingNote: "",
    deliveryNote: "",
    hours: "",

    isReadingRestaurant: false,
    isUpdatingRestaurant: false,
    isDeletingRestaurant: false,
    isDeleteModalOpen: false,
  };

  componentDidMount() {
    if(this.isEdit()) {
      this.readRestaurant();
    }
  };

  isEdit = () => this.props.match.params.restaurantId !== undefined;

  restaurantId = () => this.props.match.params.restaurantId;

  readRestaurant = () => {
    this.setState({isReadingRestaurant: true});
    fetch( APIService.serverUrl + "/api/private/promotional-restaurant/" + this.restaurantId(), {
      method: "GET",
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState( { isReadingRestaurant: false});
        if (json) {
          this.setState({
            restaurant: json,
            name: json.name,
            address: json.address,
            phoneNumber: json.phoneNumber,
            website: json.website,
            description: json.description,
            isCarryOutOption: json.isCarryOutOption,
            isDeliveryOption: json.isDeliveryOption,
            isCurbsideOption: json.isCurbsideOption,
            contactName: json.contactName,
            contactPhone: json.contactPhone,
            isApproved: json.isApproved,
            hasOnlineOrdering: json.hasOnlineOrdering,
            onlineOrderingNote: json.onlineOrderingNote,
            deliveryNote: json.deliveryNote,
            hours: json.hours,
          })
          console.log("json", json)
        }
      })
  };

  updateRestaurant = () => {
    this.setState({isUpdatingRestaurant: true});
    fetch(APIService.serverUrl + "/api/private/promotional-restaurant" + (this.isEdit() ? "/" + this.restaurantId() : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        name: this.state.name,
        address: this.state.address,
        phoneNumber: this.state.phoneNumber,
        website: this.state.website,
        description: this.state.description,
        isCarryOutOption: this.state.isCarryOutOption,
        isDeliveryOption: this.state.isDeliveryOption,
        isCurbsideOption: this.state.isCurbsideOption,
        contactName: this.state.contactName,
        contactPhone: this.state.contactPhone,
        isApproved: this.state.isApproved,
        hasOnlineOrdering: this.state.hasOnlineOrdering,
        onlineOrderingNote: this.state.onlineOrderingNote,
        deliveryNote: this.state.deliveryNote,
        hours: this.state.hours,
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if (json) {
          this.props.refreshList();
          if (this.isEdit()) {
            this.readRestaurant();
          } else {
            this.props.history.push('/promotional-restaurants/edit/' + json.id);
            this.readRestaurant();
          }
        } else {
          console.log('patchRestaurant PATCH Failed')
        }
        this.setState({isUpdatingRestaurant: false});
      })
  };

  deleteRestaurant = () => {
    fetch(APIService.serverUrl + "/api/private/promotional-restaurant/" + this.restaurantId(), {
      method: "DELETE",
      headers: APIService.authHeaders(),
      body: JSON.stringify({}),
    })
      .then((response) => {
        if(response.ok) {
          alert("This Restaurant has been deleted.")
          this.props.refreshList();
          this.props.history.push("/promotional-restaurants");
        }
      })
  };

  render = () => {
    return (
      <Form>
        <Header
            dividing
            icon='add'
            color='blue'
            content={this.isEdit() ? 'Edit Restaurant' : 'Add Restaurant'}
        />
          <Form.Checkbox
            toggle
            label="Approved"
            name='approved'
            checked={this.state.isApproved}
            onChange={() => {
              this.setState({ isApproved: !this.state.isApproved })
            }}
          />

          <Form.Group>
            <Form.Input
              width={10}
              label="Business Name"
              placeholder="XYZ Restaurant"
              value={this.state.name}
              onChange={(event) => {
                this.setState({name: event.target.value})
              }}
            />
            <Form.Input
              width={6}
              label="Phone Number"
              type="tel"
              placeholder="4191230987"
              value={this.state.phoneNumber}
              onChange={(event) => {
                this.setState({phoneNumber: event.target.value})
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              style={{minWidth: "300px"}}
              label="Address"
              placeholder="123 Main Street Mansfield, OH"
              value={this.state.address}
              onChange={(event) => {
                this.setState({address: event.target.value})
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              style={{minWidth: "250px"}}
              label="Brief Hours Description"
              placeholder="M-F 9-5, Sat 11-4"
              value={this.state.hours}
              onChange={(event) => {
                this.setState({hours: event.target.value})
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              style={{minWidth: "250px"}}
              label="Website"
              placeholder="www.example.com"
              value={this.state.website}
              onChange={(event) => {
                this.setState({website: event.target.value})
              }}
            />
          </Form.Group>

          <h4 style={{fontSize: '13px', alignSelf: 'center'}}>Select available services:</h4>
          <Form.Group>
            <Form.Checkbox
              label="Carry-Out"
              name='carryout'
              toggle
              checked={this.state.isCarryOutOption}
              onChange={() => {
                this.setState({ isCarryOutOption: !this.state.isCarryOutOption })
              }}
            />
            <Form.Checkbox
              label="Delivery"
              name='delivery'
              toggle
              checked={this.state.isDeliveryOption}
              onChange={() => {
                this.setState({ isDeliveryOption: !this.state.isDeliveryOption })
              }}
            />
            <Form.Checkbox
              label="Curb-Side/Drive-Thru"
              name='curbside'
              toggle
              checked={this.state.isCurbsideOption}
              onChange={() => {
                this.setState({ isCurbsideOption: !this.state.isCurbsideOption })
              }}
            />
            <Form.Checkbox
              label="Online Ordering"
              name='onlineOrdering'
              toggle
              checked={this.state.hasOnlineOrdering}
              onChange={() => {
                this.setState({ hasOnlineOrdering: !this.state.hasOnlineOrdering })
              }}
            />
          </Form.Group>

          {!this.state.isDeliveryOption ? null :
            <Form.Group>
              <Form.Input
                style={{minWidth: "250px"}}
                label="Optional Delivery Note"
                placeholder="grubhub.com only"
                value={this.state.deliveryNote}
                onChange={(event) => {
                  this.setState({deliveryNote: event.target.value})
                }}
              />
            </Form.Group>
          }

          {!this.state.hasOnlineOrdering ? null :
            <Form.Group>
              <Form.Input
                style={{minWidth: "250px"}}
                label="Optional Online Ordering Note"
                placeholder="doordash.com only"
                value={this.state.onlineOrderingNote}
                onChange={(event) => {
                  this.setState({onlineOrderingNote: event.target.value})
                }}
              />
            </Form.Group>
          }

          <Form.TextArea
            label="Description"
            placeholder="Brief description"
            value={this.state.description}
            onChange={(event) => {
              this.setState({description: event.target.value})
            }}
          />

          <Divider hidden />

          <Form.Group>
            <Form.Input
              label="Contact Name"
              placeholder="John Smith"
              value={this.state.contactName}
              onChange={(event) => {
                this.setState({contactName: event.target.value})
              }}
            />
            <Form.Input
              label="Contact Phone Number"
              placeholder="4191230987"
              type="tel"
              value={this.state.contactPhone}
              onChange={(event) => {
                this.setState({contactPhone: event.target.value})
              }}
            />
          </Form.Group>

          <Divider hidden />

          <Form.Field>
            <Button
              onClick={() => {
                this.props.history.push("/promotional-restaurants")
              }}
              content="Back"
              style={{marginRight: '14px'}}
            />
            {!this.isEdit() ? null :
              <Button
                content="Delete"
                color="red"
                onClick={() => {
                  this.setState({isDeleteModalOpen: true})
                }}
                style={{marginRight: '14px'}}
              />
            }
            <Button
              onClick={this.updateRestaurant}
              content={this.isEdit() ? "Save" : "Add"}
              color="green"
            />
          </Form.Field>

          <Modal size='small' open={this.state.isDeleteModalOpen}>
            <Modal.Header>
              Are you sure you want to delete&nbsp;
              <font color='blue'>{this.state.name}</font>?
            </Modal.Header>
            <Modal.Content>
              <Button
                style={{marginRight: '10px'}}
                onClick={() => {
                  this.setState({isDeleteModalOpen: false})
                }}
                content="No"
              />
              <Button
                color='red'
                onClick={this.deleteRestaurant}
                content="Delete"
              />
            </Modal.Content>
          </Modal>


      </Form>
    )
  };
};

export default withRouter(EditRestaurant);
