import React, { Component } from 'react';
import {
  Modal,
  Responsive,
} from 'semantic-ui-react'
import { withRouter } from "react-router";
import Constants from './Constants.js';


class ResponsiveModal extends Component {

  render () {

    let showContent = this.props.match.params.addEdit !== undefined;

    return (
      showContent ?
        <div>
          <Responsive maxWidth={Constants.mobileMaxWidth} > {/* Mobile */}
            {this.props.children}
          </Responsive>
          <Responsive minWidth={Constants.desktopMinWidth} >  {/* Desktop */}
            <Modal
              open
              onMount={this.props.onModalOpen}
              onUnmount={this.props.onModalClose}
              size={this.props.modalSize}
              centered={false}
            >
              <Modal.Content  >
                {this.props.children}
              </Modal.Content>
            </Modal>
          </Responsive>

        </div>
      : null

    );

  }

}

export default withRouter(ResponsiveModal);
