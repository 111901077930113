import React, {Component} from "react"
import APIService from "../API";
import {Button, Checkbox, Form, Header, Icon, Input} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import ListCandidates from "../Views/ListCandidates";

class EditRace extends Component {
  state = {
    race: {
      electionCounties: []
    },
    name: "",
    candidates: [],
    stateWide: false,
    totalVoteCount: false,
    electionCountyIds: [],

    electionCounties: [],

    isUpdatingRace: false,
    isReadingRace: false,
  };

  componentDidMount() {
    if(this.isEdit()) {
      this.readRace();
    };
    this.listElectionCounties()
  };

  isEdit = () => this.props.match.params.raceId !== undefined;

  raceId = () => this.props.match.params.raceId;
  electionId = () => this.props.match.params.electionId;

  listElectionCounties = () => {
    this.setState({isFetchingCounties: true});
    fetch(APIService.serverUrl + '/api/private/election-counties', {
      method: 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({}),
    })
      .then((response) => {
        if(response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if (json) {
          this.setState({electionCounties: json})
        }
        this.setState({isFetchingCounties: false})
      })
  }

  updateRace = () => {
    this.setState({isUpdatingRace: true});
    fetch(APIService.serverUrl + "/api/private/race" + (this.isEdit() ? "/" + this.raceId() : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        name: this.state.name,
        electionId: this.isEdit() ? undefined : Number(this.electionId()),
        stateWide: this.state.stateWide,
        totalVoteCount: this.state.totalVoteCount,
        electionCountyIds: this.state.electionCountyIds,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState({isUpdatingRace: false});
        if (json) {
          if(!this.isEdit()) {
            this.props.history.replace("/races/edit/" + json.id)
          }
          this.readRace();
        }
      })
  };

  readRace = () => {
    this.setState({isReadingRace: true});
    fetch(APIService.serverUrl + '/api/private/race/' + this.raceId(), {
      method: 'GET',
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState({isReadingRace: false});
        if (json) {
          this.setState({
            race: json,
            name: json.name,
            candidates: json.candidates,
            stateWide: json.stateWide,
            totalVoteCount: json.totalVoteCount,
            electionCountyIds: json.electionCounties.map(c => c.id),
          })
        }
      })
  };

  render = () => {
    return (
      <div>
        <Form loading={this.state.isReadingRace} onSubmit={this.updateRace}>
          <Header dividing icon='add' content={this.isEdit() ? 'Edit Race' : 'Add Race'} color='blue'/>
          <Form.Field>
            <label>
              Race Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Input
              name='name'
              disabled={this.state.isUpdatingRace}
              value={this.state.name}
              onChange={event => {
                this.setState( {name: event.target.value})
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>
              Is this Race state wide?
            </label>
            <Checkbox
              toggle
              name='stateWide'
              checked={this.state.stateWide}
              onChange={() => {
                this.setState({ stateWide: !this.state.stateWide })
                if(this.state.stateWide === false) {
                  this.setState({totalVoteCount: true})
                }
              }}
            />
          </Form.Field>

          {this.state.stateWide ? null :
            <Form.Select
              multiple
              label="Counties"
              loading={this.state.isFetchingCounties}
              options={this.state.electionCounties.map((county) => ({
                text: county.name,
                value: county.id,
              }))}
              value={this.state.electionCountyIds}
              onChange={(event, data) => {
                this.setState({electionCountyIds: data.value})
              }}
            />
          }

          <Form.Field>
            <label>
              totalVoteCount:
            </label>
            <Checkbox
              toggle
              name='totalVoteCount'
              checked={this.state.totalVoteCount}
              onChange={() => {
                this.setState({ totalVoteCount: this.state.stateWide ? true : !this.state.totalVoteCount })
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>
              <Icon
                name='asterisk'
                size='small'
                color='green'
              />&nbsp;
              Changes above will reflect further editing once this race has been submitted &nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='green'
              />
            </label>
          </Form.Field>

          <Button
            loading={this.state.isUpdatingRace}
            disabled={this.state.isUpdatingRace}
            size='small'
            color='green'
            type="submit"
          >
            <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
            {this.isEdit() ? 'Edit Race' : 'Add Race'}
          </Button>
        </Form>

        {!this.isEdit() ? null :
          <ListCandidates
            candidates={this.state.candidates}
            electionCounties={this.state.electionCounties}
            totalVoteCount={this.state.race.totalVoteCount}
            refresh={this.readRace}
            raceId={this.raceId()}
          />
        }
      </div>
    )
  };
}

export default withRouter(EditRace)
