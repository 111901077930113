import React, { Component } from 'react';
import {
  Icon,
  Button,
  Form,
  Label,
  Checkbox,
  Header,
  Dropdown,
  TextArea,
  Select,
  Input,
} from 'semantic-ui-react'
import { withRouter } from "react-router";
import APIService from '../API.js';
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import TextAlertList from "../Views/TextAlertList";


class EditClosing extends Component {

  static defaultState = {

    /*** Default Closing State ***/
    id: null,
    viewCount: 0,
    startDate: new moment().startOf('day').add(15, "minutes"),
    endDate: new moment().startOf('day').add(21, "hours"),
    agencyId: null,
    unsavedAgencyName: '',
    closingTypeId: 0,
    noAM: false,
    noPM: false,
    notes: '',
    webNotes: '',
    tvNotes: '',
    approved: true,
    sendTextOnCreate: undefined,
    agencyTextcasterId: '',
    /*** Default Closing State ***/

    agencyTypeId: 0,
    agencyTypeName: "",

    closingTypes: [],
    agencyTypes: [],
    agencyList: [],

    unmodifiedClosing: {},

    textAlerts: [],
    closing: undefined,

    isFetchingClosing: false,
    isFetchingClosingTypes: false,
    isFetchingAgencies: false,
    isFetchingAgencyTypes: false,
    isSaveAvailable: false,
  }

  state = EditClosing.defaultState;

  doesClosingExist = () => this.props.match.params.closingId !== undefined;

  componentDidMount() {
    this.getClosing();
    this.getClosingTypes();
    this.getAgencyTypes();
    this.getAgencies();

    setTimeout(this.enableSaveButton, 5000);
  }

  getClosing = () => {
    if (this.doesClosingExist()) {
      this.setState({isFetchingClosing: true});
      fetch(APIService.serverUrl + '/api/private/closing/'+this.props.match.params.closingId, {
        method: "GET",
        headers: APIService.authHeaders(),
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("ClosingList Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("ClosingList Request Succeeded!");
          // console.log(json);
          this.startEditClosing(json);
          this.setState({isFetchingClosing: false})
        } else {
          console.log("ClosingList Fetch Failed")
        }
      })
    }
  }

  getClosingTypes = () => {
    this.setState({isFetchingClosingTypes: true});
    fetch(APIService.serverUrl + '/api/private/closingType', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("closingTypes Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("closingTypes Request Succeeded!");
          this.setState({
            "closingTypes": json,
            isFetchingClosingTypes: false
          })
        } else {
          console.log("closingTypes Fetch Failed")
        }
      })
  }

  getAgencyTypes = () => {
    this.setState({isFetchingAgencyTypes: true})
    fetch(APIService.serverUrl + "/api/private/agencyType", {
      method: "GET",
      headers: APIService.authHeaders(),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({
          agencyTypes: json,
          isFetchingAgencyTypes: false
        })
      } else {
        console.log("Fetch Agency Types Failed");
      }
    })
  };

  getAgencies = (agencyTypeId = null) => {
    this.setState({isFetchingAgencies: true})
    fetch(APIService.serverUrl + '/api/private/agencies', {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        ...(agencyTypeId ? {agencyTypeId: agencyTypeId} : null),
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("getAgencies Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("getAgencies Request Succeeded!");
          this.setState({
            "agencyList": json,
            isFetchingAgencies: false
          })
        } else {
          console.log("getAgencies Fetch Failed")
        }
      })
  }

  patchClosing = () => {
    let apiPath = this.doesClosingExist() ? '/api/private/closing/'+this.state.id : '/api/private/closing';
    fetch(APIService.serverUrl + apiPath, {
      method: this.doesClosingExist() ? 'PATCH' : 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        id: this.state.id,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        agencyId: this.state.agencyId === "" ? null : this.state.agencyId,
        unsavedAgencyName: this.state.unsavedAgencyName,
        closingTypeId: this.state.closingTypeId,
        noAM: this.state.noAM,
        noPM: this.state.noPM,
        notes: this.state.notes,
        webNotes: this.state.webNotes,
        tvNotes: this.state.tvNotes,
        approved: this.state.approved,
        sendTextOnCreate: this.state.sendTextOnCreate,
        sendTextNow: this.state.sendTextOnCreate,
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("patchClosing PATCH Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("patchClosing PATCH Succeeded!");
        this.props.refreshList();
        if (this.doesClosingExist()) {
          this.getClosing();
        } else {
          this.props.history.push('/closings/edit/'+json.id);
          this.getClosing();
        }
      } else {
        console.log("patchClosing PATCH Failed")
      }
    })
  }

  startEditClosing = (closing) => {
    let agencyTypeId = 0;
    let agencyTypeName = "";

    if (closing.agency) {
      agencyTypeId = closing.agency.agencyType.id;
      this.state.agencyTypes.forEach(v => { if (v.id === agencyTypeId) agencyTypeName = v.name });
    } else {
      this.state.agencyTypes.forEach(v => {
        if (v.name.toLowerCase() === "other") {
          agencyTypeId = v.id;
          agencyTypeName = v.name;
        }
      });
    }

    let apiClosingData = {
      id: closing.id,
      viewCount: closing.viewCount,
      startDate: moment(closing.startDate),
      endDate: moment(closing.endDate),
      unsavedAgencyName: closing.unsavedAgencyName,
      closingTypeId: closing.closingType.id,
      noAM: closing.noAM,
      noPM: closing.noPM,
      notes: closing.notes,
      webNotes: closing.webNotes,
      tvNotes: closing.tvNotes,
      approved: closing.approved,
      agencyId: closing.agency ? closing.agency.id : '',
      agencyTextcasterId: closing.agency ? closing.agency.textCasterId : '',
    }

    this.setState({
      ...apiClosingData,
      agencyTypeId: agencyTypeId,
      agencyTypeName: agencyTypeName,
      textAlerts: closing.textAlerts,
      unmodifiedClosing: apiClosingData,
      closing: closing,
    });
  };

  onToggleChange = (e) => {
    let additions = {};
    if (e.target.name === 'approved' && !e.target.checked) {
      additions.sendTextOnCreate = false;
    }
    this.setState({
      [e.target.name]: e.target.checked,
        ...additions,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  onSelectChange = (e, { name, value }) => {
    this.setState({[name]: value});
  };

  onStartDateChange = (date) => {
    date = moment(date)
    if (date.format("HHmm") === "0000") {
      date = date.add(15, "minutes");
    }
    this.setState({ startDate: date })
  }

  onEndDateChange = (date) => {
    date = moment(date)
    if (date.format("HHmm") === "0000") {
      date = date.add(15, "minutes");
    }
    this.setState({ endDate: date })
  }

  onAgencyTypeChange = (e, { name, value }) => {
    let agencyTypeName = "";
    this.state.agencyTypes.forEach(a => { if (a.id === value) agencyTypeName = a.name });

    let endTime = new moment().endOf('day');

    switch (agencyTypeName.toLowerCase()) {
      case 'school':
        let schoolEndTime = new moment().set('hour', 11).set('minute', 0);
        if ( moment().isBefore(schoolEndTime) ) {
          endTime = schoolEndTime;
        }
        break;
      case 'business':
        let businessEndTime = new moment().set('hour', 17).set('minute', 0);
        if ( moment().isBefore(businessEndTime) ) {
          endTime = businessEndTime;
        }
        break;
      case 'church':
        let churchEndTime = new moment().set('hour', 19).set('minute', 0);
        if ( moment().isBefore(churchEndTime) ) {
          endTime = churchEndTime;
        }
        break;
      default:
        break;
    }
    this.setState({
      agencyTypeId: value,
      agencyTypeName: agencyTypeName,
      agencyList: [],
    });
    if (this.state.openClosingModal) {
      this.setState({
        agencyId: "",
        endDate: endTime,
      });
    }
    if (value !== 0) this.getAgencies(value);
  };

  // onAgencyAddition = (e, { value }) => {
  //   fetch(APIService.serverUrl + "/api/private/agency", {
  //     method: "POST",
  //     headers: APIService.authHeaders(),
  //     body: JSON.stringify({
  //       name: value,
  //       agencyTypeId: this.state.agencyTypeId,
  //     })
  //   }).then(response => response.ok ? response.json() : null).then(json => {
  //     if (json) {
  //       this.getAgencies(this.state.agencyTypeId);
  //       if (this.state.openClosingModal) this.setState({agencyId: json.id});
  //     } else {
  //       console.log("Add Agency Request Failed")
  //     }
  //   })
  // };

  sendText = () => {
    fetch(APIService.serverUrl + "/api/private/closing/" + this.state.id + "/send-text", {
      method: "GET",
      headers: APIService.authHeaders(),
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.getClosing()
      } else {
        console.log("Sending a text for the county failed")
      }
    })
  };

  isFormModified = () => {
    if (!this.doesClosingExist()) { return true }
    for (var key in this.state.unmodifiedClosing) {
      if (this.state.unmodifiedClosing[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  }

  enableSaveButton = () => {
    this.setState({isSaveAvailable: true})
  }

  render() {

    let requiredFieldsComplete = (
      this.state.isSaveAvailable
      && ((this.state.agencyId !== null
        || (this.state.agencyTypeName === "Other"
          && this.state.unsavedAgencyName !== ''
        )) && this.state.agencyTypeId !== 0
        && this.state.closingTypeId !== 0
      ) || (this.state.isFetchingClosing
        && this.state.isFetchingClosingTypes
        && this.state.isFetchingAgencies
        && this.state.isFetchingAgencyTypes
      )
    );

    let hasTextcasterId = this.state.agencyTextcasterId !== '' && this.state.agencyTextcasterId !== undefined;

    return (

        <Form >
          <Header
            dividing
            color='blue'
            icon={ this.state.id ? 'edit outline' : 'add' }
            content={ this.state.id ? 'Edit Closing' : 'Add Closing' } />
          {/* View Count */}
          <Form.Group inline>
            <label>View Count:</label>
            <strong>{this.state.viewCount}</strong>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              <label color='grey' >Start Date: </label>
              <DatePicker
                name='startDate'
                dateFormat='MM/dd/yyyy h:mm aa'
                showTimeSelect
                timeIntervals={15}
                timeCaption="Time"
                selected={this.state.startDate.toDate()}
                onChange={this.onStartDateChange} />
            </Form.Field>
            <Form.Field>
              <label>End Date: </label>
              <DatePicker
                name='endDate'
                dateFormat='MM/dd/yyyy h:mm aa'
                showTimeSelect
                timeIntervals={15}
                timeCaption="Time"
                selected={this.state.endDate.toDate()}
                onChange={this.onEndDateChange} />
            </Form.Field>
          </Form.Group>
          <Form.Field >
            <label>
              Agency Type:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
            </label>
            <Select
              id="agencyTypeId"
              name='agencyTypeId'
              options={this.state.agencyTypes.map(
                aChoice => ( { key: aChoice.id, text: aChoice.name, value: aChoice.id }
              ))}
              placeholder='Choose the Agency Type...'
              value={this.state.agencyTypeId}
              onChange={this.onAgencyTypeChange} />
          </Form.Field>
          {this.state.agencyTypeName.toLowerCase() === "other" ?
            <Form.Field>
              <label>
                Other Agency Name:&nbsp;
              </label>
              <Input
                id='unsavedAgencyName'
                name='unsavedAgencyName'
                placeholder='Other Agency Name...'
                value={this.state.unsavedAgencyName}
                onChange={this.onInputChange}/>
            </Form.Field>
            :
            <Form.Field >
              <label>
                Agency:&nbsp;
              </label>
              <Dropdown
                id="agencyId"
                name='agencyId'
                options={this.state.agencyList.map( aChoice => ({
                  key: aChoice.id,
                  text: aChoice.name + " - " + (aChoice.permissionCode ? aChoice.permissionCode : "No permission code"),
                  value: aChoice.id
                }))}
                disabled={this.state.agencyTypeId === 0}
                placeholder='Choose the Agency...'
                fluid
                selection
                search
                clearable
                value={this.state.agencyId}
                onChange={this.onSelectChange}
                // allowAdditions
                // onAddItem={this.onAgencyAddition}
              />
            </Form.Field>
          }
          <Form.Field >
            <label>
              Closing Type: &nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
            </label>
            <Dropdown
              id="closingTypeId"
              name='closingTypeId'
              placeholder='Choose the Closing Type...'
              fluid
              selection
              options={this.state.closingTypes.map(
                tChoice => { return { key: tChoice.id, text: tChoice.name, value: tChoice.id }
              })}
              value={this.state.closingTypeId}
              onChange={this.onSelectChange} />
          </Form.Field>
          {this.state.agencyTypeName !== "School" ? null :
            <Form.Group widths='equal' inline>
              <Form.Field>
                <label>No AM Kindergarten:</label>
                <Checkbox
                  id="noAM"
                  name='noAM'
                  checked={this.state.noAM}
                  onChange={this.onToggleChange}
                  toggle/>
              </Form.Field>
              <Form.Field>
                <label>No PM Kindergarten:</label>
                <Checkbox
                  id="noPM"
                  name='noPM'
                  checked={this.state.noPM}
                  onChange={this.onToggleChange}
                  toggle/>
              </Form.Field>
            </Form.Group>
          }
          <Form.Field >
            <label>
              Main Notes:
            </label>
            <TextArea
              id='notes'
              name='notes'
              placeholder='Enter the Notes...'
              value={this.state.notes}
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>TV Notes: </label>
            <TextArea
              id='tvNotes'
              name='tvNotes'
              placeholder='Enter the TV Notes...'
              value={this.state.tvNotes}
              onChange={this.onInputChange} />
            <Label size='tiny' pointing >
              Only enter if you want TV Closings to be different than Main Notes.
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Web Notes: </label>
            <TextArea
              id='webNotes'
              name='webNotes'
              placeholder='Enter the Web Notes...'
              value={this.state.webNotes}
              onChange={this.onInputChange} />
            <Label size='tiny' pointing >
              Only enter if you want the website to be different than Main Notes.
            </Label>
          </Form.Field>
          <Form.Group>
            <Form.Field>
              <label>Approved:</label>
              <Checkbox
                id="approved"
                name='approved'
                checked={this.state.approved}
                onChange={this.onToggleChange}
                toggle />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <Label size='tiny' >
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
              Denotes required field
            </Label>
          </Form.Field>
          <Form.Field >
            <Button
              size='mini'
              color={ this.isFormModified() ? 'red' : undefined }
              onClick={ () => this.props.history.push('/closings') } >
              <Icon name='cancel' /> { this.isFormModified() ? 'Cancel' : 'Close' }
            </Button>
            <Button
              id="saveButton"
              size='mini'
              color='green'
              onClick={() => this.patchClosing()}
              disabled={!requiredFieldsComplete}
            >
              <Icon name='save outline' /> Save
            </Button>
          </Form.Field>
          {!this.doesClosingExist() ? null :
            <div>
              <div>&nbsp;</div>
              <TextAlertList
                closing={this.state.closing}
                textAlerts={this.state.textAlerts}
                disableSend={!this.state.approved || this.isFormModified() || !hasTextcasterId}
                onSuccess={this.getClosing}
              />
            </div>
          }
        </Form>


    );
  }
}

export default withRouter(EditClosing);

/*



*/
