import React, { Component } from 'react';
import {
  Icon,
  Image,
  Button,
  // Modal,
  Form,
  Input,
  // Grid,
  Header,
  Segment,
} from 'semantic-ui-react'
import APIService from './API.js';


export default class Login extends Component {

  state = {
    username: '',
    password: '',

  }

  authenticateUser = () => {
    fetch(APIService.serverUrl + '/login', {
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Basic ' + Buffer(this.state.username + ":" + this.state.password).toString('base64'),
      },
      method: 'POST',
    }).then( (response) => {
      if (response.ok) {
        return response.json();
      } else {
        this.setState({
          password: '',
        })
        console.log("Login Failed: ", response.status, response.statusText)
        alert('Login Failed. Please try again')
      }
    }).then( (json) => {
      if (json) {
        APIService.setToken(json.string, json.expiresAt);
        this.props.onSuccessfullLogin();
        // console.log('Login Worked. response: ' + json)
      } else {
        console.log('Could not get JSON')
      }
    })
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }


  render() {

    return (

      <Segment.Group size='small' compact className='login-segment' >
        <Image src='/images/logo.png' />
        <Segment basic color='blue' >
          <Header as='h3' icon='settings' content='Admin Portal Login' color='blue' />
          <Form>
            <Form.Field>
              <label>Email: </label>
              <Input
                icon='user'
                id='username'
                name='username'
                placeholder='Enter Your Email...'
                value={this.state.username}
                onChange={this.onInputChange} />
            </Form.Field>
            <Form.Field>
              <label>Password: </label>
              <Input
                icon='lock'
                type='password'
                id='password'
                name='password'
                placeholder='Enter Your Password...'
                value={this.state.password}
                onChange={this.onInputChange} />
            </Form.Field>
            <Form.Field>
              <Button size='mini' color='green' onClick={this.authenticateUser} >
                <Icon name='power' /> Login
              </Button>
            </Form.Field>
          </Form>
        </Segment>
      </Segment.Group>

    );
  }

}

/*



*/
