import React, {Component} from 'react'
import moment from "moment/moment";
import {Dimmer, Header, Icon, Label, Loader, Table} from "semantic-ui-react";

export default class ListClosingsRadio extends Component {

  componentDidMount() {
    if (this.props.showExpired) {
      this.props.toggleShowExpiredFilter()
    }
  }

  render() {

    let tables = {}
    this.props.closings.forEach(closing => {
      let subscript = closing.agency ? closing.agency.agencyType.name : "Other"
      if (tables[subscript]) {
        tables[subscript].push(closing)
      } else {
        tables[subscript] = [closing]
      }
    })

    return (

      <div>

        <Dimmer active={this.props.areClosingsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        {Object.keys(tables).map(key => {
          let closings = tables[key];
          return (
            <div key={key} style={{marginTop: '20px'}}>
              <Header content={key} />

              <Table celled striped color="blue" selectable compact='very' size='small' >
                <Table.Header fullWidth >
                  <Table.Row>
                    <Table.HeaderCell textAlign="center" collapsing>
                      <strong>Approved</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <strong>Agency</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <strong>Closing Type</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell >
                      <strong>Notes</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell >
                      <strong>Created</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <strong>Updated</strong>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {closings.map( (closing) => {
                    return(
                      <Table.Row
                        key={closing.id.toString()}
                        id={closing.id.toString()}
                        activestate={closing.approved.toString()}
                        style={{cursor:'pointer'}}
                      >
                        <Table.Cell name='approved' textAlign="center" collapsing>
                          <Icon name={closing.approved ? 'check circle' : 'circle outline'} color="blue" />
                        </Table.Cell>
                        <Table.Cell name='agency' >
                          <Label
                            size='small'
                            icon={closing.agency ? 'building' : 'building outline'}
                            content={closing.agency ? closing.agency.name : closing.unsavedAgencyName} />
                        </Table.Cell>
                        <Table.Cell name='type'>
                          {closing.closingType.name}
                        </Table.Cell>
                        <Table.Cell name='notes'>
                          {closing.notes}
                        </Table.Cell>
                        <Table.Cell name='createdAt'>
                          <Icon name='add' color='grey' />
                          {moment(closing.createdAt).format('M/DD/YY')}
                          <em> - {moment(closing.createdAt).format('h:mm a')}</em>
                        </Table.Cell>
                        <Table.Cell name='updatedAt' color='grey' size='small' >
                          <Icon name='refresh' color='grey' />
                          {moment(closing.updatedAt).format('M/DD/YY')}
                          <em> - {moment(closing.updatedAt).format('h:mm a')}</em>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </div>
          )
        })}

      </div>

    );
  }
}