import React, {Component} from "react";
import {withRouter} from "react-router";
import {Table, Icon, Button, Dimmer, Loader} from 'semantic-ui-react';

class ViewRestaurantList extends Component {

  startAddRestaurant = () => {
    this.props.history.push('/promotional-restaurants/add');
  };

  startEditRestaurant = (restaurant) => {
    this.props.history.push('/promotional-restaurants/edit/' + restaurant.id)
  };

  render = () => {
    return (
      <div>
        <Dimmer page>
            <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Table
            celled
            striped
            color="blue"
            selectable
            compact='very'
            size='small'
            className='small-table'
        >
          <Table.Header fullWidth >
            <Table.Row >
              <Table.HeaderCell textAlign="center" colSpan='3'>
                <span style={{ fontSize: "18px" }}>
                  Click The Rows Below To Edit Active State Or Name.
                </span>
                <Button
                  color="blue"
                  onClick={this.startAddRestaurant}
                  floated='right'
                  size='mini'
                >
                  Add Restaurant
                </Button>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign="center" collapsing>
                <strong>Id</strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" collapsing>
                <strong>Approved</strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <strong>Name</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!this.props.restaurants ? null :
              this.props.restaurants.map( (restaurant) => {
                return(
                  <Table.Row
                    key={restaurant.id.toString()}
                    id={restaurant.id.toString()}
                    name={restaurant.name}
                    style={{cursor:'pointer'}}
                    onClick={ (e) => {this.startEditRestaurant(restaurant)} }
                  >
                    <Table.Cell>{restaurant.id}</Table.Cell>
                    <Table.Cell name='approved' textAlign="center" >
                      <Icon name={restaurant.isApproved ? 'check circle' : 'circle outline'} color="blue" />
                    </Table.Cell>
                    <Table.Cell>{restaurant.name}</Table.Cell>
                  </Table.Row>
                )
            })}
          </Table.Body>
        </Table>
      </div>
    );
  };
};

export default withRouter(ViewRestaurantList);
