import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import {withRouter} from "react-router";
import ViewGameStatusList from "../Views/ViewGameStatusList";
import EditGameStatus from "../Editors/EditGameStatus";
import APIService from "../API.js";


class SchoolController extends Component {

    state = {
        gameStatuses: [],
        isFetchingStatuses: false,
    };

    componentDidMount() {
        this.getStatusList();
    };

    getStatusList = () => {
        this.setState( {isFetchingStatuses: true})
        fetch(APIService.serverUrl + "/api/private/game-statuses", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState( {gameStatuses: json})
                }
                this.setState({isFetchingStatuses: false})
            })
    };



    render = () => {

        let relativePath = this.props.match.path;

        return (
            <div>
                <Switch>
                    <Route
                        path={relativePath}
                        exact
                        render={() =>
                            <ViewGameStatusList
                                {...this.state}
                                refreshList={this.getStatusList}
                                statuses={this.state.gameStatuses}
                            />}
                    />
                    <Route
                        path={relativePath + "/add"}
                        render={() =>
                            <EditGameStatus
                                refreshList={this.getStatusList}

                            />}
                    />
                    <Route
                        path={relativePath + "/edit/:statusId"}
                        render={() =>
                            <EditGameStatus
                                refreshList={this.getStatusList}
                            />}
                    />
                </Switch>

            </div>
        )
    };
};

export default withRouter(SchoolController);