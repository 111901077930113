import React, {Component} from 'react'
import {Segment, Table, Header, Icon, Button, Modal} from "semantic-ui-react";
import APIService from "../API";
import {withRouter} from 'react-router';
import EditUser from "../Editors/EditUser";

class ListUsers extends Component {

  static defaultProps = {
    agencyId: undefined,
  };

  state = {
    users: [],
    isLoadingUsers: false,
    hasLoadedUsers: false,
    isEditUserModalOpen: false,
  };

  componentDidMount() {
    this.fetchUsers()
  }

  fetchUsers = () => {
    this.setState({isLoadingUsers: true});
    fetch(APIService.serverUrl + "/api/private/users", {
      method: 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        ...(this.props.agencyId ? {agencyId: this.props.agencyId} : {})
      })
    }).then(response => response.ok ? response.json() : null).then(json => {
      if (json) {
        this.setState({users: json})
      } else {
        alert("Fetching users has failed")
      }
      this.setState({
        isLoadingUsers: false,
        hasLoadedUsers: true,
      })
    })
  };

  render = () => {

    let editUserModal =
      <Modal
        open={this.state.isEditUserModalOpen}
        trigger={
          <Button
            content='Add User' icon='plus' color='green'
            onClick={() => this.setState({isEditUserModalOpen: true})}
          />
        }
        size='mini'
      >
        <Modal.Content>
          <EditUser
            refreshList={this.fetchUsers}
            agencyId={this.props.agencyId}
            onClose={() => this.setState({isEditUserModalOpen: false})}
          />
        </Modal.Content>
      </Modal>;

    return this.state.hasLoadedUsers && this.state.users.length === 0 ?
      <Segment placeholder >
        <Header icon>
          <Icon name='user' />
          There are no users
        </Header>
        {editUserModal}
      </Segment>
      :
      <Segment loading={this.state.isLoadingUsers} basic style={{padding: 0}}>
        <Table
          headerRows={[
            <Table.Row key='menu'>
              <Table.HeaderCell colSpan={3}>
                {editUserModal}
              </Table.HeaderCell>
            </Table.Row>,
            <Table.Row key='headers'>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>E-Mail</Table.HeaderCell>
              <Table.HeaderCell>Roles</Table.HeaderCell>
            </Table.Row>
          ]}
          tableData={this.state.users}
          renderBodyRow={(user) => {
            return (
              <Table.Row key={user.id}>
                <Table.Cell>{user.name}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.roles.map((role, index) => (index ? ', ':'') + role.name )}</Table.Cell>
              </Table.Row>
            )
          }}
        />
      </Segment>
  }
}

export default withRouter(ListUsers);