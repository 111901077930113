import React, { Component } from "react"
import { Route } from "react-router-dom";
import ViewContests from "../Views/ViewContests.js";
import EditContest from "../Editors/EditContest.js";
import APIService from "../API";


class ContestListController extends Component {

  state = {
    contests: [],
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getContests();
  }

  getContests = () => {
    fetch(APIService.serverUrl + '/api/private/contest', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("getContests Fetch Failed: ", response.status, response.statusText)
        if (response.status === 401) { this.props.history.push('/') };
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("getContests Request Succeeded!");
        // console.log(json);
        this.setState({
          "contests": json,
          "componentIsLoading": false,
        })
      } else {
        console.log("getContests Fetch Failed returned null")
      }
    })
  }

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <div>
        <Route
          path={relativePath}
          exact
          render={() => <ViewContests {...this.state} />}
        />
        <Route
          path={relativePath + "/:addEdit(add|edit)/:contestId?"}
          render={() => <EditContest refreshList={this.getContests} />}
        />
      </div>
    );
  }
}

export default ContestListController
