import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Header,
  Icon,
  Form,
  Input,
  Button,
} from 'semantic-ui-react'
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import APIService from './API.js';


export default class Cropper extends PureComponent {

  entityId = this.props.match.params.entityId;
  entityPath = this.props.match.params.entityPath;
  noAspect = this.props.match.params.noAspect === "no-aspect";
  // // future edit capability
  // articleImageId = this.props.match.params.articleImageId;
  // isEditMode = this.articleImageId !== undefined;

  state = {
    src: null,
    croppedImageUrl: null,
    crop: {
      aspect: this.noAspect ? null : this.entityPath === "school" ? 1 : (16/9),
      height: this.entityPath === "school" ? null : 1440,
      width: null,
      x: 0,
      y: 0,
    },
    newArticleImage: {
      title: '',
      description: '',
      original: null,
      size900: null,
      size750: null,
      size360: null,
      size263: null,
      size150: null,
    },
    componentIsLoading: true,
    minWidth: 100,
  };
  
  imageInput = React.createRef()
  shouldSetCropToImageSize = false

  componentDidMount() {
    this.setState({ componentIsLoading: false, })
    console.log(window.location.protocol+'//'+window.location.host);
  }

  onImageLoaded = (image) => {
    if (this.noAspect) {
      this.shouldSetCropToImageSize = true
    }
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    let cropState = crop
    if (this.shouldSetCropToImageSize) {
      this.shouldSetCropToImageSize = false
      cropState.width = this.imageRef.naturalWidth
      cropState.height = this.imageRef.naturalHeight
    }
    this.setState({crop: cropState})
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      let aspect = crop.width / crop.height;
      this.setState({ croppedImageUrl });
      this.imageResize( croppedImageUrl, 900, aspect );
      this.imageResize( croppedImageUrl, 750, aspect );
      this.imageResize( croppedImageUrl, 360, aspect );
      this.imageResize( croppedImageUrl, 263, aspect );
      this.imageResize( croppedImageUrl, 150, aspect );
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  };

  addArticleImage = (e) => {
    this.setState({ 'componentIsLoading': true, });
    let data = new FormData();
    data.append("original", this.state.newArticleImage.original);
    if (this.state.crop.aspect) {
      data.append("aspect", this.state.crop.aspect);
    }
    data.append("height", this.state.crop.height);
    data.append("width", this.state.crop.width);
    data.append("originX", this.state.crop.x);
    data.append("originY", this.state.crop.y);
    data.append("title", this.state.newArticleImage.title);
    data.append("description", this.state.newArticleImage.description);
    switch (this.entityPath) {
    case 'article':
      data.append("size900", this.state.newArticleImage.size900);
      data.append("size750", this.state.newArticleImage.size750);
      data.append("size360", this.state.newArticleImage.size360);
      data.append("size263", this.state.newArticleImage.size263);
      data.append("articleId", this.entityId);
      break;
    case 'contest':
      data.append("size263", this.state.newArticleImage.size263);
      data.append("contestId", this.entityId);
      break;
    case 'school':
      data.append("size150", this.state.newArticleImage.size150);
      data.append("schoolId", this.entityId);
      break;
    default:
      break;
    };
    fetch(APIService.serverUrl + '/api/private/image', {
      headers: { Authorization: APIService.bearerToken() },
      method: 'POST',
      body: data,
    }).then(
      response => response.ok ? response.json() : null // if the response is a JSON object
    ).then(
      json => {
        if (json) {
          this.props.history.goBack();
        } else {
          alert('Image Upload Failed');
        }
      }
    );
  };

  setNewImageState = (object) => {
    let modifyImage = {
      ...this.state.newArticleImage,
      ...object
    };
    this.setState({ newArticleImage: modifyImage });
  }

  onImageInputChange = (e) => {
    this.setNewImageState({ [e.target.name]: e.target.value, });
  }

  imageResize = (blobUrl, resizeWidth, resizeAspect) => {
    let resizeHeight = Math.round(resizeWidth / resizeAspect)
    var sizeState = "size" + resizeWidth;
    var croppedImage = document.createElement("img");
    croppedImage.onload = () => {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext("2d");
      ctx.drawImage(croppedImage, 0, 0);

      let finalWidth = (resizeWidth > croppedImage.width) ? croppedImage.width : resizeWidth;
      let finalHeight = (resizeHeight > croppedImage.height) ? croppedImage.height : resizeHeight;

      canvas.width = finalWidth;
      canvas.height = finalHeight;

      ctx.drawImage(croppedImage, 0, 0, finalWidth, finalHeight);
      canvas.toBlob((blob)=>{
        this.setNewImageState({ [sizeState]: blob, });
      },'image/jpeg',1);
    }
    croppedImage.src = blobUrl;
  }

  onNewImageChange = (e) => {
    if (this.imageInput.current.files && this.imageInput.current.files.length > 0) {
      let file = this.imageInput.current.files[0];
      this.setNewImageState({ [e.target.name]: file });
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(file);
    }
  }

  cancelCroppedImage = () => {
    this.setState({
      src: null,
      croppedImageUrl: null,
      crop: {
        // aspect: 16 / 9,
        height: null,
        x: 0,
        y: 0,
      },
      newArticleImage: {
        title: '',
        description: '',
        original: undefined,
        size900: undefined,
        size750: undefined,
        size360: undefined,
        size263: undefined,
        size150: undefined,
      },
    });
    this.props.history.goBack(-1); //or something ???
    // this.props.history.push('/edit-article/'+this.entityId);
  };

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <Container fluid >

        <Dimmer active={this.state.componentIsLoading} page >
          <Loader size='massive'>Saving</Loader>
        </Dimmer>

        <Form className='image-form'>
          <Header
            dividing
            color='blue'
            icon='photo'
            content=' Add Image' />
          <Form.Field>
            <label>Title: </label>
            <Input
              fluid
              name="title"
              placeholder='Enter Title'
              value={this.state.newArticleImage.title}
              onChange={this.onImageInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Description: </label>
            <Input
              fluid
              name="description"
              placeholder='Enter Description'
              value={this.state.newArticleImage.description}
              onChange={this.onImageInputChange} />
          </Form.Field>
          <Form.Field>
            <input name="original" type="file" onChange={this.onNewImageChange} ref={this.imageInput} />
          </Form.Field>
          { src ? <h4>Height: {Math.round(crop.height)} / Width: {Math.round(crop.width)}</h4> : '' }
          <Segment placeholder textAlign='center' >
            { src ?
              <ReactCrop
                src={src}
                crop={crop}
                minWidth={this.state.minWidth}
                onImageLoaded={this.onImageLoaded}
                onChange={this.onCropChange}
                onComplete={this.onCropComplete} />
              : 'Select an Image...'
            }
          </Segment>
          <Segment tertiary textAlign="center" >
            { croppedImageUrl ?
              <img
                alt="Cropped"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
                src={croppedImageUrl} />
              : 'Cropped Image Preview...'
            }
          </Segment>
          <Form.Field >
            <Button size='mini' color='red' onClick={this.cancelCroppedImage} >
              <Icon name='cancel' /> Cancel
            </Button>
            <Button size='mini' color='green' onClick={this.addArticleImage} >
              <Icon name='save outline' /> Save
            </Button>
          </Form.Field>

        </Form>
      </Container>
    );
  }
}
