import React, { Component } from 'react';
import {
  Table,
  Button,
  Dimmer,
  Loader,
  Image,
  Icon,
} from 'semantic-ui-react';
import Moment from 'react-moment';
import { withRouter } from "react-router";
import Helpers from '../Helpers.js';


class Contests extends Component {

  startNewContest = () => {
    this.props.history.push('/contests/add');
  }

  editContest = (contest) => {
    this.props.history.push('/contests/edit/'+contest.id);
  };

  render() {

    let defaultImage = window.location.protocol+'//'+window.location.host+'/images/logo.png'

    return (

      <div>

        <Dimmer active={this.props.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Table celled striped color="blue" selectable compact='very' size='small' >
          <Table.Header fullWidth >
            <Table.Row >
              <Table.HeaderCell textAlign="center" colSpan='6'>
                <span style={{ fontSize: "18px" }}>Click The Rows Below To Edit Contests.</span>
                <Button color="blue" onClick={this.startNewContest} floated='right' size='mini' >Add Contest</Button>
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell textAlign="center" collapsing>
                <strong>Image</strong>
              </Table.HeaderCell>{/**/}
              <Table.HeaderCell textAlign="center" collapsing>
                <strong>Approved</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Contest Name</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Start Date</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>End Date</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.contests.map( (contest) => {
              return(
                <Table.Row
                  key={contest.id.toString()}
                  id={contest.id.toString()}
                  name={contest.name}

                  style={{cursor:'pointer'}}
                  onClick={ (e)=> {this.editContest(contest) }}
                >
                  <Table.Cell textAlign="center" collapsing>
                    {contest.image ?
                      <Image alt={contest.name} src={Helpers.getOptimizedImage(contest.image, 263)} width='100px' /> :
                      <Image alt='default' src={defaultImage} width='100px' />
                    }
                  </Table.Cell>{/**/}
                  <Table.Cell textAlign="center" collapsing>
                    <Icon
                      name={ contest.isApproved ? 'check circle' : 'circle outline' }
                      color="blue" />
                  </Table.Cell>
                  <Table.Cell>
                    {contest.name}
                  </Table.Cell>
                  <Table.Cell>
                    <Moment format='MM/DD/YYYY' >{contest.startDate}</Moment>
                  </Table.Cell>
                  <Table.Cell>
                    <Moment format='MM/DD/YYYY' >{contest.endDate}</Moment> {/*  - h:mm a */}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

      </div>

    );
  }
}

export default withRouter(Contests)

/*



*/
