import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input} from "semantic-ui-react";
import APIService from "../API";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';


class EditElection extends Component {

  state = {
    electionName: null,
    electionStart: new moment,
    electionEnd: new moment,

    isUpdatingElection: false,
    isReadingElection: false,
  };

  componentDidMount() {
    if(this.isEdit()) {
        this.readElection();
    };
  };

  isEdit = () => this.props.match.params.electionId !== undefined;

  electionId = () => this.props.match.params.electionId;

  updateElection = () => {
    this.setState({isUpdatingElection: true});
    fetch(APIService.serverUrl + "/api/private/election" + (this.isEdit() ? "/" + this.electionId() : ""), {
        method: this.isEdit() ? "PATCH" : "POST",
        headers: APIService.authHeaders(),
        body: JSON.stringify({
            name: this.state.electionName,
            startAt: moment(this.state.electionStart),
            endAt: moment(this.state.electionEnd),
        }),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return null;
            }
        })
        .then((json) => {
            this.setState({isUpdatingElection: false});
            if (json) {
                this.props.refreshList();
                this.props.history.push("/elections/view/" + json.id);

            }
        })
  };

  readElection = () => {
    this.setState({isReadingElection: true});
    fetch(APIService.serverUrl + '/api/private/election/' + this.electionId(), {
      methos: 'GET',
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState({isReadingElection: false});
        if (json) {
          this.setState({
            electionName: json.name,
            electionStart: moment(json.startAt),
            electionEnd: moment(json.endAt),
          })
        }
      })
  };

  onElectionStartAt = (date) => {
      this.setState( {electionStart: moment(date)})
  };

  onElectionEndAt = (date) => {
      this.setState( {electionEnd: moment(date)})
  }

  render = () => {
      return (
          <div>
              <Form loading={this.state.isReadingElection}>
                <Header dividing icon='add' content={this.isEdit() ? 'Edit Election' : 'Add Election'} color='blue'/>
                <Form.Field>
                    <label>
                        Election Name:&nbsp;
                        <Icon
                          name='asterisk'
                          size='small'
                          color='blue'
                        />
                    </label>
                    <Input
                        id='electionName'
                        name='electionName'
                        disabled={this.state.isUpdatingElection}
                        value={this.state.electionName}
                        onChange={(event) => {
                            this.setState( {electionName: event.target.value})
                        }}
                    />
                </Form.Field>

                <Form.Group>
                  <Form.Field >
                      <label>Start:&nbsp;
                          <Icon
                            name='asterisk'
                            size='small'
                            color='blue'
                          />
                      </label>
                      <DatePicker
                          name='electionStart'
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          selected={this.state.electionStart.toDate()}
                          onChange={this.onElectionStartAt}
                      />
                  </Form.Field>
                  <Form.Field >
                      <label>End:&nbsp;
                          <Icon
                            name='asterisk'
                            size='small'
                            color='blue'
                          />
                      </label>
                      <DatePicker
                          name='eventEnd'
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          selected={this.state.electionEnd.toDate()}
                          onChange={this.onElectionEndAt}
                      />
                  </Form.Field>
                </Form.Group>

                <Button
                    loading={this.state.isUpdatingElection}
                    disabled={this.state.isUpdatingElection}
                    size='mini'
                    color='green'
                    onClick={this.updateElection}
                >
                    <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                    {this.isEdit() ? 'Edit Election' : 'Add Election'}
                </Button>
              </Form>
          </div>
      )
  };
};

export default withRouter(EditElection);
