import React, {Component} from "react";
import {withRouter} from "react-router";
import ViewTeamList from "../Views/ViewTeamList";
import {Route, Switch} from "react-router-dom";
import EditTeam from "../Editors/EditTeam";
import APIService from "../API";



class TeamController extends Component {

    state = {
        teams: [],
        isFetchingSchools: false,
    };

    componentDidMount() {
        this.getTeamList();
    };

    getTeamList = () => {
        this.setState( {isFetchingSchools: true});
        fetch( APIService.serverUrl + "/api/private/teams", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState({teams: json})
                }
                this.setState({isFetchingSchools: false})
            })
    };

    render = () => {

        let relativePath = this.props.match.path;

        return (
            <div>
                <Switch>
                    <Route
                        path={relativePath}
                        exact
                        render={() =>
                            <ViewTeamList
                                {...this.state}
                                refreshList={this.getTeamList}
                                teams={this.state.teams}
                            />}
                    />
                    <Route
                        path={relativePath + "/add"}
                        render={() =>
                            <EditTeam
                                refreshList={this.getTeamList}
                            />}
                    />
                    <Route
                        path={relativePath + "/edit/:teamId"}
                        render={() =>
                            <EditTeam
                                refreshList={this.getTeamList}
                            />}
                    />
                </Switch>

            </div>
        )
    };
};

export default withRouter(TeamController);