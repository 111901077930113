import React, { Component } from 'react';
import {
  Table,
  Button,
  Dimmer,
  Loader,
  Label,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { withRouter } from "react-router";


class ListSnowEmergencies extends Component {

  startNewCounty = () => {
    this.props.history.push('/snow-emergency/add');
  }

  startEditCounty = (county) => {
    this.props.history.push('/snow-emergency/edit/'+county.id);
  };

  render() {

    return (

      <div>
        <Dimmer active={this.props.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>
        <Segment
          textAlign='right'
          size='mini'
          color={this.props.stormMode ? 'red': 'blue' }
          style={{
            background:this.props.stormMode ? 'pink': '#F9FAFB',
          }}>
          <Button
            toggle
            size='mini'
            color={this.props.stormMode ? 'red': 'blue' }
            className={this.props.stormMode ? 'storm-mode' : null}
            checked={this.props.stormMode}
            onClick={this.props.onStormModeChange} >
            <Icon name='power' color={this.props.stormMode ? 'yellow' : 'black'} />
            <label>{this.props.stormMode ? 'Turn Storm Mode Off ' : 'Turn Storm Mode On'}</label>
          </Button>

        </Segment>

        <Table
          celled
          striped
          color={this.props.stormMode ? 'red': 'blue' }
          selectable
          compact='very'
          size='small'
        >
          <Table.Header fullWidth >
            <Table.Row >
              <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='12' >
                <span style={{ fontSize: "18px" }}>
                  Click The Rows Below To Edit Snow Emergency Counties.
                </span>
                <Button color="blue" onClick={this.startNewCounty} floated='right' size='mini' >
                  New Snow Emergency County
                </Button>
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell textAlign='center' collapsing>
                <strong>ID</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>County</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Level</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.snowEmergencyCounties.map( (snowEmergencyCounty) => {
              return(
                <Table.Row
                  key={snowEmergencyCounty.id.toString()}
                  id={snowEmergencyCounty.id.toString()}
                  style={{cursor:'pointer'}}
                  negative={snowEmergencyCounty.snowEmergencyMode.name !== "None"}
                  onClick={ () => {this.startEditCounty(snowEmergencyCounty)} }
                >
                  <Table.Cell name='id' textAlign='center' collapsing>
                    <Label circular size='tiny' content={snowEmergencyCounty.id} />
                  </Table.Cell>
                  <Table.Cell name='countyName' >
                    {snowEmergencyCounty.name}
                  </Table.Cell>
                  <Table.Cell name='level'>
                    {snowEmergencyCounty.snowEmergencyMode.name}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </div>

    );
  }
}

export default withRouter(ListSnowEmergencies)

/*



*/
