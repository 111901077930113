import React, { Component } from "react"
import { Route } from "react-router-dom";
import ViewArticleList from "../Views/ViewArticleList.js";
import EditArticle from "../Editors/EditArticle.js";
import AddArticle from "../Editors/AddArticle.js";
import APIService from "../API";


class ArticleController extends Component {

  state = {
    articleList: [],
    filterBody: {},
    page: 1,
    pageSize: 30,
    lastPage: 1,
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getArticleList();
  }

  getArticleList = (filterBody = this.state.filterBody, page = this.state.page, pageSize = this.state.pageSize) => {
    this.setState({
      filterBody: filterBody,
      page: page, pageSize: pageSize
    })
    fetch(APIService.serverUrl + '/api/private/articles', {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        ...filterBody,
        page: page,
        pageSize: pageSize,
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("ArticleList Fetch Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("ArticleList Fetch Succeeded")
        // console.log(json);
        this.setState({
          articleList: json.results,
          lastPage: json.lastPage,
          componentIsLoading: false,
        })
      } else {
        console.log("ArticleList Fetch Failed")
      }
    })
  }

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <div>
        <Route
          path={relativePath}
          exact
          render={() => <ViewArticleList {...this.state} refreshList={this.getArticleList} />} />
        <Route
          path={relativePath + "/edit/:articleId"}
          render={() => <EditArticle refreshList={this.getArticleList} user={this.props.user} />} />
        <Route
          path={relativePath + "/add"}
          render={() => <AddArticle refreshList={this.getArticleList} user={this.props.user} />} />
      </div>
    )
  }




}

export default ArticleController
