import React, {Component} from "react";
import {withRouter} from "react-router";
import {Table, Icon, Button, Dimmer, Loader} from 'semantic-ui-react';

class ViewPartyList extends Component {

  startAddParty = () => {
    this.props.history.push('/parties/add');
  };

  startEditParty = (party) => {
    this.props.history.push('/parties/edit/' + party.id)
  };

  render = () => {
    return (
        <div>
          <Dimmer page>
              <Loader size='massive'>Loading</Loader>
          </Dimmer>

          <Table
              celled
              striped
              color="blue"
              selectable
              compact='very'
              size='small'
              className='small-table'
          >
              <Table.Header fullWidth >
                  <Table.Row >
                      <Table.HeaderCell textAlign="center" colSpan='2'>
                          <span style={{ fontSize: "18px" }}>
                              Click The Rows Below To Edit Active State Or Name.
                          </span>
                          <Button
                              color="blue"
                              onClick={this.startAddParty}
                              floated='right'
                              size='mini'
                          >
                              Add Party
                          </Button>
                      </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                      <Table.HeaderCell textAlign="center" collapsing>
                          <strong>Id</strong>
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                          <strong>Name</strong>
                      </Table.HeaderCell>
                  </Table.Row>
              </Table.Header>
              <Table.Body>
                  {this.props.parties.map( (party) => {
                      return(
                          <Table.Row
                              key={party.id.toString()}
                              id={party.id.toString()}
                              name={party.name}
                              style={{cursor:'pointer'}}
                              onClick={ (e) => {this.startEditParty(party)} }
                          >
                              <Table.Cell>{party.id}</Table.Cell>
                              <Table.Cell>{party.name}</Table.Cell>
                          </Table.Row>
                      )
                  })}
              </Table.Body>
          </Table>
        </div>
    );
  };
};

export default withRouter(ViewPartyList);
