import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Dimmer, Dropdown, Icon, Label, Loader, Table, Modal, Form} from "semantic-ui-react";
import moment from "moment";
import Helpers from "../Helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";




class ViewGameList extends Component {

    startAddGame = () => {
        this.props.history.push('/games/add');
    };

    startEditGame = (game) => {
        this.props.history.push('/games/edit/' + game.id)  ;
    };

    render = () => {
        return (
            <div>

                <Dimmer
                    // active={this.props.componentIsLoading}
                    page
                >
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>

                <Table
                    celled
                    striped
                    color="blue"
                    selectable
                    compact='very'
                    size='small'
                >
                    <Table.Header fullWidth >
                        <Table.Row >
                            <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='6' >
                                <span style={{ fontSize: "18px" }}>Click The Rows Below To Edit Games.</span>
                                <Button
                                  color='blue'
                                  onClick={this.props.openTVDateModal}
                                  floated='right'
                                  style={{ padding: '5px 13px', marginLeft: '10px'}}
                                >
                                  <Icon name='tv' size='large' style={{marginRight: '0px'}}/>
                                </Button>
                                <Button
                                    color="blue"
                                    onClick={this.startAddGame}
                                    floated='right'
                                    size='mini'
                                >
                                    Add Game
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center" collapsing>
                                <strong>Id</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <strong>Game</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                <strong>Score</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <strong>Date</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <strong>Status</strong>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <strong>Season</strong>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {this.props.games.map( (game) => {
                            return(
                                <Table.Row
                                    key={game.id.toString()}
                                    id={game.id.toString()}
                                    name={game.name}
                                    style={{cursor: 'pointer'}}
                                    onClick={(e) => {this.startEditGame(game)}}
                                >
                                    <Table.Cell>{game.id}</Table.Cell>
                                    <Table.Cell>{game.hasCustomName ? game.name : Helpers.generateGameNameFromGame(game)}</Table.Cell>
                                    <Table.Cell textAlign="center">{game.secondarySchoolScore} - {game.primarySchoolScore}</Table.Cell>
                                    <Table.Cell>{moment(game.eventStartAt).format('MMM D, YYYY')}</Table.Cell>
                                    <Table.Cell>{game.gameStatus.name}</Table.Cell>
                                    <Table.Cell>{game.season.name}</Table.Cell>
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>

                <Modal
                  size='tiny'
                  open={this.props.isModalOpen}
                >
                  <Modal.Header>Select a Game Date to Display on TV:</Modal.Header>
                  <Modal.Content>
                    <Form.Field>
                      <label>Date Played: </label>
                      <DatePicker
                          name='sportTVDate'
                          dateFormat='MM/dd/yyyy'
                          selected={this.props.sportsTVDate.toDate()}
                          onChange={this.props.onSelectTVDate}
                      />
                    </Form.Field>
                    <div style={{marginTop: '10px'}}>
                      <Button
                        color='red'
                        size='tiny'
                        onClick={this.props.closeTVDateModal}
                      >
                        <Icon name='close'/>
                        Close
                      </Button>
                      <Button
                        color='blue'
                        size='tiny'
                        onClick={this.props.updateSportsTVDate}
                      >
                        <Icon name='save'/>
                        Submit
                      </Button>
                    </div>
                  </Modal.Content>
                </Modal>
            </div>
        )
    };
};

export default withRouter(ViewGameList);
