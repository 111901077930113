import moment from 'moment';


export default class APIService {
  static local = "http://api.wmfd.solsys.local:8083";
  static testing = "http://api.wmfd.testing.solsys.local:8083";
  static staging = "https://wmfdapi.solsyshosting.com";
  static production = "https://api.wmfd.com";
  static serverUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  static token = localStorage.getItem('token');
  static expiresAt = localStorage.getItem('expiresAt');

  static bearerToken = () => {
    let now = new moment();
    let expiresAt = new moment(APIService.expiresAt);
    let hourLeft = expiresAt.subtract(1, 'hours');
    if ( now.isAfter(hourLeft) ) {
      fetch(APIService.serverUrl + '/api/private/reauth', {
        headers: {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + APIService.token,
        },
        method: 'POST',
      }).then( (response) => {
        if (response.ok) {
          return response.json();
        } else {
          APIService.unAuthenticate();
          console.log("reAuth Failed: ", response.status, response.statusText);
        }
      }).then( (json) => {
        if (json) {
          APIService.setToken(json.string, json.expiresAt);
          // console.log('response: ' + json);
        } else {
          console.log('Could not get JSON');
        }
      })
    }
    return 'Bearer ' + APIService.token;
  }

  static setToken = (token, expiresAt) => {
    localStorage.setItem('token', token);
    localStorage.setItem('expiresAt', expiresAt);
    APIService.token = token;
    APIService.expiresAt = expiresAt;
  }

  static authHeaders = () => ({
    "Content-Type": "application/json",
    "Authorization": APIService.bearerToken(),
  })

  static unAuthenticate = () => {} //this method is in APP.js

  static handleLogout = () => {
    APIService.setToken('expired', 'expired');
    APIService.unAuthenticate();
  }

}
