import React, {Component} from "react";
import {withRouter} from "react-router";
import {Table, Icon, Button, Dimmer, Loader} from 'semantic-ui-react';

class ViewElectionList extends Component {

  startAddElection = () => {
    this.props.history.push('/elections/add');
  };

  startEditElection = (election) => {
    this.props.history.push('/elections/view/' + election.id)
  };

  render = () => {
    return (
        <div>
          <Dimmer page>
              <Loader size='massive'>Loading</Loader>
          </Dimmer>

          <Table
              celled
              striped
              color="blue"
              selectable
              compact='very'
              size='small'
              className='small-table'
          >
              <Table.Header fullWidth >
                  <Table.Row >
                      <Table.HeaderCell textAlign="center" colSpan='2'>
                          <span style={{ fontSize: "18px" }}>
                              Click The Rows Below To Edit Active State Or Name.
                          </span>
                          <Button
                              color="blue"
                              onClick={this.startAddElection}
                              floated='right'
                              size='mini'
                          >
                              Add Election
                          </Button>
                      </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                      <Table.HeaderCell textAlign="center" collapsing>
                          <strong>Id</strong>
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                          <strong>Name</strong>
                      </Table.HeaderCell>
                  </Table.Row>
              </Table.Header>
              <Table.Body>
                  {this.props.elections.map( (election) => {
                      return(
                          <Table.Row
                              key={election.id.toString()}
                              id={election.id.toString()}
                              name={election.name}
                              style={{cursor:'pointer'}}
                              onClick={ (e) => {this.startEditElection(election)} }
                          >
                              <Table.Cell>{election.id}</Table.Cell>
                              <Table.Cell>{election.name}</Table.Cell>
                          </Table.Row>
                      )
                  })}
              </Table.Body>
          </Table>
        </div>
    );
  };
};

export default withRouter(ViewElectionList);
