import React, { Component } from 'react';
import {
  Icon,
  Button,
  Form,
  Header,
  Label,
} from 'semantic-ui-react'
import { withRouter } from "react-router";
import APIService from '../API.js';
import ResponsiveModal from '../ResponsiveModal';


class EditResource extends Component {

  static defaultState = {
    name: '',
    value: '',
    reference: '',
    unmodifiedResource: {},
  }

  state = EditResource.defaultState;

  componentDidMount() {
    this.getResource();
  }

  getResource = () => {
    if (this.props.match.params.resourceId !== undefined) { // I can add ID below.
      fetch(APIService.serverUrl + '/api/private/layoutResource/'+this.props.match.params.resourceId, {
        method: "GET",
        headers: APIService.authHeaders(),
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("getResource Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("getResource Request Succeeded!");
          // console.log(json);
          let tempResource = {
            id: json.id,
            name: json.name,
            value: json.value,
            reference: json.reference,
          }
          this.setState({
            ...tempResource,
            unmodifiedResource: {
              ...tempResource,
            },
            "componentIsLoading": false,
          })
        } else {
          console.log("getResource Fetch Failed")
        }
      })
    }
  }

  patchResource = () => {
    this.setState({ 'componentIsLoading': true, });
    //IF for makeing the FETCH Content.
    let apiMethod = '';
    let apiBody = {};
    if (this.state.id) {
      apiMethod = 'PATCH';
      apiBody = {
        id: this.state.id,
        value: this.state.value,
      }
    } else {
      apiMethod = 'POST';
      apiBody = {
        name: this.state.name,
        value: this.state.value,
        reference: this.state.reference,
      }
    }
    fetch(APIService.serverUrl + '/api/private/layoutResource', {
      method: apiMethod,
      headers: APIService.authHeaders(),
      body: JSON.stringify({ ...apiBody }),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("patchResource PATCH Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("patchResource PATCH Succeeded!");
        this.props.history.replace('/resources/edit/'+json.id);
        this.props.refreshList();
        this.getResource();
      } else {
        console.log("patchResource PATCH Failed")
      }
    })
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  isFormModified = () => {
    if (!this.state.id) { return true }
    for (var key in this.state.unmodifiedResource) {
      if (this.state.unmodifiedResource[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  }

  render() {

    let requiredFieldsComplete = (
      this.state.name !== '' &&
      this.state.reference !== ''
    );
    // console.log('requiredFieldsComplete: '+requiredFieldsComplete);
    // console.log('isFormModified: '+this.isFormModified());

    return (

      <ResponsiveModal modalSize='tiny' >
        <Form >
          <Header
            dividing
            icon={ this.state.id ? 'edit outline' : 'add'}
            content={ this.state.id ? 'Edit - Resource' : 'Add New Resource'}
            color='blue' />
          <Form.Field>
            <label>
              Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
            </label>
            <Form.Input
              id='name'
              name='name'
              disabled={this.state.id ? true : false}
              placeholder='Enter the Resource Name...'
              value={this.state.name}
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>
              Reference:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
            </label>
            <Form.Input
              id='reference'
              name='reference'
              disabled={this.state.id ? true : false}
              placeholder='EnterTheReference...'
              value={this.state.reference}
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Value:</label>
            <Form.TextArea
              id='value'
              name='value'
              placeholder='Enter the Value...'
              value={this.state.value}
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <Label size='tiny' >
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
              Denotes required field
            </Label>
          </Form.Field>
          <Form.Field>
            <Button
              size='mini'
              color={ this.isFormModified() ? 'red' : undefined }
              onClick={ () => this.props.history.push('/resources') } >
              <Icon name='cancel' /> { this.isFormModified() ? 'Cancel' : 'Close' }
            </Button>
            <Button
              size='mini'
              color='green'
              disabled={ !requiredFieldsComplete || !this.isFormModified() }
              onClick={ this.patchResource } >
              <Icon name='save outline' /> Save
            </Button>
          </Form.Field>
        </Form>
      </ResponsiveModal>
    );
  }
}

export default withRouter(EditResource);

  /*



  */
