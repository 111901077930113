import React, { Component } from "react"
import { Route } from "react-router-dom";
import PriorityTypes from "../Views/ViewPriorityTypes";
import EditPriorityType from "../Editors/EditPriorityType.js";
import Constants from '../Constants.js';
import APIService from "../API";

class PriorityTypeController extends Component {
  state = {
    priorityTypes: [],
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getPriorityTypes();
  }

  getPriorityTypes = () => {
    fetch(APIService.serverUrl + '/api/private/articlePriority?showInactive=true', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("articlePriority Fetch Failed: ", response.status, response.statusText)
          if (response.status === 401) { this.props.history.push('/') };
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("articlePriority Request Succeeded!");
          // console.log(json);
          this.setState({
            "priorityTypes": json,
            "componentIsLoading": false,
          })
        } else {
          console.log("articlePriority Fetch Failed")
        }
      })
  }

  render = () => {

    let isMobile = Constants.isMobile();
    let relativePath = this.props.match.path;

    return (
      <div>
        <Route
          path={relativePath}
          exact={isMobile}
          render={() => <PriorityTypes {...this.state}/>}
        />
        <Route
          path={relativePath + "/:addEdit(add|edit)/:priorityTypeId?"}
          render={() => <EditPriorityType refreshList={this.getPriorityTypes} />}
        />
      </div>
    )
  }
}

export default PriorityTypeController
