import React, { Component } from 'react';
import {
  Icon,
  Button,
  Form,
  Input,
  Dropdown,
  Checkbox,
  Header,
  Label,
} from 'semantic-ui-react'
import { withRouter } from "react-router";
import APIService from '../API.js';


class EditUser extends Component {

  static defaultProps = {
    onClose: () => {},
    refreshList: () => {},
  }

  static defaultState = {

    /* Default User State */
    // id: undefined,
    name: '',
    email: '',
    isActive: true,
    roles: [],
    agencies: [],
    articleTypes: [],
    /* Default User State */

    unmodifiedUser: {},

    allRoles: [],
    allAgencies: [],
    allArticleTypes: [],
    newPassword: '',
    confirmPassword: '',
    passwordError: false,
  }

  state = EditUser.defaultState;

  doesUserExist = () => this.props.match.params.userId !== undefined;

  componentDidMount() {
    this.getUser();
    this.getRoles();
    this.getAgencies();
    this.getArticleTypes();
  }

  getUser = () => {
    if (this.doesUserExist()) {
      fetch(APIService.serverUrl + '/api/private/user/'+this.props.match.params.userId, {
        method: "GET",
        headers: APIService.authHeaders(),
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("getUser Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("getUser Request Succeeded!");
          // console.log(json);
          this.startEditUser(json);
        } else {
          console.log("getUser Fetch Failed")
        }
      })
    }
  }

  getRoles = () => {
    fetch(APIService.serverUrl + '/api/private/userRole', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("getRoles Fetch Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("getRoles Request Succeeded!");
        // console.log(json);
        this.setState({
          "allRoles": json,
        });

        if (this.props.agencyId) {
          let roleId = undefined
          json.forEach(role => {
            if (role.name === "closer") {
              roleId = role.id;
            }
          })
          this.setState({
            ...(roleId ? {roles: [roleId]} : {})
          })
        }
      } else {
        console.log("getRoles Fetch Failed")
      }
    })
  }

  getAgencies = () => {
    fetch(APIService.serverUrl + '/api/private/agencies', {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({})
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("Agencies Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("getRoles Request Succeeded!");
          // console.log(json);
          this.setState({
            "allAgencies": json,
          });

          if (this.props.agencyId) {
            let roleId = undefined
            json.forEach(role => {
              if (role.name === "closer") {
                roleId = role.id;
              }
            })
            this.setState({
              ...(roleId ? {roles: [roleId]} : {})
            })
          }
        } else {
          console.log("getRoles Fetch Failed")
        }
      })
  }

  getArticleTypes = () => {
    fetch(APIService.serverUrl + '/api/private/articleType', {
      method: "GET",
      headers: APIService.authHeaders()
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("Article Types Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          this.setState({
            "allArticleTypes": json,
          });
        } else {
          console.log("getRoles Fetch Failed")
        }
      })
  }

  patchUser = () => {
    this.setState({ 'componentIsLoading': true, });
    let passwordVar = (this.state.newPassword === '') ? null : { password: this.state.newPassword }
    fetch(APIService.serverUrl + '/api/private/user' + (this.doesUserExist() ? '/'+this.state.id : ''), {
      method: this.doesUserExist() ? 'PATCH' : 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        id: this.state.id,
        name: this.state.name,
        email: this.state.email,
        isActive: this.state.isActive,
        roles: this.state.roles,
        ...passwordVar,
        agencyIds: this.props.agencyId ? [this.props.agencyId] : this.state.agencies,
        articleTypeIds: this.state.articleTypes,
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("patchUser PATCH Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("patchUser PATCH Succeeded!");
        this.setState({
          newPassword: '',
          confirmPassword: '',
        });
        this.props.refreshList();
        if (this.doesUserExist()) {
          this.getUser();
        } else if (this.props.agencyId) {
          this.props.onClose()
        } else {
          this.props.history.replace('/users/edit/'+json.id);
          this.getUser();
        }
      } else {
        console.log("patchUser PATCH Failed")
      }
    })
  }

  startEditUser = (user) => {
    let apiUserData = {
      id: user.id,
      name: user.name,
      email: user.email,
      isActive: user.isActive,
      roles: user.roles.map(role => {
        if (role.name === "closer") {
          this.getAgencies();
        }
        return role.id
      }),
      agencies: user.agencies.map(agency => agency.id),
      articleTypes: user.articleTypes.map(articleType => {
        if (articleType.name === "publisher") {
          this.getArticleTypes();
        }
        return articleType.id
      })
    }
    this.setState({
      ...apiUserData,
      unmodifiedUser: {
        ...apiUserData,
        newPassword: '',
        confirmPassword: '',
      }
    });
  }

  onToggleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  onSelectChange = (e, data) => {
    console.log("data: ", data);
    this.setState({ [data.name]: data.value });
  }

  newPasswordChange = (e) => {
    if (e.target.value !== this.state.confirmPassword) {
      this.setState({ passwordError: true, newPassword: e.target.value, });
    } else {
      this.setState({ passwordError: false, newPassword: e.target.value, });
    }
  }

  confirmPasswordChange = (e) => {
    if (e.target.value !== this.state.newPassword) {
      this.setState({ passwordError: true, confirmPassword: e.target.value, });
    } else {
      this.setState({ passwordError: false, confirmPassword: e.target.value, });
    }
  }

  isFormModified = () => {
    if (!this.doesUserExist()) { return true }
    for (var key in this.state.unmodifiedUser) {
      if (this.state.unmodifiedUser[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  }

  render() {

    let requiredFieldsComplete =
      this.state.name.length !== 0 &&
      this.state.email.length !== 0 &&
      this.state.roles.length !== 0 &&
      (this.state.passwordError === false ||
        ( this.doesUserExist() &&
          this.state.newPassword.length === 0 &&
          this.state.confirmPassword.length === 0
        )
      );

    let newUserVar = this.props.match.params.userId === undefined;

    return (
      <Form autoComplete='false' >
        <Header
          dividing
          color='blue'
          icon={ this.state.id ? 'edit outline' : 'add user'}
          content={ this.state.id ? 'Edit User' : 'New User'} />
        {this.props.agencyId ? null :
          <Form.Group inline >
            <label><strong>Active:</strong></label>
            <Checkbox
              id="isActive"
              name='isActive'
              checked={this.state.isActive}
              onChange={this.onToggleChange}
              toggle />
          </Form.Group>
        }
        <Form.Field>
          <label>
            Name:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Input
            id='name'
            name='name'
            autoComplete='false'
            placeholder='Enter the User Name...'
            value={this.state.name}
            onChange={this.onInputChange} />
        </Form.Field>
        <Form.Field>
          <label>
            E-Mail:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Input
            id='email'
            name='email'
            placeholder='Enter the User Email...'
            value={this.state.email}
            onChange={this.onInputChange} />
        </Form.Field>
        <Form.Field error={this.state.passwordError}>
          <label>
            New Password:&nbsp;
            { !newUserVar ? '' :
              <Icon
                name='asterisk'
                size='small'
                color='blue' /> }
          </label>
          <Input
            id='newPassword'
            name='newPassword'
            type='password'
            autoComplete='false'
            placeholder='Enter the New Password...'

            value={this.state.newPassword}
            onChange={this.newPasswordChange} />
        </Form.Field>
        <Form.Field error={this.state.passwordError}>
          <label>
            Confirm Password:&nbsp;
            { !newUserVar ? '' :
              <Icon
                name='asterisk'
                size='small'
                color='blue' /> }
          </label>
          <Input
            id='confirmPassword'
            name='confirmPassword'
            type='password'
            autoComplete='false'
            placeholder='Confirm the New Password...'

            value={this.state.confirmPassword}
            onChange={this.confirmPasswordChange} />
        </Form.Field>
        {this.props.agencyId ? null :
          <Form.Field>
            <label>
              Roles:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
            </label>
            <Dropdown
              id='roles'
              name='roles'
              placeholder='Choose User Role(s)...'
              fluid
              multiple
              selection
              options={this.state.allRoles.map(
                role => { return { key: role.id, text: role.name, value: role.id }
                })}
              value={this.state.roles}
              onChange={this.onSelectChange} />
          </Form.Field>
        }
        {this.props.agencyId ? null :
          <Form.Field>
            <label>
              Closer Agencies:&nbsp;
            </label>
            <Dropdown
              name='agencies'
              placeholder='Choose Agencies...'
              fluid
              multiple
              selection
              options={this.state.allAgencies.map(agency => ({
                key: agency.id,
                text: agency.name,
                value: agency.id
              }))}
              value={this.state.agencies}
              onChange={this.onSelectChange} />
          </Form.Field>
        }
        {this.props.agencyId ? null :
          <Form.Field>
            <label>
              Publisher Article Type Restrictions:&nbsp;
            </label>
            <Dropdown
              name='articleTypes'
              placeholder='Choose Article Types...'
              fluid
              multiple
              selection
              options={this.state.allArticleTypes.map(articleType => ({
                key: articleType.id,
                text: articleType.name,
                value: articleType.id
              }))}
              value={this.state.articleTypes}
              onChange={this.onSelectChange} />
          </Form.Field>
        }
        <Form.Field>
          <Label size='tiny' >
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
            Denotes required field
          </Label>
        </Form.Field>
        <Form.Field>
          <Button
            size='mini'
            color={ this.isFormModified() ? 'red' : undefined }
            onClick={ () => this.props.onClose() } >
            <Icon name='cancel' /> { this.isFormModified() ? 'Cancel' : 'Close' }
          </Button>
          <Button
            size='mini'
            color='green'
            onClick={() => this.patchUser()}
            disabled={ !requiredFieldsComplete || !this.isFormModified() } >
            <Icon name='save outline' /> Save
          </Button>
        </Form.Field>
      </Form>
    );
  }
} export default withRouter(EditUser);

/*



*/
