import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input} from "semantic-ui-react";
import APIService from "../API";


class EditElectionCounty extends Component {

  state = {
    electionCountyName: null,
    isUpdatingElectionCounty: false,
    isReadingElectionCounty: false,
  };

  componentDidMount() {
    if(this.isEdit()) {
        this.readElectionCounty();
    };
  };

  isEdit = () => this.props.match.params.electionCountyId !== undefined;

  electionCountyId = () => this.props.match.params.electionCountyId;

  updateElectionCounty = () => {
    this.setState({isUpdatingElectionCounty: true});
    fetch(APIService.serverUrl + "/api/private/election-county" + (this.isEdit() ? "/" + this.electionCountyId() : ""), {
        method: this.isEdit() ? "PATCH" : "POST",
        headers: APIService.authHeaders(),
        body: JSON.stringify({
            name: this.state.electionCountyName,
        }),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return null;
            }
        })
        .then((json) => {
            this.setState({isUpdatingElectionCounty: false});
            if (json) {
                this.props.refreshList();
                this.props.history.push("/election-counties");
            }
        })
  };

  readElectionCounty = () => {
    this.setState({isReadingElectionCounty: true});
    fetch(APIService.serverUrl + '/api/private/election-county/' + this.electionCountyId(), {
      method: 'GET',
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState({isReadingElectionCounty: false});
        if (json) {
          this.setState({electionCountyName: json.name})
        }
      })
  };

  render = () => {
      return (
          <div>
              <Form loading={this.state.isReadingElectionCounty}>
                <Header dividing icon='add' content={this.isEdit() ? 'Edit Election County' : 'Add Election County'} color='blue'/>
                <Form.Field>
                    <label>
                        Election County Name:
                    </label>
                    <Input
                        id='electionCountyName'
                        name='electionCountyName'
                        disabled={this.state.isUpdatingElectionCounty}
                        value={this.state.electionCountyName}
                        onChange={(event) => {
                            this.setState( {electionCountyName: event.target.value})
                        }}
                    />
                </Form.Field>
                <Button
                    loading={this.state.isUpdatingElectionCounty}
                    disabled={this.state.isUpdatingElectionCounty}
                    size='mini'
                    color='green'
                    onClick={this.updateElectionCounty}
                >
                    <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                    {this.isEdit() ? 'Edit Election County' : 'Add Election County'}
                </Button>
              </Form>
          </div>
      )
  };
};

export default withRouter(EditElectionCounty);
