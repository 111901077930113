import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input, Dropdown, Grid, Image, Checkbox} from "semantic-ui-react";
import Helpers from "../Helpers";
import APIService from "../API";


class EditGameStatus extends Component {

    state = {
        statusName: "",
        sports: [],
        sportId: "",
        selectedSports: [],
        isGlobal: false,
        isFetchingSports: false,
        isReadingGameStatus: false,
        isUpdatingGameStatus: false,
    };

    componentDidMount() {
        if(this.isEdit()) {
            this.readGameStatus();
        };
        this.getSportList();
    }

    isEdit = () => this.props.match.params.statusId !== undefined;

    statusId = () => this.props.match.params.statusId;


    updateGameStatus = () => {
        this.setState({isUpdatingGameStatus: true});
        fetch(APIService.serverUrl + "/api/private/game-status" + (this.isEdit() ? "/" + this.statusId() : ""), {
            method: this.isEdit() ? "PATCH" : "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({
                name: this.state.statusName,
                isGlobal: this.state.isGlobal,
                sportIds: this.state.selectedSports,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState({isUpdatingGameStatus: false});
                if (json) {
                    this.props.refreshList();
                    this.props.history.push("/game-statuses");
                }
            })
    };

    readGameStatus = () => {
        this.setState({isReadingGameStatus: true});
        fetch( APIService.serverUrl + '/api/private/game-status/' + this.statusId(), {
            method: "GET",
            headers: APIService.authHeaders(),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState( { isReadingGameStatus: false});
                if (json) {
                    this.setState( {
                        statusName: json.name,
                        isGlobal: json.isGlobal,
                        selectedSports: json.sports.map(sport => sport.id),
                    })
                }
            })
    };

    getSportList = () => {
        this.setState( {isFetchingSports: true});
        fetch( APIService.serverUrl + "/api/private/sports", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState({sports: json})
                }
                this.setState({isFetchingSports: false})
            })
    };


    render = () => {
        // let selectedSports = [];
        // this.state.selectedSports.map(())
        return (
            <div>
                <Form loading={this.state.isReadingGameStatus}>
                    <Header dividing icon='add' content={this.isEdit() ? 'Edit Status' : 'Add Status'} color='blue'/>

                    <Form.Field>
                        <label>
                            Status Name:
                        </label>
                        <Input
                            id='statusName'
                            name='statusName'
                            disabled={this.state.isUpdatingGameStatus}
                            value={this.state.statusName}
                            onChange={(event) => {
                                this.setState( {statusName: event.target.value})
                            }}
                        />
                    </Form.Field>

                    <Form.Group>

                        <Form.Field width={4}>
                            <label>Global: </label>
                            <Checkbox
                                toggle
                                id='isGlobal'
                                name='isGlobal'
                                checked={this.state.isGlobal}
                                onChange={() => {
                                    this.setState({ "isGlobal": !this.state.isGlobal })
                                }}
                            />
                        </Form.Field>
                        <Form.Field width={12}>
                            <label>Sport:</label>
                            <Dropdown
                                disabled={this.state.isGlobal}
                                loading={this.state.isFetchingSports}
                                fluid
                                multiple
                                selection
                                placeholder=''
                                value={this.state.selectedSports}
                                options={this.state.sports.map( (sport) => ({
                                    key: sport.id,
                                    text: sport.name,
                                    value: sport.id,
                                }))}
                                onChange={(event, data) => {
                                    this.setState({
                                        selectedSports: data.value,
                                    });
                                }}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Button
                        loading={this.state.isUpdatingGameStatus}
                        disabled={this.state.isUpdatingGameStatus}
                        size='mini'
                        color='green'
                        onClick={this.updateGameStatus}
                    >
                        <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                        {this.isEdit() ? 'Edit Status' : 'Add Status'}
                    </Button>
                </Form>
            </div>
        )
    };
};

export default withRouter(EditGameStatus);
