import React, { Component } from 'react';
import { Menu, Dropdown, Icon, Responsive, Button } from 'semantic-ui-react'
import APIService from './API.js';
import { withRouter } from "react-router";
import Constants from './Constants.js';


class HeaderMenu extends Component {

  state = {
    // ...
  }

  componentDidMount() {
    // ...
  }

  onMenuItemClick = (e, { name }) => {
    switch (name) {
      case "articleList": this.props.history.push('/articles'); break
      case "articleReports": this.props.history.push('/article-reports'); break
      case "articleTypes": this.props.history.push('/article-types'); break
      case "priorityTypes": this.props.history.push('/priority-types'); break
      case "videoTypes": this.props.history.push('/video-types'); break
      case "closings": this.props.history.push('/closings'); break
      case "closingsRadio": this.props.history.push('/closings/radio'); break
      case "closingsHistory": this.props.history.push('/closings/history'); break
      case "agencyList": this.props.history.push('/agencies'); break
      case "snowEmergency": this.props.history.push('/snow-emergency'); break
      case "resourceList": this.props.history.push('/resources'); break
      case "contests": this.props.history.push('/contests'); break
      case "userList": this.props.history.push('/users'); break
      case "gamesList": this.props.history.push('/games'); break
      case "gameStatusList": this.props.history.push('/game-statuses'); break
      case "schoolList": this.props.history.push('/schools'); break
      case "teamList": this.props.history.push('/teams'); break
      case "sportList": this.props.history.push('/sports'); break
      case "seasonList": this.props.history.push('/seasons'); break
      case "electionList": this.props.history.push('/elections'); break
      case "partyList": this.props.history.push('/parties'); break
      case "electionCountyList": this.props.history.push('/election-counties'); break
      case "promotionalRestaurantList": this.props.history.push('/promotional-restaurants'); break
      default: this.props.history.push('/');
    };
  }

  onLogout = () => {
    this.props.history.push('/');
    console.log('logged out');
    APIService.handleLogout();
  }


  render() {

    let userRoles = this.props.user.roles

    let activeMenuItem = '';
    let pageName = '';
    let iconName = '';
    let iLoading = false;

    switch(this.props.location.pathname) {
      case "/articles":
        activeMenuItem = 'articleList'; pageName = 'Articles'; iconName = 'newspaper outline'; break
      case "/article-reports":
        activeMenuItem = 'articleReports'; pageName = 'Article Reports'; iconName = 'line graph'; break
      case "/article-types":
        activeMenuItem = 'articleTypes'; pageName = 'Article Types'; iconName = 'tasks'; break
      case "/priority-types":
        activeMenuItem = 'priorityTypes'; pageName = 'Priority Types'; iconName = 'tasks'; break
      case "/video-types":
        activeMenuItem = 'videoTypes'; pageName = 'Video Types'; iconName = 'tasks'; break
      case "/closings":
        activeMenuItem = 'closings'; pageName = 'Closings'; iconName = 'calendar times outline'; break
      case "/closings/radio":
        activeMenuItem = 'closingsRadio'; pageName = 'Closings Radio'; iconName = 'calendar times outline'; break
      case "/closings/history":
        activeMenuItem = 'closingsHistory'; pageName = 'Closings History'; iconName = 'calendar times outline'; break
      case "/agencies":
        activeMenuItem = 'agencyList'; pageName = 'Agencies'; iconName = 'building outline'; break
      case "/snow-emergency":
        activeMenuItem = 'snowEmergency'; pageName = 'Snow Emergencies'; iconName = 'warning sign'; break
      case "/resources":
        activeMenuItem = 'resourceList'; pageName = 'Layout Resources'; iconName = 'globe'; break
      case "/contests":
        activeMenuItem = 'contests'; pageName = 'Contests'; iconName = 'trophy'; break
      case "/users":
        activeMenuItem = 'userList'; pageName = 'Users'; iconName = 'users'; break
      case "/games":
        activeMenuItem = 'gamesList'; pageName = 'Games'; iconName = 'ticket alternate'; break
      case "/game-statuses":
        activeMenuItem = 'gameStatusList'; pageName = 'Game Statuses'; iconName= 'clock outline'; break
      case "/schools":
        activeMenuItem = 'schoolList'; pageName = 'Schools'; iconName = 'university'; break
      case "/teams":
        activeMenuItem = 'teamList'; pageName = 'Teams'; iconName =  'list ul'; break
      case "/sports":
        activeMenuItem = 'sportList'; pageName = 'Sports'; iconName = 'football ball'; break
      case "/seasons":
          activeMenuItem = 'seasonList'; pageName = 'Seasons'; iconName = 'calendar alternate outline'; break
      case "/elections":
          activeMenuItem = 'electionList'; pageName = 'Elections'; iconName = 'book'; break
      case "/parties":
          activeMenuItem = 'partyList'; pageName = 'Parties'; iconName = 'address card outline'; break
      case "/election-counties":
        activeMenuItem = 'electionCountyList'; pageName = 'Election Counties'; iconName = 'map marker alternate'; break
      case "/promotional-restaurants":
        activeMenuItem = 'promotionalRestaurantList'; pageName = 'Promotional Restaurants'; iconName = 'food'; break
      default:
          break
    };


    return (

      <Menu
          className='appMenu'
          pointing
          fixed='top'
          inverted
          borderless
          compact
          color={this.props.stormMode ? 'red' : 'blue'}
          key="blue"
      >
        <div className='navDiv'>

          <div className='navContainer'>
            <Button
                // inverted
                color={this.props.stormMode ? 'red' : 'blue'}
                content='WMFD Prototype'
                icon='angle down'
                onClick={this.props.openNavMenu}
            />
            <Menu.Item header name='pageName'>
              <Icon loading={iLoading} name={iconName} />
              {pageName}
            </Menu.Item>
          </div>

          <div className='signOutDiv'>
            <Button
              color={this.props.stormMode ? 'red' : 'blue'}
              icon='log out'
              content='Logout'
              onClick={this.onLogout}
            />
          </div>

        </div>

      </Menu>



    )
  }
}

export default withRouter(HeaderMenu);
