import React, {Component} from 'react'
import {Button, Card, Header, List, Segment} from "semantic-ui-react";
import {withRouter} from "react-router-dom";

class ListIssues extends Component {

  cardDescriptionLayout = (issue) => {
    if(issue.stateWide === false && issue.totalVoteCount === false) {
      return (
        <div>
          {issue.voteCounts.map((count) => {
            return (
              <div>
                <h5 style={{fontWeight: 'normal', margin: '5px', textDecoration: 'underline'}}>
                  {count.electionCounty.name + " County: "}
                </h5>
                <h5 style={{fontWeight: 'normal', margin: '5px 14px'}}>
                  {"For - " + count.forCount}
                </h5>
                <h5 style={{fontWeight: 'normal', margin: '5px 14px'}}>
                  {"Against - " + count.againstCount}
                </h5>
              </div>
            )
          })}
        </div>
      )
    } else if(issue.stateWide === false && issue.totalVoteCount === true) {
        return (
          <div>
            <h5 style={{fontWeight: 'normal', margin: '5px'}}>
              {"For Votes: " + issue.forCount}
            </h5>
            <h5 style={{fontWeight: 'normal', margin: '5px'}}>
              {"Against Votes: " + issue.againstCount}
            </h5>
          </div>
        )
    } else if(issue.stateWide === true && issue.totalVoteCount === true) {
        return (
          <div>
            <h5 style={{fontWeight: 'normal', margin: '5px'}}>
              {"For Votes: " + issue.forCount}
            </h5>
            <h5 style={{fontWeight: 'normal', margin: '5px'}}>
              {"Against Votes: " + issue.againstCount}
            </h5>
          </div>
        )
    } else {
        return (
          <div>
            <h5>Somthing seems to be wrong</h5>
          </div>
        )
    }
  }

  render = () => {
    const {issues} = this.props
    return (
      <Segment basic style={{}} className="segmentContent">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
          <Header style={{marginTop: '5px', textDecoration: 'underline'}}>
            Issues
          </Header>
          <div>
            {/*<Button
              content="Edit All Issues"
              size="mini"
              color="green"
              style={{marginBottom: '14px', marginRight: '15px'}}
            />*/}
            <Button
              content='Add Issue'
              icon='plus'
              size='mini'
              color="blue"
              style={{marginBottom: '14px'}}
              onClick={() => this.props.history.push("/issues/add/" + this.props.electionId)}
            />
          </div>
        </div>

        <div>
          {issues.map((issue) => {
            return (
              <Card
                style={{padding: '0px', textAlign: 'left', width: '100%'}}
                onClick={() => {
                  this.props.history.push("/issues/edit/" + issue.id)
                }}
              >
                <Card.Content>
                  <Card.Header >{issue.name}</Card.Header>
                  <Card.Description>
                    <h6 style={{margin: '0px', marginTop: '-5px', color: 'rgb(33, 133, 208)'}}>{issue.stateWide ? "**State wide issue**" : null}</h6>
                    {this.cardDescriptionLayout(issue)}
                  </Card.Description>
                </Card.Content>
              </Card>
            )
          })}
        </div>
      </Segment>
    )
  }
}

export default withRouter(ListIssues)
