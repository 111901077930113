import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input, Grid, Image, Checkbox, Dropdown, Label} from "semantic-ui-react";
import APIService from "../API";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';


class EditSeason extends Component {

  state = {
    seasonName: null,
    seasonStartAt: new moment,
    seasonEndAt: new moment,
    publicLiveStart: new moment,
    publicLiveEnd: new moment,
    sportList: [],
    sportId: null,
    isFetchingSeason: false,
    isFetchingSports: false,
    isReadingSeason: false,
    isUpdatingSeason: false,
  };

  componentDidMount() {
    if(this.isEdit()) {
        this.readSeason();
    }
    this.getSportList();
  };

  isEdit = () => this.props.match.params.seasonId !== undefined;

  seasonId = () => this.props.match.params.seasonId;

  updateSeason = () => {
    this.setState({isUpdatingSeason: true});
    fetch(APIService.serverUrl + "/api/private/season" + (this.isEdit() ? "/" + this.seasonId() : ""), {
        method: this.isEdit() ? "PATCH" : "POST",
        headers: APIService.authHeaders(),
        body: JSON.stringify({
            name: this.state.seasonName,
            sportId: this.state.sportId,
            startAt: this.state.seasonStartAt.toISOString(),
            endAt: this.state.seasonEndAt.toISOString(),
            publicLiveStart: this.state.publicLiveStart.toISOString(),
            publicLiveEnd: this.state.publicLiveEnd.toISOString(),
        })
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return null;
            }
        })
        .then((json) => {
            this.setState({isUpdatingSeason: false});
            if (json) {
                this.props.refreshList();
                this.props.history.push("/seasons");
            }
        })
  };

  readSeason = () => {
    this.setState({isReadingSeason: true});
    fetch( APIService.serverUrl + "/api/private/season/" + this.seasonId(), {
        method: "GET",
        headers: APIService.authHeaders(),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return null;
            }
        })
        .then((json) => {
            this.setState( { isReadingSeason: false});
            if (json) {
                this.setState( {
                    seasonName: json.name,
                    sportId: json.sport.id,
                    seasonStartAt: moment(json.startAt),
                    seasonEndAt: moment(json.endAt),
                    publicLiveStart: moment(json.publicLiveStart),
                    publicLiveEnd: moment(json.publicLiveEnd),
                })
            }
        })
  };

  getSportList = () => {
      this.setState({isFetchingSports: true});
      fetch(APIService.serverUrl + "/api/private/sports", {
          method: "POST",
          headers: APIService.authHeaders(),
          body: JSON.stringify({}),
      })
          .then((response) => {
              if(response.ok) {
                  return response.json();
              } else {
                  return null;
              }
          })
          .then( (json) => {
              if (json) {
                  this.setState({sportList: json})
              }
              this.setState({isFetchingSports: false})
          })
  };

  render = () => {

    return (
      <Form loading={this.state.isFetchingSeason}>
        <Header
          dividing
          icon='add'
          color='blue'
          content={this.isEdit() ? 'Edit Season' : 'Add Season'}
        />

        <Form.Group widths='equal'>
          <Form.Field>
            <label>Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Input
                id='seasonName'
                name='seasonName'
                value={this.state.seasonName}
                onChange={(event) => {
                    this.setState( {seasonName: event.target.value})
                }}
            />
          </Form.Field>
          <Form.Field >
              <label>Season Start:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
              </label>
              <DatePicker
                  name='seasonStartAt'
                  dateFormat='MM/dd/yyyy'
                  selected={this.state.seasonStartAt.toDate()}
                  onChange={ (date) => {
                      this.setState( {seasonStartAt: moment(date)})
                      console.log(date)
                    }
                  }
              />
          </Form.Field>

        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Field>
              <label>Sport:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
              </label>
              <Dropdown
                  fluid
                  search
                  selection
                  placeholder='Select Sport'
                  value={this.state.sportId}
                  options={this.state.sportList.map( (sport) => ({
                      key: sport.id,
                      text: sport.name,
                      value: sport.id,
                  }))}
                  onChange={(event, data) => {
                      this.setState({
                          sportId: data.value,
                      });
                  }}
              />
          </Form.Field>
          <Form.Field >
              <label>Season End:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
              </label>
              <DatePicker
                  name='seasonEndAt'
                  dateFormat='MM/dd/yyyy'
                  selected={this.state.seasonEndAt.toDate()}
                  onChange={ (date) => {
                      this.setState( {seasonEndAt: moment(date)})
                      console.log(this.state.seasonEndAt)
                    }
                  }
              />
          </Form.Field>
        </Form.Group>

        <Form.Group style={{marginTop: '40px'}}>
          <Form.Field>
            <label>Select when this Season will display on the public site</label>
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field >
              <label>Public Live Start:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
              </label>
              <DatePicker
                  name='publicLiveStart'
                  dateFormat='MM/dd/yyyy'
                  selected={this.state.publicLiveStart.toDate()}
                  onChange={ (date) => {
                      this.setState( {publicLiveStart: moment(date)})
                      console.log(date)
                    }
                  }
              />
          </Form.Field>
          <Form.Field >
              <label>Public Live End:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
              </label>
              <DatePicker
                  name='publicLiveEnd'
                  dateFormat='MM/dd/yyyy'
                  selected={this.state.publicLiveEnd.toDate()}
                  onChange={ (date) => {
                      this.setState( {publicLiveEnd: moment(date)})
                      console.log(date)
                    }
                  }
              />
          </Form.Field>

        </Form.Group>

        <Form.Field>
          <Label size='tiny' >
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
            Denotes required field
          </Label>
        </Form.Field>
        <Button
            loading={this.state.isUpdatingSeason}
            disabled={this.state.isUpdatingSeason || this.state.isReadingSeason}
            size='mini'
            color='green'
            onClick={this.updateSeason}
        >
            <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
            {this.isEdit() ? 'Edit Season' : 'Add Season'}
        </Button>
      </Form>
    )
  };

};

export default withRouter(EditSeason);
