import React, { Component } from 'react';
import {
  Table,
  Button,
  Dimmer,
  Loader,
  Label,
} from 'semantic-ui-react';
import Truncate from 'react-truncate';
import { withRouter } from "react-router";


class ResourceList extends Component {

  startNewResource = () => {
    this.props.history.push('/resources/add');
  }

  startEditResource = (resource) => {
    this.props.history.push('/resources/edit/'+resource.id);
  };

  render() {

    return (

      <div>

        <Dimmer active={this.props.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Table celled striped color="blue" selectable compact='very' size='small' >
          <Table.Header fullWidth >
            <Table.Row >
              <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='12' >
                <span style={{ fontSize: "18px", verticalAlign:'middle' }}>Click The Rows Below To Edit Resources.</span>
                <Button color="blue" onClick={this.startNewResource} floated='right' size='mini' >New Resource</Button>
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell textAlign='center' collapsing>
                <strong>ID</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Name</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Reference</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Value</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.resourceList.map( (resource) => {
              return(
                <Table.Row
                  key={resource.id.toString()}
                  id={resource.id.toString()}
                  style={{cursor:'pointer'}}
                  onClick={ (e) => {this.startEditResource(resource)} }
                >
                  <Table.Cell name='id' textAlign='center' collapsing>
                    <Label circular size='tiny' content={resource.id} />
                  </Table.Cell>
                  <Table.Cell name='name' >
                    {resource.name}
                  </Table.Cell>
                  <Table.Cell name='reference' >
                    {resource.reference}
                  </Table.Cell>
                  <Table.Cell name='value' >
                    <Truncate lines={1} ellipsis='...'>
                      {resource.value}
                    </Truncate>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

      </div>

    );
  }
}

export default withRouter(ResourceList)

/*



*/
