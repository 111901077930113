import React, {Component} from "react"
import APIService from "../API";
import moment from "moment/moment";
import {Button, Checkbox, Form, Header, Icon, Input} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import EditVoteCount from "./EditVoteCount"

class EditIssue extends Component {
  state = {
    issue: {
      electionCounties: [],
    },
    name: "",
    forCount: 0,
    againstCount: 0,
    stateWide: false,
    totalVoteCount: false,
    electionId: null,
    electionCountyIds: [],
    voteCounts: [],

    electionCounties: [],

    isUpdatingIssue: false,
    isReadingIssue: false,
    isFetchingCounties: false,
  };

  componentDidMount() {
    if(this.isEdit()) {
      this.readIssue();
    };
    this.listElectionCounties();
  };

  isEdit = () => this.props.match.params.issueId !== undefined;

  issueId = () => this.props.match.params.issueId;
  electionId = () => this.props.match.params.electionId;

  listElectionCounties = () => {
    this.setState({isFetchingCounties: true});
    fetch(APIService.serverUrl + '/api/private/election-counties', {
      method: 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({}),
  })
      .then((response) => {
        if(response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        if (json) {
          this.setState({electionCounties: json})
        }
        this.setState({isFetchingCounties: false})
      })
  }

  updateIssue = () => {
    this.setState({isUpdatingIssue: true});
    fetch(APIService.serverUrl + "/api/private/issue" + (this.isEdit() ? "/" + this.issueId() : ""), {
      method: this.isEdit() ? "PATCH" : "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        name: this.state.name,
        forCount: this.state.totalVoteCount ? Number(this.state.forCount) : 0,
        againstCount: this.state.againstCount ? Number(this.state.againstCount) : 0,
        electionId: this.isEdit() ? undefined : Number(this.electionId()),
        stateWide: this.state.stateWide,
        totalVoteCount: this.state.totalVoteCount,
        electionCountyIds: this.state.electionCountyIds,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState({isUpdatingIssue: false});
        if (json) {
          if(!this.isEdit()) {
            this.props.history.replace("/issues/edit/" + json.id)
          }
          this.readIssue();
        }
      })
  };

  readIssue = () => {
    this.setState({isReadingIssue: true});
    fetch(APIService.serverUrl + '/api/private/issue/' + this.issueId(), {
      method: 'GET',
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState({isReadingIssue: false});
        if (json) {
          console.log(json)
          this.setState({
            issue: json,
            name: json.name,
            forCount: json.forCount,
            againstCount: json.againstCount,
            stateWide: json.stateWide,
            totalVoteCount: json.totalVoteCount,
            electionId: json.electionId,
            electionCountyIds: json.electionCounties.map((county) => county.id),
            voteCounts: json.voteCounts,
          })
        }
      })
  };

  render = () => {
    return (
      <div>
        <Form loading={this.state.isReadingIssue}>
          <Header dividing icon='add' content={this.isEdit() ? 'Edit Issue' : 'Add Issue'} color='blue'/>

          <Form.Field>
            <label>
              Issue Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Input
              name='name'
              disabled={this.state.isUpdatingIssue}
              value={this.state.name}
              onChange={event => {
                this.setState( {name: event.target.value})
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>
              Is this Issue state wide?&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Checkbox
              toggle
              name='stateWide'
              checked={this.state.stateWide}
              onChange={() => {
                this.setState({ stateWide: !this.state.stateWide })
                if(this.state.stateWide === false) {
                  this.setState({totalVoteCount: true})
                }
              }}
            />
          </Form.Field>

          <Form.Field>
            <label>
              totalVoteCount: &nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue'
              />
            </label>
            <Checkbox
              toggle
              name='totalVoteCount'
              checked={this.state.totalVoteCount}
              onChange={() => {
                this.setState({ totalVoteCount: this.state.stateWide ? true : !this.state.totalVoteCount })
              }}
            />
          </Form.Field>

          {this.state.stateWide ? null :
            <Form.Select
              multiple
              label="Counties"
              loading={this.state.isFetchingCounties}
              options={this.state.electionCounties.map((county) => ({
                text: county.name,
                value: county.id,
              }))}
              value={this.state.electionCountyIds}
              onChange={(event, data) => {
                this.setState({electionCountyIds: data.value})
              }}
            />
          }

          {!this.state.totalVoteCount ? null :
            <Form.Group>
              <Form.Field >
                <label>For Count:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
                </label>
                <Input
                  name='forCount'
                  type='number'
                  disabled={this.state.isUpdatingIssue}
                  value={this.state.forCount}
                  onChange={event => {
                    this.setState( {forCount: event.target.value})
                  }}
                />
              </Form.Field>
              <Form.Field >
                <label>Against Count:&nbsp;
                  <Icon
                    name='asterisk'
                    size='small'
                    color='blue'
                  />
                </label>
                <Input
                  name='againstCount'
                  type='number'
                  disabled={this.state.isUpdatingIssue}
                  value={this.state.againstCount}
                  onChange={event => {
                    this.setState( {againstCount: event.target.value})
                  }}
                />
              </Form.Field>
            </Form.Group>
           }


          <Button
            size='mini'
            color='green'
            onClick={this.updateIssue}
            loading={this.state.isUpdatingIssue}
            disabled={this.state.isUpdatingIssue}
            style={{marginBottom: '40px'}}
          >
            <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
            {this.isEdit() ? 'Edit Issue' : 'Add Issue'}
          </Button>

          {!this.isEdit() || this.state.totalVoteCount ? null :
            <div>
              {this.state.voteCounts.map((count) => {
                return (
                  <EditVoteCount
                    voteCount={count}
                    electionCountyOptions={this.state.issue.electionCounties}
                    issueId={this.state.issue.id}
                    readEntity={this.readIssue}
                  />
                )
              })}
              <EditVoteCount
                electionCountyOptions={this.state.issue.electionCounties}
                issueId={this.state.issue.id}
                readEntity={this.readIssue}
              />
            </div>
          }
        </Form>
      </div>
    )
  };
}

export default withRouter(EditIssue)
