import React, { Component } from "react"
import { Route } from "react-router-dom";
import ClosingList from "../Views/ViewClosingList";
import EditClosing from "../Editors/EditClosing";
import APIService from "../API";
import ListClosingsRadio from "../Views/ListClosingsRadio";
import ListAllClosings from "../Views/ListAllClosings";

class ClosingController extends Component {
  state = {
    closings: [],
    areClosingsLoading: true,
    showExpired: false,
  }

  showExpiredFilter = false;

  componentDidMount() {
    this.getClosings();
  }

  toggleShowExpiredFilter = () => {
    this.showExpiredFilter = !this.showExpiredFilter;
    this.setState({showExpired: this.showExpiredFilter});
    this.getClosings();
  }

  getClosings = () => {
    fetch(APIService.serverUrl + '/api/private/closings', {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        showExpired: this.showExpiredFilter,
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("Closings Fetch Failed: ", response.status, response.statusText)
          if (response.status === 401) { this.props.history.push('/') };
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("ClosingList Request Succeeded!");
          this.setState({
            "closings": json,
            "areClosingsLoading": false,
          })
        } else {
          console.log("Closings Fetch Failed")
        }
      })
  }

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <div >
        <Route
          path={relativePath}
          exact
          render={() =>
            <ClosingList
              {...this.state}
              toggleShowExpiredFilter={this.toggleShowExpiredFilter}
            />
          }
        />
        <Route
          path={relativePath + "/:addEdit(add|edit)/:closingId?"}
          render={() => <EditClosing refreshList={this.getClosings} />}
        />
        <Route
          path={relativePath + '/radio'}
          render={() =>
            <ListClosingsRadio
              {...this.state}
              toggleShowExpiredFilter={this.toggleShowExpiredFilter}
            />
          }
        />
        <Route
          path={relativePath + '/history'}
          render={() =>
            <ListAllClosings
              {...this.state}
              toggleShowExpiredFilter={this.toggleShowExpiredFilter}
            />
          }
        />
      </div>
    )
  }
}

export default ClosingController
