import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input, Grid, Image, Checkbox} from "semantic-ui-react";
import Helpers from "../Helpers";
import APIService from "../API";


class EditSport extends Component {

    state = {
        sportId: null,
        sortOrder: null,
        isReadingSport: false,
        isUpdatingSport: false,
    }

    componentDidMount() {
        if(this.isEdit()) {
            this.readSport();
        };
    };

    isEdit = () => this.props.match.params.sportId !== undefined;

    sportId = () => this.props.match.params.sportId;

    updateSport = () => {
        this.setState({isUpdatingSport: true});
        fetch(APIService.serverUrl + "/api/private/sport" + (this.isEdit() ? "/" + this.sportId() : ""), {
            method: this.isEdit() ? "PATCH" : "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({
                name: this.state.sportId,
                sortOrder: this.state.sortOrder,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState({isUpdatingSport: false});
                if (json) {
                    this.props.refreshList();
                    this.props.history.push("/sports");

                }
            })
    }

    readSport = () => {
        this.setState({isReadingSport: true});
        fetch( APIService.serverUrl + '/api/private/sport/' + this.sportId(), {
            method: "GET",
            headers: APIService.authHeaders(),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState( { isReadingSport: false});
                if (json) {
                    this.setState( {
                      sportId: json.name,
                      sortOrder: json.sortOrder,
                    })
                }
            })
    };

    render = () => {
        return (
            <div>
                <Form loading={this.state.isReadingSport}>
                    <Header dividing icon='add' content={this.isEdit() ? 'Edit Sport' : 'Add Sport'} color='blue'/>
                    <Form.Group>
                      <Form.Field>
                          <label>
                              Sport Name:
                          </label>
                          <Input
                              id='sportName'
                              name='sportName'
                              disabled={this.state.isUpdatingSport}
                              value={this.state.sportId}
                              onChange={(event) => {
                                  this.setState( {sportId: event.target.value})
                                  console.log(this.state.sportId)
                              }}
                          />
                      </Form.Field>
                      <Form.Field>
                          <label>Sort Order:</label>
                          <Input
                              id='sortOrder'
                              name='sortOrder'
                              disabled={this.state.isUpdatingSport}
                              type='number'
                              value={this.state.sortOrder}
                              onChange={(event) => {
                                  this.setState( {sortOrder: Number(event.target.value)})
                              }}
                          />
                      </Form.Field>
                    </Form.Group>

                    <Button
                        loading={this.state.isUpdatingSport}
                        disabled={this.state.isUpdatingSport}
                        size='mini'
                        color='green'
                        onClick={this.updateSport}
                    >
                        <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                        {this.isEdit() ? 'Edit Sport' : 'Add Sport'}
                    </Button>
                </Form>
            </div>
        )
    };
};

export default withRouter(EditSport);
