import React, {Component} from "react";
import {withRouter} from 'react-router';
import {Form, Header, Button, Icon, Input, Grid, Image, Checkbox, Dropdown} from "semantic-ui-react";
import APIService from "../API";


class EditTeam extends Component {

    state = {
        schoolId: "",
        sportId: "",
        teamName: "",
        schoolList: [],
        sportList: [],
        isReadingTeam: false,
        isUpdatingTeam: false,
        isFetchingSports: false,
        isFetchingSchools: false,
    };

    componentDidMount() {
        if(this.isEdit()) {
            this.readTeam();
        }
        this.getSchoolList();
        this.getSportList();
    };

    isEdit = () => this.props.match.params.teamId !== undefined;

    teamId = () => this.props.match.params.teamId;

    readTeam = () => {
        this.setState({isReadingTeam: true});
        fetch( APIService.serverUrl + "/api/private/team/" + this.teamId(), {
            method: "GET",
            headers: APIService.authHeaders(),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                this.setState( { isReadingTeam: false});
                if (json) {
                    this.setState( {
                        teamName: json.name,
                        schoolId: json.school.id,
                        sportId: json.sport.id,
                    })
                }
            })
    }

    updateTeam = () => {
        this.setState({isUpdatingTeam: true});
        fetch(APIService.serverUrl + "/api/private/team" + (this.isEdit() ? "/" + this.teamId() : ""), {
            method: this.isEdit() ? "PATCH" : "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify( {
                name: this.state.teamName,
                schoolId: this.state.schoolId,
                sportId: this.state.sportId,
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then( (json) => {
                this.setState( {isUpdatingTeam: false})
                if (json) {
                    this.props.refreshList();
                    this.props.history.push("/teams");
                }
            })
    }

    getSchoolList = () => {
        this.setState( {isFetchingSchools: true})
        fetch(APIService.serverUrl + "/api/private/schools", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState( {schoolList: json})
                }
                this.setState({isFetchingSchools: false})
            })
    };

    getSportList = () => {
        this.setState( {isFetchingSports: true});
        fetch( APIService.serverUrl + "/api/private/sports", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if(response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState({sportList: json})
                }
                this.setState({isFetchingSports: false})
            })
    };

    render = () => {

        return (
            <div>
                <Form loading={this.state.isReadingTeam}>
                    <Header dividing icon='add' content={this.isEdit() ? 'Edit Team' : 'Add Team'} color='blue'/>
                    <Form.Field>
                        <label>Team Name:</label>
                        <Input
                            id='teamName'
                            name='teamName'
                            disabled={this.state.isUpdatingTeam}
                            value={this.state.teamName}
                            onChange={(event) => {
                                this.setState( {teamName: event.target.value})
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>School:</label>
                        <Dropdown
                            placeholder='Select School'
                            fluid
                            search
                            selection
                            value={this.state.schoolId}
                            options={this.state.schoolList.map( (school) => ({
                                key: school.id,
                                text: school.name,
                                value: school.id,
                            }))}
                            disabled={this.state.isUpdatingTeam}
                            loading={this.state.isReadingTeam || this.state.isFetchingSchools}
                            onChange={(event, data) => {
                                this.setState( {schoolId: data.value})
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Sport:</label>
                        <Dropdown
                            placeholder='Select Sport'
                            fluid
                            search
                            selection
                            value={this.state.sportId}
                            options={this.state.sportList.map( (sport) => ({
                                key: sport.id,
                                text: sport.name,
                                value: sport.id,
                            }))}
                            disabled={this.state.isUpdatingTeam}
                            loading={this.state.isReadingTeam || this.state.isFetchingSports}
                            onChange={(event, data) => {
                                this.setState( {sportId: data.value})
                            }}
                        />
                    </Form.Field>
                    <Button
                        loading={this.state.isUpdatingTeam}
                        disabled={this.state.isUpdatingTeam || this.state.isReadingTeam}
                        size='mini'
                        color='green'
                        onClick={this.updateTeam}
                    >
                        <Icon name='right arrow' style={{marginRight: '5px', marginLeft: '0'}}/>
                        {this.isEdit() ? 'Edit Team' : 'Add Team'}
                    </Button>
                </Form>
            </div>
        )
    };
};

export default withRouter(EditTeam);