import React, { Component } from 'react';
import {
  Table,
  Icon,
  Dimmer,
  Loader,
  Label,
  Button,
  Dropdown, Pagination,
} from 'semantic-ui-react'
import Moment from 'react-moment';
import 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { withRouter } from "react-router";
import APIService from "../API";
import Helpers from '../Helpers.js';


class ArticleList extends Component {

  state = {

    articleType: [],
    articleTypeId: '',

    activeFilterOption: '',

    articleStateOptions: [
      { key: 1, text: 'Active', value: 'active' },
      { key: 2, text: 'Pending', value: 'pending' },
    ]

  }

  componentDidMount() {
    this.getType('articleType');
  }

  getType = (type) => {
    fetch(APIService.serverUrl + '/api/private/'+type, {
      method: "GET",
      headers: APIService.authHeaders(),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log(type+" Fetch Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log(type+" Request Succeeded!");
        this.setState({
          [type]: json,
        })
      } else {
        console.log(type+" Fetch Failed")
      }
    })
  }

  startAddArticle = () => {
    this.props.history.push('/articles/add');
  }

  startEditArticle = (article) => {
    this.props.history.push('/articles/edit/'+article.id);
  };

  onArticleFilterChange = (e, { name, value }) => {
    this.setState({ [name]: value });

    let filterBody = {};
    if(this.state.activeFilterOption !== '') {
      filterBody.activeOption = this.state.activeFilterOption
    }
    if(this.state.articleTypeId !== '') {
      filterBody.articleTypeId = this.state.articleTypeId
    }

    switch (name) {
      case 'activeFilterOption':
        if(value !== '') {
          filterBody.activeOption = value
        } else { filterBody.activeOption = undefined } break;
      case 'articleTypeId':
        if(value !== '') {
          filterBody.articleTypeId = value
        } else { filterBody.articleTypeId = undefined } break;
      default: break;
    }

    this.props.refreshList(filterBody);
  }


  render() {

    let defaultImage = '/images/logo.png';

    return (

      <div>

        <Dimmer active={this.props.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Table celled striped color="blue" selectable compact='very' size='small'>
          <Table.Header fullWidth >

            <Table.Row >
              <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='11' >
                <span style={{ fontSize: "18px" }}>Click The Rows Below To Edit Articles.</span>
                <Button color="blue" onClick={this.startAddArticle} floated='right' size='mini' >Add Article</Button>
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row >
              <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='11' >
                <Dropdown
                  id="activeFilterOption"
                  name='activeFilterOption'
                  placeholder='Article State Filter...'
                  selection
                  clearable
                  options={this.state.articleStateOptions}
                  value={this.state.activeFilterOption}
                  onChange={this.onArticleFilterChange} />

                <Dropdown
                  id="articleTypeId"
                  name='articleTypeId'
                  placeholder='Article Type Filter...'
                  selection
                  clearable
                  options={this.state.articleType.map(
                    tfilters => { return { key: tfilters.id, text: tfilters.name, value: tfilters.id }
                  })}
                  value={this.state.articleTypeId}
                  onChange={this.onArticleFilterChange} />

              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell>
                <strong>Main Image</strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" collapsing>
                <strong>Approved</strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center' collapsing>
                <strong>ID</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Views</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Headline</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Tag</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Waiting For</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Updated</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Start Date</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>End Date</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Priority</strong>
              </Table.HeaderCell>
            </Table.Row>

          </Table.Header>

          <Table.Body>
            {this.props.articleList.map( (article) => {
              return(
                <Table.Row
                  key={article.id.toString()}
                  id={article.id.toString()}
                  name={article.headline}
                  activestate={article.isApproved.toString()}
                  style={{cursor:'pointer'}}
                  onClick={ (e)=> {this.startEditArticle(article)} }
                >
                  <Table.Cell name='mainArticleImage' >
                    <img
                      width='83px'
                      height='47px'
                      alt={article.mainImage ? article.mainImage.id : 'default image'}
                      src={article.mainImage ? Helpers.getOptimizedImage(article.mainImage, 263) : defaultImage }
                    />
                  </Table.Cell>
                  <Table.Cell name='approved' textAlign="center" >
                    <Icon name={article.isApproved ? 'check circle' : 'circle outline'} color="blue" />
                  </Table.Cell>
                  <Table.Cell name='id' textAlign="center" collapsing>
                    <Label circular size='tiny' content={article.id} />
                  </Table.Cell>
                  <Table.Cell name='views' textAlign="center"  >
                    {article.viewCount}
                  </Table.Cell>
                  <Table.Cell name='headline' >
                    {article.headline}
                  </Table.Cell>
                  <Table.Cell name='tag' >
                    {article.tag}
                  </Table.Cell>
                  <Table.Cell name='waiting' >
                    {article.waitingFor}
                  </Table.Cell>
                  <Table.Cell name='updated' >
                    <Moment format='MM/DD/YYYY - h:mm a' >{article.updatedAt}</Moment>
                  </Table.Cell>
                  <Table.Cell name='start' >
                    <Moment format='MM/DD/YYYY - h:mm a' >{article.startDate}</Moment>
                  </Table.Cell>
                  <Table.Cell name='end' >
                    <Moment format='MM/DD/YYYY - h:mm a' >{article.endDate}</Moment>
                  </Table.Cell>
                  <Table.Cell name='priority' >
                    {article.articlePriority.name}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <Pagination
          defaultActivePage={this.props.page} totalPages={this.props.lastPage}
          onPageChange={(event, { activePage }) => {
            this.props.refreshList(this.props.filterBody, activePage)
          }}
        />

        <Dropdown
          selection
          options={[
            {
              text: "30",
              value: 30,
            },
            {
              text: "50",
              value: 50,
            },
            {
              text: "100",
              value: 100,
            },
          ]}
          value={this.props.pageSize}
          onChange={(event, {value}) => {
            this.props.refreshList(this.props.filterBody, this.props.page, value)
          }}
        />

      </div>

    );
  }
}

export default withRouter(ArticleList)

/*



*/
