import React, {Component} from 'react'
import { Route, Switch } from "react-router-dom";
import {withRouter} from "react-router";
import EditRace from "../Editors/EditRace";

class RaceController extends Component {
  render = () => {
    const relativePath = this.props.match.path

    return (
      <Switch>
        <Route
          path={relativePath + "/edit/:raceId"}
          render={() => (
            <EditRace/>
          )}
        />

        <Route
          path={relativePath + "/add/:electionId"}
          render={() => (
            <EditRace/>
          )}
        />
      </Switch>
    )
  }
}

export default withRouter(RaceController)