import React, { Component } from "react"
import { Route } from "react-router-dom";
import AgencyList from "../Views/ViewAgencyList.js";
import EditAgency from "../Editors/EditAgency.js";
import APIService from "../API.js";

class AgencyListController extends Component {
  state = {
    agencyList: [],
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getAgencyList();
  }

  getAgencyList = () => {
    fetch(APIService.serverUrl + '/api/private/agencies', {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({}),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("getAgencyList Fetch Failed: ", response.status, response.statusText)
        if (response.status === 401) { this.props.history.push('/') };
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("getAgencyList Request Succeeded! Controller page");
        this.setState({
          "agencyList": json,
          "componentIsLoading": false,
        })
      } else {
        console.log("getAgencyList Fetch Failed")
      }
    })
  }

  render = () => {

    let relativePath = this.props.match.path;

    return (
      <div>
        <Route
          path={relativePath}
          exact
          render={() => <AgencyList {...this.state}/>}
        />
        <Route
          path={relativePath + "/:addEdit(add|edit)/:agencyId?"}
          render={() => <EditAgency refreshList={this.getAgencyList} />}
        />
      </div>
    )
  }
}

export default AgencyListController
