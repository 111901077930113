import React, { Component } from 'react';
import {
  Icon,
  Button,
  Form,
  Input,
  Select,
  Header,
  Label,
} from 'semantic-ui-react'
import { withRouter } from "react-router";
import APIService from '../API.js';
import ListUsers from "../Views/ListUsers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';


class EditAgency extends Component {

  static defaultState = {
    id: undefined,
    name: '',
    permissionCode: '',
    textCasterId: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    agencyPhone: '',
    mainContactName: '',
    mainContactTitle: '',
    mainContactEmail: '',
    mainContactPhone: '',
    secondContactName: '',
    secondContactTitle: '',
    secondContactEmail: '',
    secondContactPhone: '',
    lastConfirmedDate: new moment(),
    agencyWebsite: '',
    athleticDirectorName: '',
    agencyTypeId: 0,

    unmodifiedAgency: {},
    agencyTypes: [],
  }

  state = EditAgency.defaultState;

  componentDidMount() {
    this.getAgency();
    this.getAgencyTypes();
  }

  getAgency = () => {
    if (this.props.match.params.agencyId !== undefined) {
      fetch(APIService.serverUrl + '/api/private/agency/'+this.props.match.params.agencyId, {
        method: "GET",
        headers: APIService.authHeaders(),
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("articleType Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("articleType Request Succeeded! Edit Page");
          // console.log(json);
          let tempAgency = {
            id: parseInt(this.props.match.params.agencyId, 10),
            name: json.name,
            permissionCode: json.permissionCode,
            textCasterId: json.textCasterId,
            address1: json.address1,
            address2: json.address2,
            city: json.city,
            state: json.state,
            zip: json.zip,

            agencyPhone: json.phoneNumber,
            mainContactName: json.mainContactName,
            mainContactTitle: json.mainContactTitle,
            mainContactEmail: json.mainContactEmail,
            mainContactPhone: json.mainContactPhone,
            secondContactName: json.secondaryContactName,
            secondContactTitle: json.secondaryContactTitle,
            secondContactEmail: json.secondaryContactEmail,
            secondContactPhone: json.secondaryContactPhone,
            lastConfirmedDate: moment(json.lastConfirmedDate),
            agencyWebsite: json.website,
            athleticDirectorName: json.athleticDirectorName,

            agencyTypeId: json.agencyType.id,
          }
          this.setState({
            ...tempAgency,
            "unmodifiedAgency": {
              ...tempAgency,
            },
          })
        } else {
          console.log("articleTypes Fetch Failed returned null")
        }
      })
    }
  }

  getAgencyTypes = () => {
    fetch(APIService.serverUrl + '/api/private/agencyType', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("getAgencyTypes Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("getAgencyTypes Request Succeeded! Controller page");
          this.setState({
            "agencyTypes": json,
          })
        } else {
          console.log("getAgencyTypes Fetch Failed")
        }
      })
  }

  patchAgency = () => {
    this.setState({ 'componentIsLoading': true, });
    let apiPath = this.state.id ? '/api/private/agency/'+this.state.id : '/api/private/agency';
    fetch(APIService.serverUrl + apiPath, {
      method: this.state.id ? 'PATCH' : 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        id: this.state.id,
        name: this.state.name,
        permissionCode: this.state.permissionCode,
        textCasterId: this.state.textCasterId,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,

        phoneNumber: this.state.agencyPhone,
        mainContactName: this.state.mainContactName,
        mainContactTitle: this.state.mainContactTitle,
        mainContactEmail: this.state.mainContactEmail,
        mainContactPhone: this.state.mainContactPhone,
        secondaryContactName: this.state.secondContactName,
        secondaryContactTitle: this.state.secondContactTitle,
        secondaryContactEmail: this.state.secondContactEmail,
        secondaryContactPhone: this.state.secondContactPhone,
        lastConfirmedDate: moment(this.state.lastConfirmedDate),
        website: this.state.agencyWebsite,
        athleticDirectorName: this.state.athleticDirectorName,

        agencyTypeId: this.state.agencyTypeId,
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("patchAgency PATCH Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("articleType PATCH Succeeded! Edit Page");
        this.props.history.replace('/agencies/edit/'+json.id);
        this.props.refreshList();
        this.getAgency();
      } else {
        console.log("patchAgency PATCH Failed")
      }
    })
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  onSelectChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  onDateChange = (date) => {
    this.setState({ lastConfirmedDate: moment(date) })
  }

  isFormModified = () => {
    if (this.state.id === undefined) { return true }
    for (var key in this.state.unmodifiedAgency) {
      if (this.state.unmodifiedAgency[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  }

  render() {

    let requiredFieldsComplete =
      this.state.name !== '' &&
      this.state.agencyTypeId !== 0;

    let hasAgencyLoaded = this.state.id !== undefined;

    return (
      <div>
        <Form >
          <Header
            dividing
            icon={ this.state.id ? 'edit outline' : 'add'}
            content={ this.state.id ? 'Edit Agency' : 'Add Agency'}
            color='blue' />
          <Form.Field>
            <label>Name:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
            </label>
            <Input
              id='name'
              name='name'
              placeholder='Enter the Agency Name...'
              value={
                this.state.name !== undefined ?
                this.state.name : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Permission Code: </label>
            <Input
              id='permissionCode'
              name='permissionCode'
              placeholder='Enter the Permission Code...'
              value={
                this.state.permissionCode !== undefined ?
                this.state.permissionCode : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>TextCaster ID: </label>
            <Input
              id='textCasterId'
              name='textCasterId'
              placeholder='Enter the TextCaster ID...'
              value={
                this.state.textCasterId !== undefined ?
                this.state.textCasterId : '' }
              onChange={this.onInputChange} />
            <Label size='tiny' pointing >
              TextCaster ID only required for sending texts.
            </Label>
          </Form.Field>
          <Form.Field>
            <label>Address 1: </label>
            <Input
              id='address1'
              name='address1'
              placeholder='Enter the Address...'
              value={
                this.state.address1 !== undefined ?
                this.state.address1 : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Address 2: </label>
            <Input
              id='address2'
              name='address2'
              placeholder='Enter the Address...'
              value={
                this.state.address2 !== undefined ?
                this.state.address2 : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>City: </label>
            <Input
              id='city'
              name='city'
              placeholder='Enter the City...'
              value={
                this.state.city !== undefined ?
                this.state.city : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>State: </label>
            <Input
              id='state'
              name='state'
              placeholder='Enter the State...'
              value={
                this.state.state !== undefined ?
                this.state.state : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Zip Code: </label>
            <Input
              id='zip'
              name='zip'
              placeholder='Enter the Zip Code...'
              value={
                this.state.zip !== undefined ?
                this.state.zip : '' }
              onChange={this.onInputChange} />
          </Form.Field>

          <Form.Field>
            <label>Phone: </label>
            <Input
              id='agencyPhone'
              name='agencyPhone'
              placeholder='Enter the Agency Phone Number...'
              value={
                this.state.agencyPhone !== undefined ?
                this.state.agencyPhone : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Agency Type:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
            </label>
            <Select
              id="agencyTypeId"
              name='agencyTypeId'
              options={this.state.agencyTypes.map(
                aChoice => { return { key: aChoice.id, text: aChoice.name, value: aChoice.id }
              })}
              placeholder='Select the Agency Type...'
              value={this.state.agencyTypeId}
              onChange={this.onSelectChange} />
          </Form.Field>

          <Header as='h4' dividing content='Main Contact'/>
          <Form.Field>
            <label>Name: </label>
            <Input
              id='mainContactName'
              name='mainContactName'
              placeholder='Enter the Main Contact Name...'
              value={
                this.state.mainContactName !== undefined ?
                this.state.mainContactName : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Title: </label>
            <Input
              id='mainContactTitle'
              name='mainContactTitle'
              placeholder='Enter the Main Contact Title...'
              value={
                this.state.mainContactTitle !== undefined ?
                this.state.mainContactTitle : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Email: </label>
            <Input
              id='mainContactEmail'
              name='mainContactEmail'
              placeholder='Enter the Main Contact Email...'
              value={
                this.state.mainContactEmail !== undefined ?
                this.state.mainContactEmail : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Phone: </label>
            <Input
              id='mainContactPhone'
              name='mainContactPhone'
              placeholder='Enter the Main Contact Phone...'
              value={
                this.state.mainContactPhone !== undefined ?
                this.state.mainContactPhone : '' }
              onChange={this.onInputChange} />
          </Form.Field>

          <Header as='h4' dividing content='Secondary Contact'/>
          <Form.Field>
            <label>Name: </label>
            <Input
              id='secondContactName'
              name='secondContactName'
              placeholder='Enter the Second Contact Name...'
              value={
                this.state.secondContactName !== undefined ?
                this.state.secondContactName : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Title: </label>
            <Input
              id='secondContactTitle'
              name='secondContactTitle'
              placeholder='Enter the Second Contact Title...'
              value={
                this.state.secondContactTitle !== undefined ?
                this.state.secondContactTitle : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Email: </label>
            <Input
              id='secondContactEmail'
              name='secondContactEmail'
              placeholder='Enter the Second Contact Email...'
              value={
                this.state.secondContactEmail !== undefined ?
                this.state.secondContactEmail : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Phone: </label>
            <Input
              id='secondContactPhone'
              name='secondContactPhone'
              placeholder='Enter the Second Contact Phone...'
              value={
                this.state.secondContactPhone !== undefined ?
                this.state.secondContactPhone : '' }
              onChange={this.onInputChange} />
          </Form.Field>

          <Header as='h4' dividing content='Additional Information'/>
          <Form.Field>
            <label>Last Confirmed Date: </label>
            <DatePicker
              name='lastConfirmedDate'
              dateFormat='MM/dd/yyyy'
              selected={this.state.lastConfirmedDate.toDate()}
              onChange={this.onDateChange} />
          </Form.Field>
          <Form.Field>
            <label>Agency Website: </label>
            <Input
              id='agencyWebsite'
              name='agencyWebsite'
              placeholder='Enter the Agency Website...'
              value={
                this.state.agencyWebsite !== undefined ?
                this.state.agencyWebsite : '' }
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <label>Athletic Director Name: </label>
            <Input
              id='athleticDirectorName'
              name='athleticDirectorName'
              placeholder='Enter the Athletic Director Name...'
              value={
                this.state.athleticDirectorName !== undefined ?
                this.state.athleticDirectorName : '' }
              onChange={this.onInputChange} />
          </Form.Field>

          <Form.Field>
            <Label size='tiny' >
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
              Denotes required field
            </Label>
          </Form.Field>
          <Form.Field >
            <Button
              size='mini'
              color={ this.isFormModified() ? 'red' : undefined }
              onClick={ () => this.props.history.push('/agencies') } >
              <Icon name='cancel' /> { this.isFormModified() ? 'Cancel' : 'Close' }
            </Button>
            <Button
              size='mini'
              color='green'
              onClick={ this.patchAgency }
              disabled={ !requiredFieldsComplete || !this.isFormModified() } >
              <Icon name='save outline' /> Save
            </Button>
          </Form.Field>

          {!hasAgencyLoaded ? null :
            <div>
              <Header dividing content='Authorized Users'/>
              <ListUsers agencyId={this.state.id}/>
            </div>
          }
        </Form>

      </div>
    );
  }
}

export default withRouter(EditAgency);

/*



*/
