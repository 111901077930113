import React, {Component} from 'react'
import Helpers from "../Helpers";
import {Dimmer, Dropdown, Header, Label, Loader, Pagination, Table} from "semantic-ui-react";
import APIService from "../API";
import Moment from "react-moment";
import moment from "moment";

let articleSortOptions = [
  { key: 1, text: 'View Count', value: 'viewCount' },
  { key: 2, text: 'Word Count', value: 'wordCount' },
]

export default class ViewArticlesReport extends Component {
  state = {
    articleList: [],
    filterBody: {},
    page: 1,
    pageSize: 30,
    lastPage: 1,
    componentIsLoading: true,

    articleTypes: [],
    selectedArticleType: undefined,

    articleSortOption: "viewCount",
  }

  componentDidMount() {
    this.getArticleList({sortOption: this.state.articleSortOption});
    this.getArticleTypes()
  }

  getArticleList = (filterBody = this.state.filterBody, page = this.state.page, pageSize = this.state.pageSize) => {
    fetch(APIService.serverUrl + '/api/private/articles', {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify( {
        ...filterBody,
        beginningDateRange: moment().startOf('week').toISOString(),
        endDateRange: moment().toISOString(),
        page: page,
        pageSize: pageSize,
      })
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("ArticleList Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("ArticleList Fetch Succeeded")
          // console.log(json);
          this.setState({
            articleList: json.results,
            lastPage: json.lastPage,
            componentIsLoading: false,
          })
        } else {
          console.log("ArticleList Fetch Failed")
        }
      })
  }

  getArticleTypes = () => {
    fetch(APIService.serverUrl + '/api/private/articleType', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("Article Types Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("Article Types Request Succeeded!");
          this.setState({
            articleTypes: json,
          })
        } else {
          console.log("Article Types Fetch Failed")
        }
      })
  }

  onArticleFilterChange = (e, { name, value }) => {
    this.setState({ [name]: value });

    let filterBody = {};
    if(this.state.articleSortOption !== '') {
      filterBody.sortOption = this.state.articleSortOption
    }
    if(this.state.selectedArticleType !== '') {
      filterBody.articleTypeId = this.state.selectedArticleType
    }

    switch (name) {
      case 'articleSortOption':
        if(value !== '') {
          filterBody.sortOption = value
        } else { filterBody.sortOption = undefined } break;
      case 'selectedArticleType':
        if(value !== '') {
          filterBody.articleTypeId = value
        } else { filterBody.articleTypeId = undefined } break;
      default: break;
    }

    this.setState({filterBody: filterBody});
    this.getArticleList(filterBody);
  }

  render = () => {
    let defaultImage = '/images/logo.png';

    return (

      <div>

        <Dimmer active={this.state.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Header content="This Week's Report"/>

        <Table celled striped color="blue" selectable compact='very' size='small'>
          <Table.Header fullWidth >

            <Table.Row >
              <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='11' >
                <Dropdown
                  name='articleSortOption'
                  placeholder='Article Sort Option...'
                  selection
                  options={articleSortOptions}
                  value={this.state.articleSortOption}
                  onChange={this.onArticleFilterChange} />

                <Dropdown
                  name='selectedArticleType'
                  placeholder='Article Type Filter...'
                  selection
                  clearable
                  options={this.state.articleTypes.map(
                    type => { return { key: type.id, text: type.name, value: type.id }
                  })}
                  value={this.state.selectedArticleType}
                  onChange={this.onArticleFilterChange} />

              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell>
                <strong>Main Image</strong>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign='center' collapsing>
                <strong>ID</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Views</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Word Count</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Headline</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Tag</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Updated</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Start Date</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>End Date</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Priority</strong>
              </Table.HeaderCell>
            </Table.Row>

          </Table.Header>

          <Table.Body>
            {this.state.articleList.map( (article) => {
              return(
                <Table.Row
                  key={article.id.toString()}
                  id={article.id.toString()}
                  name={article.headline}
                  activestate={article.isApproved.toString()}
                >
                  <Table.Cell name='mainArticleImage' >
                    <img
                      width='83px'
                      height='47px'
                      alt={article.mainImage ? article.mainImage.id : 'default image'}
                      src={article.mainImage ? Helpers.getOptimizedImage(article.mainImage, 263) : defaultImage }
                    />
                  </Table.Cell>
                  <Table.Cell name='id' textAlign="center" collapsing>
                    <Label circular size='tiny' content={article.id} />
                  </Table.Cell>
                  <Table.Cell name='views' textAlign="center"  >
                    {article.viewCount}
                  </Table.Cell>
                  <Table.Cell name='views' textAlign="center"  >
                    {article.wordCount}
                  </Table.Cell>
                  <Table.Cell name='headline' >
                    {article.headline}
                  </Table.Cell>
                  <Table.Cell name='tag' >
                    {article.tag}
                  </Table.Cell>
                  <Table.Cell name='updated' >
                    <Moment format='MM/DD/YYYY - h:mm a' >{article.updatedAt}</Moment>
                  </Table.Cell>
                  <Table.Cell name='start' >
                    <Moment format='MM/DD/YYYY - h:mm a' >{article.startDate}</Moment>
                  </Table.Cell>
                  <Table.Cell name='end' >
                    <Moment format='MM/DD/YYYY - h:mm a' >{article.endDate}</Moment>
                  </Table.Cell>
                  <Table.Cell name='priority' >
                    {article.articlePriority.name}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

        <Pagination
          defaultActivePage={this.state.page} totalPages={this.state.lastPage}
          onPageChange={(event, { activePage }) => {
            this.setState({page: activePage})
            this.getArticleList(this.state.filterBody, activePage)
          }}
        />
      </div>

    );
  }
}
