import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import {withRouter} from "react-router";
import ViewSchoolsList from "../Views/ViewSchoolList";
import EditSchool from "../Editors/EditSchool";
import APIService from "../API.js";


class SchoolController extends Component {

    state = {
        schools: [],
        isFetchingSchools: false,
    };

    componentDidMount() {
        this.getSchoolList();
    };

    getSchoolList = () => {
        this.setState( {isFetchingSchools: true})
        fetch(APIService.serverUrl + "/api/private/schools", {
            method: "POST",
            headers: APIService.authHeaders(),
            body: JSON.stringify({}),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then((json) => {
                if (json) {
                    this.setState( {schools: json})
                }
                this.setState({isFetchingSchools: false})
            })
    };



    render = () => {

        let relativePath = this.props.match.path;

        return (
            <div>
                <Switch>
                    <Route
                        path={relativePath}
                        exact
                        render={() =>
                            <ViewSchoolsList
                                {...this.state}
                                refreshList={this.getSchoolList}
                                schools={this.state.schools}
                            />}
                    />
                    <Route
                        path={relativePath + "/add"}
                        render={() =>
                            <EditSchool
                                refreshList={this.getSchoolList}

                            />}
                    />
                    <Route
                        path={relativePath + "/edit/:schoolId"}
                        render={() =>
                            <EditSchool
                                refreshList={this.getSchoolList}
                            />}
                    />
                </Switch>

            </div>
        )
    };
};

export default withRouter(SchoolController);