import React, { Component } from 'react';
import {
  Icon,
  Header,
  Form,
  Input,
  Label,
  Checkbox,
  Button,
  Dropdown,
  Select,
} from 'semantic-ui-react'
import APIService from '../API.js';
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { withRouter } from "react-router";
import wordCount from "html-word-count";


class AddArticle extends Component {

  state = {

    /* Default New Article */
    headline: '',
    subHeadline: '',
    tag: '',
    waitingFor: '',
    story: '',
    startDate: new moment().startOf('day'),
    endDate: new moment().endOf('day').add(7, 'd'),
    articleTypeIds: [],
    articlePriorityId: 0,
    isFeatured: false,
    shouldShowGenericAuthor: false,
    shouldAutoPostToSocial: false,
    /* Default New Article */

    articleType: [],
    articlePriority: [],

    editorState: EditorState.createEmpty(),
    editorStateTest: EditorState.createEmpty(),

    articleTypeId: '',
  }

  componentDidMount() {
    this.getType('articleType');
    this.getType('articlePriority');
  }

  getType = (type) => {
    fetch(APIService.serverUrl + '/api/private/'+type, {
      method: "GET",
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log(type+" Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log(type+" Request Succeeded!");
          this.setState({
            [type]: json,
          })
        } else {
          console.log(type+" Fetch Failed")
        }
      })
  }

  postNewArticle = () => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl + '/api/private/article', {
      method: 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        headline: this.state.headline,
        subHeadline: this.state.subHeadline,
        tag: this.state.tag,
        waitingFor: this.state.waitingFor,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        articleTypeIds: this.state.articleTypeIds,
        articlePriorityId: this.state.articlePriorityId,
        isFeatured: this.state.isFeatured,
        shouldShowGenericAuthor: this.state.shouldShowGenericAuthor,
        shouldAutoPostToSocial: this.state.shouldAutoPostToSocial,
        story: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        wordCount: wordCount(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))),
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("postNewArticle PATCH Failed: ", response.status, response.statusText)
        alert(response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("postNewArticle PATCH Succeeded!");
        this.setState({
          'componentIsLoading': false,
          headline: '',
          subHeadline: '',
          tag: '',
          waitingFor: '',
          story: '',
          startDate: new moment().startOf('day'),
          endDate: new moment().endOf('day').add(7, 'd'),
          articleTypeIds: [],
          articlePriorityId: 0,
          isFeatured: false,
          shouldShowGenericAuthor: false,
          shouldAutoPostToSocial: false,
          editorState: EditorState.createEmpty(),
          editorStateTest: EditorState.createEmpty(),
        })
        this.props.refreshList();
        this.props.history.replace('/articles/edit/'+json.id)
      } else {
        console.log("postNewArticle PATCH Failed")
      }
    })
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  onSelectChange = (e, { name, value }) => {
    this.setState({ [name]: value })
  }

  onStartDateChange = (date) => {
    this.setState({ startDate: moment(date) })
  }

  onEndDateChange = (date) => {
    this.setState({ endDate: moment(date) })
  }

  onToggleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  render() {

    let requiredFieldsComplete =
      this.state.headline.length !== 0 &&
      this.state.subHeadline.length !== 0 &&
        draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) !==
        draftToHtml(convertToRaw(this.state.editorStateTest.getCurrentContent())) &&
      this.state.articlePriorityId !== 0 &&
      this.state.articleTypeId !== 0;

    let { user } = this.props;

    let isRestrictedPublisher =
      !user.roles.filter(role => role.name === "admin").length
      && user.roles.filter(role => role.name === "publisher").length
      && user.articleTypes.length;

    return (

      <Form >
        <Header dividing icon='add' content='Add Article' color='blue' />
        <Form.Field>{/* Headline */}
          <label>
            Headline:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Input
              id='headline'
              name='headline'
              placeholder='Enter the Headline...'
              value={this.state.headline}
              onChange={this.onInputChange} />
        </Form.Field>

        <Form.Field>{/* Sub Headline */}
          <label>
            Sub Headline:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Input
            id='subHeadline'
            name='subHeadline'
            placeholder='Enter the Sub Headline...'
            value={this.state.subHeadline}
            onChange={this.onInputChange} />
        </Form.Field>

        <Form.Field>{/* Tag */}
          <label>Tag: </label>
          <Input
            id='tag'
            name='tag'
            placeholder='Enter the Tag...'
            value={this.state.tag}
            onChange={this.onInputChange} />
        </Form.Field>

        {isRestrictedPublisher ? null :
          <Form.Field>{/* Waiting For */}
            <label>Waiting For: </label>
            <Input
              id='waitingFor'
              name='waitingFor'
              placeholder='Enter the Tag...'
              value={this.state.waitingFor}
              onChange={this.onInputChange}/>
          </Form.Field>
        }

        <Form.Field>{/* Story */}
          <label>
            Story:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'history'],
              inline: {
                inDropdown: false,
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
              blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote', 'Code'],
              },
              fontSize: {
                options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
              },
              textAlign: { inDropdown: true, },
              list: { inDropdown: true, },
            }}
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange} />
          <textarea
            disabled
            hidden
            value={draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))} />
          <p>Word Count: { wordCount(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))}</p>
        </Form.Field>

        <Form.Group widths='equal'>
          <Form.Field>{/* Start Date */}
            <label>Start Date: </label>
            <DatePicker
              name='startDate'
              dateFormat='MM/dd/yyyy h:mm aa'
              minDate={new Date()}
              showTimeSelect
              timeIntervals={15}
              timeCaption="Time"
              selected={this.state.startDate.toDate()}
              onChange={this.onStartDateChange} />
          </Form.Field>

          <Form.Field>{/* End Date */}
            <label>End Date: </label>
            <DatePicker
              name='endDate'
              dateFormat='MM/dd/yyyy h:mm aa'
              minDate={new Date()}
              showTimeSelect
              timeIntervals={15}
              timeCaption="Time"
              selected={this.state.endDate.toDate()}
              onChange={this.onEndDateChange} />
          </Form.Field>
        </Form.Group>

        <Form.Field>{/* Article Type */}
          <label>
            Article Type:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Dropdown
            id="articleTypeIds"
            name='articleTypeIds'
            placeholder='Choose the Article Type...'
            fluid
            multiple
            selection
            options={this.state.articleType.map(
              aType => { return { key: aType.id, text: aType.name, value: aType.id }
            })}
            value={this.state.articleTypeIds}
            onChange={this.onSelectChange} />
        </Form.Field>

        <Form.Field>{/* Article Priority */}
          <label>
            Article Priority:&nbsp;
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
          </label>
          <Select
            id="articlePriorityId"
            name='articlePriorityId'
            options={this.state.articlePriority.map(
              pChoice => { return { key: pChoice.id, text: pChoice.name, value: pChoice.id }
            })}
            placeholder='Choose the Article Priority...'
            value={this.state.pChoice}
            onChange={this.onSelectChange} />
        </Form.Field>

        {isRestrictedPublisher ? null :
          <Form.Group widths='equal'>
            <Form.Field>{/* Featured */}
              <label>Featured Article: </label>
              <Checkbox
                id="isFeatured"
                name='isFeatured'
                checked={this.state.isFeatured}
                onChange={this.onToggleChange}
                toggle/>
            </Form.Field>

            <Form.Field>{/* Generic Author */}
              <label>Show Generic Author: </label>
              <Checkbox
                id="shouldShowGenericAuthor"
                name='shouldShowGenericAuthor'
                checked={this.state.shouldShowGenericAuthor}
                onChange={this.onToggleChange}
                toggle/>
            </Form.Field>

            <Form.Field>{/* Social */}
              <label>Post To Social: </label>
              <Checkbox
                id="shouldAutoPostToSocial"
                name='shouldAutoPostToSocial'
                checked={this.state.shouldAutoPostToSocial}
                onChange={this.onToggleChange}
                toggle/>
            </Form.Field>
          </Form.Group>
        }

        <Form.Field>
          <Label size='tiny' >
            <Icon
              name='asterisk'
              size='small'
              color='blue' />
            Denotes required field
          </Label>
        </Form.Field>

        <Form.Field>{/* Buttons */}
          <Button
            size='mini'
            color='red'
            onClick={ () => this.props.history.push('/articles') } >
            <Icon name='cancel' /> Cancel
          </Button>
          <Button
            size='mini'
            color='green'
            disabled={!requiredFieldsComplete}
            onClick={this.postNewArticle} >
            <Icon name='save outline' /> Save
          </Button>
        </Form.Field>

      </Form>

    );
  }
} export default withRouter(AddArticle);
