import React, {Component} from 'react'
import {Button, Card, Header, List, Segment} from "semantic-ui-react";
import {withRouter} from "react-router-dom";

class ListRaces extends Component {
  render = () => {
    const {races} = this.props
    return (
      <Segment basic className="segmentContent">
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
          <Header style={{marginTop: '5px', textDecoration: 'underline'}}>
            Races
          </Header>
          <div>
            {/*<Button
              content="Edit All Races"
              size="mini"
              color="green"
              style={{marginBottom: '14px', marginRight: '15px'}}
            />*/}
            <Button
              content='Add Race'
              icon='plus'
              size='mini'
              color="blue"
              style={{marginBottom: '14px'}}
              onClick={() => this.props.history.push("/races/add/" + this.props.electionId)}
            />
          </div>
        </div>

        <div >
          {races.map((race) => {
            return (
              <Card
                style={{padding: '0px', textAlign: 'left', width: '100%'}}
                onClick={() => {
                  this.props.history.push("/races/edit/" + race.id)
                }}
              >
                <Card.Content>
                  <Card.Header >{race.name}</Card.Header>
                  <Card.Description>
                    <h6 style={{margin: '0px', marginTop: '-5px', color: 'rgb(33, 133, 208)'}}>{race.stateWide ? "**State wide race**" : null}</h6>
                    {race.candidates.map((candidate) => {
                      if(race.stateWide === false && race.totalVoteCount === false) {
                        return (
                          <div>
                            <h5 style={{fontWeight: 'normal', margin: '5px', textDecoration: 'underline'}}>{candidate.name + " (" + candidate.party.name + ")"}</h5>
                            {candidate.voteCounts.map((count) => {
                              return (
                                <h5 style={{fontWeight: 'normal', margin: '5px 14px'}}>
                                  {count.electionCounty.name + " - " + count.forCount + " votes"}
                                </h5>
                              )
                            })}
                          </div>
                        )
                      } else if(race.stateWide === false && race.totalVoteCount === true) {
                         return (
                           <div>
                              <h5 style={{fontWeight: 'normal', margin: '5px', textDecoration: 'underline'}}>
                                {candidate.name + " (" + candidate.party.name + ")"}
                              </h5>
                              <h5 style={{fontWeight: 'normal', margin: '5px 14px'}}>
                                 {candidate.voteCount + " votes"}
                               </h5>
                           </div>
                         )
                      } else if(race.stateWide === true && race.totalVoteCount === true) {
                        return (
                          <div>
                             <h5 style={{fontWeight: 'normal', margin: '5px', textDecoration: 'underline'}}>
                               {candidate.name + " (" + candidate.party.name + ")"}
                             </h5>
                             <h5 style={{fontWeight: 'normal', margin: '5px 14px'}}>
                                {candidate.voteCount + " votes"}
                              </h5>
                          </div>
                        )
                      } else {
                        return (
                          <div>
                            <h5>Something seems to be wrong</h5>
                          </div>
                        )
                      }
                    })}
                  </Card.Description>
                </Card.Content>
              </Card>
            )
          })}
        </div>
      </Segment>
    )
  }
}

export default withRouter(ListRaces)
