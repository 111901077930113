import React, {Component} from 'react'
import moment from "moment/moment";
import APIService from "../API";
import {Button, Container, Divider, Header, Icon, Segment, Step} from "semantic-ui-react";
import {withRouter} from "react-router-dom";
import ListRaces from "./ListRaces";
import ListIssues from "./ListIssues";

class ViewElection extends Component {

  state = {
    election: {
      races: [],
      issues: [],
    },
    isReadingElection: false,
  }

  componentDidMount() {
    this.readElection()
  }

  electionId = () => this.props.match.params.electionId;

  startEditElection = () => {
    this.props.history.push('/elections/edit/' + this.electionId())
  };

  readElection = () => {
    this.setState({isReadingElection: true});
    fetch(APIService.serverUrl + '/api/private/election/' + this.electionId(), {
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((json) => {
        this.setState({isReadingElection: false});
        if (json) {
          this.setState({election: json})
        }
      })
  };

  render = () => {
    const {election} = this.state

    return (
      <Container style={{marginTop: '20px'}}>
        <Segment loading={this.state.isReadingElection} basic>

          <div className="electionHeading">
            <div className="electionHeaders">
              <Header
                as='h1'
                style={{textDecoration: "underline"}}
              >
                {election.name}
              </Header>
              <Header
                as='h4'
                style={{fontWeight: "normal", marginTop: '10px'}}
              >
                {"Last Updated: " + moment(election.updatedAt).format("MM/DD/YYYY, h:mm a")}
              </Header>
            </div>

            <div className="dateRangeSegments" style={{}}>
              <Segment basic style={{display: 'flex', alignItems: 'center', padding: '0px', marginBottom: '0px'}}>
                <Icon name="calendar outline" size="big" style={{marginRight: '10px'}}/>
                <div>
                  <h4 style={{marginBottom: '2px'}}>Start</h4>
                  <h5 style={{margin: '2px 2px 2px 0', fontWeight: 'normal'}}>{moment(election.startAt).format("MMM D 'YY")}</h5>
                  <h5 style={{margin: '2px 2px 2px 0', fontWeight: 'normal'}}>{moment(election.startAt).format("h:mm a")}</h5>
                </div>
              </Segment>
              <Icon
                name="angle right"
                size="big"
                style={{marginRight: '0px'}}
              />
              <Segment basic style={{display: 'flex', alignItems: 'center', padding: '5px', marginTop: '0px'}}>
                <Icon name="calendar outline" size="big" style={{marginRight: '10px'}}/>
                <div>
                  <h4 style={{marginBottom: '2px'}}>End</h4>
                  <h5 style={{margin: '2px 2px 2px 0', fontWeight: 'normal'}}>{moment(election.endAt).format("MMM D 'YY")}</h5>
                  <h5 style={{margin: '2px 2px 2px 0', fontWeight: 'normal'}}>{moment(election.endAt).format("h:mm a")}</h5>
                </div>
              </Segment>
            </div>

            <Button
              content='Edit'
              icon='pencil'
              color="blue"
              size='mini'
              onClick={this.startEditElection}
            />
          </div>

          <Divider/>

          <div className="electionLists">
            <ListRaces
              electionId={election.id}
              races={election.races}
            />
            <ListIssues
              electionId={election.id}
              issues={election.issues}
            />
          </div>

        </Segment>
      </Container>
    )
  }
}

export default withRouter(ViewElection)
