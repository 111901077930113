import React, { Component } from "react"
import { Route } from "react-router-dom";
import VideoTypes from "../Views/ViewVideoTypes";
import EditVideoType from "../Editors/EditVideoType.js";
import Constants from '../Constants.js';
import APIService from "../API";

class VideoTypeController extends Component {
  state = {
    videoTypes: [],
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getVideoTypes();
  }

  getVideoTypes = () => {
    fetch(APIService.serverUrl + '/api/private/videoType?showInactive=true', {
      method: "GET",
      headers: APIService.authHeaders(),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("getArticleTypes Fetch Failed: ", response.status, response.statusText)
          if (response.status === 401) { this.props.history.push('/') };
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("getArticleTypes Request Succeeded!");
          // console.log(json);
          this.setState({
            "videoTypes": json,
            "componentIsLoading": false,
          })
        } else {
          console.log("getArticleTypes Fetch Failed")
        }
      })
  }

  render = () => {

    let isMobile = Constants.isMobile();
    let relativePath = this.props.match.path;

    return (
      <div>
        <Route
          path={relativePath}
          exact={isMobile}
          render={() => <VideoTypes {...this.state}/>}
        />
        <Route
          path={relativePath + "/:addEdit(add|edit)/:videoTypeId?"}
          render={() => <EditVideoType refreshList={this.getVideoTypes} />}
        />
      </div>
    )
  }
}

export default VideoTypeController
