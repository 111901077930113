import React, {Component} from "react";
import {withRouter} from "react-router";
import {Button, Dimmer, Dropdown, Icon, Label, Loader, Table} from "semantic-ui-react";


class ViewSeasonList extends Component {

  startAddSeason = () => {
      this.props.history.push('/seasons/add');
  };

  startEditSeason = (season) => {
      this.props.history.push('/seasons/edit/' + season.id)  ;
  };

  render = () => {

    return(
      <div>
        <Dimmer
            // active={this.props.componentIsLoading}
            page
        >
            <Loader size='massive'>Loading</Loader>

        </Dimmer>
        <Table
            celled
            striped
            color="blue"
            selectable
            compact='very'
            size='small'
        >
            <Table.Header fullWidth >
                <Table.Row >
                    <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='5' >
                        <span style={{ fontSize: "18px" }}>Click The Rows Below To Edit Seasons.</span>
                        <Button
                            color="blue"
                            onClick={this.startAddSeason}
                            floated='right'
                            size='mini'
                        >
                            Add Season
                        </Button>
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell textAlign="center" collapsing>
                        <strong>Id</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <strong>Season</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <strong>Sport</strong>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {this.props.seasons.map( (season) => {
                    return(
                        <Table.Row
                            key={season.id.toString()}
                            id={season.id.toString()}
                            name={season.name}
                            style={{cursor: 'pointer'}}
                            onClick={(e) => {this.startEditSeason(season)}}
                        >
                            <Table.Cell>{season.id}</Table.Cell>
                            <Table.Cell>{season.name}</Table.Cell>
                            <Table.Cell>{season.sport.name}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
      </div>
    )
  };
};

export default withRouter(ViewSeasonList);
