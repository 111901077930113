import React, {Component} from 'react'
import {Divider, Header, List} from "semantic-ui-react";
import APIService from "../API";
import EditCandidate from "../Editors/EditCandidate"

class ListCandidates extends Component {

  state = {
    isFetchingParties: false,
    parties: [],
  }

  componentDidMount = () => {
    this.fetchParties()
  }

  fetchParties = () => {
    this.setState({isFetchingParties: true})
    fetch(APIService.serverUrl + "/api/private/parties", {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({})
    })
      .then(response => response.ok ? response.json() : null)
      .then(json => {
        this.setState({isFetchingParties: false})
        if (json) {
          this.setState({
            parties: json
          })
        }
      })
  }

  render = () => {
    return (
      <div>
        <Header align='center' style={{marginTop: '20px'}}>Candidates</Header>
        <div>
          {this.props.candidates.map(candidate => {
            return (
              <div key={candidate.id}>
                <Divider/>
                <EditCandidate
                  candidate={candidate}
                  parties={this.state.parties}
                  electionCounties={this.props.electionCounties}
                  totalVoteCount={this.props.totalVoteCount}
                  isFetchingParties={this.state.isFetchingParties}
                  refresh={this.props.refresh}
                />
              </div>
            )
          })}
          <div>
            <Divider/>
            <EditCandidate
              parties={this.state.parties}
              isFetchingParties={this.state.isFetchingParties}
              electionCounties={this.props.electionCounties}
              totalVoteCount={this.props.totalVoteCount}
              refresh={this.props.refresh}
              raceId={this.props.raceId}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ListCandidates
