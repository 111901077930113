import React, { Component } from "react"
import { Route } from "react-router-dom";
import ViewUsers from "../Views/ViewUsers.js";
import EditUser from "../Editors/EditUser.js";
import Constants from '../Constants.js';
import APIService from "../API.js";
import ResponsiveModal from "../ResponsiveModal";

class UserListController extends Component {
  state = {
    userList: [],
    allRoles: [],
    componentIsLoading: true,
  }

  componentDidMount() {
    this.getUserList();
  }

  getUserList = () => {
    fetch(APIService.serverUrl + '/api/private/users', {
      method: "POST",
      headers: APIService.authHeaders(),
      body: JSON.stringify({}),
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("UserList Fetch Failed: ", response.status, response.statusText)
        if (response.status === 401) { this.props.history.push('/') };
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("UserList Request Succeeded!");
        this.setState({
          "userList": json,
          "componentIsLoading": false,
        });
      } else {
        console.log("ArticleList Fetch Failed")
      }
    })
  }

  render = () => {

    let isMobile = Constants.isMobile();
    let relativePath = this.props.match.path;

    return (
      <div>
        <Route
          path={relativePath}
          exact={isMobile}
          render={() => <ViewUsers {...this.state} />}
        />
        <Route
          path={relativePath + "/:addEdit(add|edit)/:userId?"}
          render={() => (
            <ResponsiveModal modalSize='mini'>
              <EditUser
                refreshList={this.getUserList}
                onClose={() => this.props.history.push('/users')}
              />
            </ResponsiveModal>
          )}
        />
      </div>
    )
  }
}

export default UserListController
