import React, {Component} from "react";
import {withRouter} from "react-router";
import {Route, Switch} from "react-router-dom";
import EditSeason from '../Editors/EditSeason.js';
import ViewSeasonList from "../Views/ViewSeasonList.js";
import APIService from "../API";



class SeasonController extends Component {

    state = {
        seasons: [],
        isFetchingSeasons: false,
    };

    componentDidMount() {
        this.getSeasonList();
    };

    getSeasonList = () => {
        this.setState({isFetchingSeasons: true});
        fetch(APIService.serverUrl + "/api/private/seasons", {
          method: "POST",
          headers: APIService.authHeaders(),
          body: JSON.stringify({}),
        })
          .then( (response) => {
            if(response.ok) {
              return response.json();
            } else {
              return null;
            }
          })
          .then( (json) => {
            if(json) {
              this.setState( {seasons: json})
            }
            this.setState( {isFetchingSeasons: false})
          })
    };


    render = () => {

        let relativePath = this.props.match.path;

        return (
              <Switch>
                  <Route
                      path={relativePath}
                      exact
                      render={() =>
                          <ViewSeasonList
                              {...this.state}
                              refreshList={this.getSeasonList}
                              seasons={this.state.seasons}
                          />}
                  />
                  <Route
                      path={relativePath + "/add"}
                      render={() =>
                          <EditSeason
                              refreshList={this.getSeasonList}
                          />}
                  />
                  <Route
                      path={relativePath + "/edit/:seasonId"}
                      render={() =>
                          <EditSeason
                              refreshList={this.getSeasonList}
                          />}
                  />
              </Switch>
        )
    };
};

export default withRouter(SeasonController);
