import React, { Component } from 'react';
import {
  Table,
  Icon,
  Button,
  Dimmer,
  Loader,
  Label,
  Checkbox,
  Segment, Header, Divider,
} from 'semantic-ui-react';
import { withRouter } from "react-router";
import moment from "moment";


class ClosingList extends Component {

  startNewClosing = () => {
    this.props.history.push('/closings/add');
  }

  editClosing = (closing) => {
    this.props.history.push('/closings/edit/'+closing.id);
  };

  render() {

    // groups and sorts closings by agency type
    let tables = {}
    this.props.closings.forEach(closing => {
      let agencyName = closing.agency ? closing.agency.agencyType.name : "Other"
      if (tables[agencyName]) {
        tables[agencyName].closings.push(closing)
      } else {
        tables[agencyName] = {
          agencyName: agencyName,
          sortOrder: closing.agency ? closing.agency.agencyType.sortOrder : 'last',
          closings: [closing]
        }
      }
    })
    let tablesArray = []
    Object.keys(tables).forEach(agencyName => {
      tablesArray.push(tables[agencyName])
    })
    let sortedTables = tablesArray.sort((a, b) => (a.sortOrder !== "last" && a.sortOrder <= b.sortOrder))

    return (

      <div>

        <Dimmer active={this.props.areClosingsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Segment>

          <Header subheader='click to edit'>
            <div style={{display: 'flex', float: 'right'}}>
              <Label basic style={{marginRight: "5px"}}>
                <Checkbox
                  label='Show Expired'
                  checked={this.props.showExpired}
                  onChange={this.props.toggleShowExpiredFilter}
                />
              </Label>
              <Button color="blue" onClick={this.startNewClosing} size='mini' >Add Closing</Button>
            </div>
            Closings
            <Header.Subheader>click to edit</Header.Subheader>
          </Header>
        </Segment>

        {sortedTables.map(agencyObject => {
          return (
            <div>
              <Divider hidden />
              <Table celled striped color="blue" selectable compact='very' size='small' >
                <Table.Header fullWidth >
                  <Table.Row >
                    <Table.HeaderCell verticalAlign='middle' colSpan='12' >
                      <span style={{ fontSize: "18px" }}>{agencyObject.agencyName}</span>
                    </Table.HeaderCell>
                  </Table.Row>

                  <Table.Row>
                    <Table.HeaderCell textAlign="center" collapsing>
                      <strong>Approved</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <strong>Agency</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <strong>Closed By</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell >
                      <strong>Created</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell >
                      <strong>Start</strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <strong>End</strong>
                    </Table.HeaderCell>{/**/}
                    <Table.HeaderCell>
                      <strong>Updated</strong>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {agencyObject.closings
                    // sorts closings by agency name or unsaved agency name
                    .sort((a, b) => (a.agency ? a.agency : {name: a.unsavedAgencyName}).name.localeCompare((b.agency ? b.agency : {name: b.unsavedAgencyName}).name))
                    .map( (closing) => {
                      return(
                        <Table.Row
                          key={closing.id.toString()}
                          id={closing.id.toString()}
                          activestate={closing.approved.toString()}
                          style={{cursor:'pointer'}}
                          onClick={ (e) => {this.editClosing(closing)} }
                        >
                          <Table.Cell name='approved' textAlign="center" collapsing>
                            <Icon name={closing.approved ? 'check circle' : 'circle outline'} color="blue" />
                          </Table.Cell>
                          <Table.Cell name='agency' >
                            <Label
                              size='small'
                              icon={closing.agency ? 'building' : 'building outline'}
                              content={closing.agency ? closing.agency.name : closing.unsavedAgencyName} />
                          </Table.Cell>
                          <Table.Cell name='closedBy' >
                            <Label
                              size='small'
                              icon='user'
                              content={closing.closedBy.name} />
                          </Table.Cell>
                          <Table.Cell name='createdAt'>
                            <Icon name='add' color='grey' />
                            {moment(closing.createdAt).format('M/DD/YY')}
                            <em> - {moment(closing.createdAt).format('h:mm a')}</em>
                          </Table.Cell>
                          <Table.Cell name='startDate' >
                            <Icon name='hourglass start' color='grey' />
                            {moment(closing.startDate).format('M/DD/YY')}
                            <em> - {moment(closing.startDate).format('h:mm a')}</em>
                          </Table.Cell>
                          <Table.Cell name='endDate' >
                            <Icon name='hourglass end' color='grey' />
                            {moment(closing.endDate).format('M/DD/YY')}
                            <em> - {moment(closing.endDate).format('h:mm a')}</em>
                          </Table.Cell>{/**/}
                          <Table.Cell name='updatedAt' color='grey' size='small' >
                            <Icon name='refresh' color='grey' />
                            {moment(closing.updatedAt).format('M/DD/YY')}
                            <em> - {moment(closing.updatedAt).format('h:mm a')}</em>
                          </Table.Cell>
                        </Table.Row>
                      )
                  })}
                </Table.Body>
              </Table>
            </div>
          )
        })}

      </div>

    );
  }
}

export default withRouter(ClosingList)
/*



*/
