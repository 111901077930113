import React, {Component} from "react";
import { Route, Switch } from "react-router-dom";
import {withRouter} from "react-router";
import ListElectionCounties from "../Views/ListElectionCounties";
import EditParty from "../Editors/EditElectionCounty";
import APIService from "../API.js";


class ElectionCountyController extends Component {

  state = {
    electionCounties: [],
    isFetchingElectionCounties: false,
  };

  componentDidMount() {
    this.getElectionCountyList();
  };

  getElectionCountyList = () => {
    this.setState({isFetchingElectionCounties: true})
    fetch(APIService.serverUrl + "/api/private/election-counties", {
        method: "POST",
        headers: APIService.authHeaders(),
        body: JSON.stringify({}),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                return null;
            }
        })
        .then((json) => {
            if (json) {
                this.setState( {electionCounties: json})
            }
            this.setState({isFetchingElectionCounties: false})
        })
  };


  render = () => {

      let relativePath = this.props.match.path;

      return (
          <div>
              <Switch>
                  <Route
                      path={relativePath}
                      exact
                      render={() =>
                          <ListElectionCounties
                              {...this.state}
                              refreshList={this.getElectionCountyList}
                              electionCounties={this.state.electionCounties}
                          />}
                  />
                  <Route
                      path={relativePath + "/add"}
                      render={() =>
                          <EditParty
                              refreshList={this.getElectionCountyList}
                          />
                      }
                  />
                  <Route
                      path={relativePath + "/edit/:electionCountyId"}
                      render={() =>
                          <EditParty
                              refreshList={this.getElectionCountyList}
                          />
                      }
                  />
              </Switch>

          </div>
      )
  };
};

export default withRouter(ElectionCountyController);
