import React, { Component } from 'react';
import {
  Icon,
  Label,
  Button,
  Form,
  Input,
  Checkbox,
  Header,
} from 'semantic-ui-react'
import { withRouter } from "react-router";
import APIService from '../API.js';
import ResponsiveModal from '../ResponsiveModal.js';


class EditArticleType extends Component {

  static defaultState = {
    // id: 0,
    name: '',
    isActive: false,

    unmodifiedEditType: {},
  }

  state = EditArticleType.defaultState;

  componentDidMount() {
    this.getArticleType();
  }

  getArticleType = () => {
    if (this.props.match.params.articleTypeId !== undefined) {
      fetch(APIService.serverUrl + '/api/private/articleType/'+this.props.match.params.articleTypeId, {
        method: "GET",
        headers: APIService.authHeaders(),
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          console.log("articleType Fetch Failed: ", response.status, response.statusText)
          return null
        }
      })
      .then((json) => {
        if (json) {
          // console.log("articleType Request Succeeded!");
          this.setState({
            'id': json.id,
            'name': json.name,
            'isActive': json.isActive,

            "unmodifiedEditType": json,
          })
        } else {
          console.log("articleTypes Fetch Failed returned null")
        }
      })
    }
  }

  patchArticleType = () => {
    this.setState({ 'componentIsLoading': true, });
    fetch(APIService.serverUrl + '/api/private/articleType', {
      method: this.state.id ? 'PATCH' : 'POST',
      headers: APIService.authHeaders(),
      body: JSON.stringify({
        "id": this.state.id,
        "name": this.state.name,
        "isActive": this.state.isActive,
      })
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        console.log("articleType PATCH Failed: ", response.status, response.statusText)
        return null
      }
    })
    .then((json) => {
      if (json) {
        // console.log("articleType PATCH Succeeded!");
        this.props.history.replace('/article-types/edit/'+json.id);
        this.props.refreshList();
        this.getArticleType();
      } else {
        console.log("articleType PATCH Failed")
      }
    })
  }

  onToggleChange = (e) => {
    this.setState({ "isActive": !this.state.isActive });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, });
  }

  isFormModified = () => {
    if (!this.state.id) { return true }
    for (var key in this.state.unmodifiedEditType) {
      if (this.state.unmodifiedEditType[key] !== this.state[key]) {
        return true;
      }
    }
    return false;
  }

  render() {

    let requiredFieldsComplete = this.state.name.length !== 0;

    return (

      <ResponsiveModal modalSize='mini' >
        <Form>
          <Header
            dividing
            color='blue'
            icon={ this.state.id ? 'edit outline' : 'add' }
            content={ this.state.id ? 'Edit Article Type' : 'Add Article Type' } />
          <Form.Group inline >
            <label><strong>Active: </strong></label>
            <Checkbox
              id="isActive"
              name='isActive'
              checked={this.state.isActive}
              onChange={this.onToggleChange}
              toggle />
          </Form.Group >
          <Form.Field >
            <label>
              Name:&nbsp;
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
            </label>
            <Input
              fluid
              id="name"
              name='name'
              value={this.state.name}
              onChange={this.onInputChange} />
          </Form.Field>
          <Form.Field>
            <Label size='tiny' >
              <Icon
                name='asterisk'
                size='small'
                color='blue' />
              Denotes required field
            </Label>
          </Form.Field>
          <Form.Field>
            <Button
              size='mini'
              color={ this.isFormModified() ? 'red' : undefined }
              onClick={ () => this.props.history.push('/article-types') } >
              <Icon name='cancel' /> { this.isFormModified() ? 'Cancel' : 'Close' }
            </Button>
            <Button
              size='mini'
              color='green'
              disabled={ !requiredFieldsComplete || !this.isFormModified() }
              onClick={this.patchArticleType} >
              <Icon name='save outline' /> Save
            </Button>
          </Form.Field>
        </Form>
      </ResponsiveModal>
    );
  }
}

export default withRouter(EditArticleType);

/*



*/
