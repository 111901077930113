import React, { Component } from 'react';
import {
  Table,
  Button,
  Dimmer,
  Loader,
  Label,
} from 'semantic-ui-react';
import { withRouter } from "react-router";


class AgencyList extends Component {

  startNewAgency = () => {
    this.props.history.push('/agencies/add');
  }

  startEditAgency = (agency) => {
    this.props.history.push('/agencies/edit/'+agency.id);
  };

  render() {

    return (

      <div>

        <Dimmer active={this.props.componentIsLoading} page >
          <Loader size='massive'>Loading</Loader>
        </Dimmer>

        <Table celled striped color="blue" selectable compact='very' size='small' >
          <Table.Header fullWidth >
            <Table.Row >
              <Table.HeaderCell textAlign="center" verticalAlign='middle' colSpan='12' >
                <span style={{ fontSize: "18px" }}>Click The Rows Below To Edit Agencies.</span>
                <Button color="blue" onClick={this.startNewAgency} floated='right' size='mini' >Add Agency</Button>
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row>
              <Table.HeaderCell textAlign='center' collapsing>
                <strong>ID</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Name</strong>
              </Table.HeaderCell>
              <Table.HeaderCell >
                <strong>Address 1</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Address 2</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Zip Code</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>City</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>State</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>Agency Type</strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.agencyList.map( (agency) => {
              return(
                <Table.Row
                  key={agency.id.toString()}
                  id={agency.id.toString()}
                  style={{cursor:'pointer'}}
                  onClick={ (e) => {this.startEditAgency(agency)} }
                >
                  <Table.Cell name='id' textAlign='center' collapsing>
                    <Label circular size='tiny' content={agency.id} />
                  </Table.Cell>
                  <Table.Cell name='name'>
                    {agency.name}
                  </Table.Cell>
                  <Table.Cell name='address1' >
                    {agency.address1}
                  </Table.Cell>
                  <Table.Cell name='address2' >
                    {agency.address2}
                  </Table.Cell>
                  <Table.Cell name='zip' >
                    {agency.zip}
                  </Table.Cell>
                  <Table.Cell name='city' >
                    {agency.city}
                  </Table.Cell>
                  <Table.Cell name='state' >
                    {agency.state}
                  </Table.Cell>
                  <Table.Cell name='agencyType' >
                    {agency.agencyType.name}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>

      </div>

    );
  }
}

export default withRouter(AgencyList)

/*



*/
